export const assets = [
  {
    image: 'https://assets.metaplayerone.app/assets/images/assets/Hammer1.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/assets/Helment1.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/assets/Kolba1.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/assets/Kope1.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/assets/Shar1.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/assets/Hammer1.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/assets/Helment1.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/assets/Kolba1.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/assets/Kope1.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/assets/Shar1.png',
  },
];
