import { FC, useState } from 'react';
import { RadioItem } from 'components/ui/radio';
import { clsx } from 'helpers/clsx';

import { InitialStake } from './components/initial-stake';
import { MOCK_SETUP_INSTRUCTIONS } from './components/node-setup/mock';
import { BridgeDeposit, Confirmation, NodeSetup, Requirements, ValidatorInfo } from './components';

import styles from './styles.module.scss';

interface IStepContentProps {
  activeStepIndex: number;
}

export const StepContent: FC<IStepContentProps> = ({ activeStepIndex }) => {
  const [currentValidatorType, setCurrentValidatorType] = useState<RadioItem>(MOCK_SETUP_INSTRUCTIONS[0]);
  return (
    <>
      <Requirements className={clsx(activeStepIndex === 0 && styles.active, styles.contentItem, styles.root)} />
      <NodeSetup
        className={clsx(activeStepIndex === 1 && styles.active, styles.contentItem, styles.root)}
        currentValidatorType={currentValidatorType}
        setCurrentValidatorType={setCurrentValidatorType}
      />
      <ValidatorInfo
        className={clsx(activeStepIndex === 2 && styles.active, styles.contentItem, styles.root)}
        isNodeOptionSelected={currentValidatorType.value === 'validatorNode'}
      />
      <BridgeDeposit className={clsx(activeStepIndex === 3 && styles.active, styles.contentItem, styles.root)} />
      <InitialStake className={clsx(activeStepIndex === 4 && styles.active, styles.contentItem, styles.root)} />
      <Confirmation className={clsx(activeStepIndex === 5 && styles.active, styles.contentItem, styles.root)} />
    </>
  );
};
