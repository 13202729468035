import { FC } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import CustomButton from 'modules/participate/components/custom-button';

import styles from './styles.module.scss';

interface IAboutPartnersProps {
  isModalOpen: boolean;
  closeModal: () => void;
}

export const AboutPartners: FC<IAboutPartnersProps> = ({ isModalOpen, closeModal }) => {
  return (
    <Modal isOpen={isModalOpen} onRequestClose={closeModal} shouldCloseOnOverlayClick ariaHideApp={false}>
      <p className={styles.title}>Our Partners</p>
      <div className={styles.border}>
        <p className={styles.title}>Partners</p>
        <div className={styles.linksContainer}>
          <div className={styles.linkItem}>
            <p className={styles.subTitle}>LeaderGPU</p>
            <Link className={styles.link} to="https://www.leadergpu.com">
              https://www.leadergpu.com
            </Link>
          </div>
          <div className={styles.linkItem}>
            <p className={styles.subTitle}>GPU Mart</p>
            <Link className={styles.link} to="https://www.gpu-mart.com">
              https://www.gpu-mart.com
            </Link>
          </div>
          <div className={styles.linkItem}>
            <p className={styles.subTitle}>AIME</p>
            <Link className={styles.link} to="https://www.aime.info/en/gpucloud/">
              https://www.aime.info/en/gpucloud/
            </Link>
          </div>
        </div>
      </div>
      <CustomButton
        className={styles.buttonWrapper}
        containedButtonClassName={styles.button}
        variant="contained"
        onClick={() => closeModal()}
      >
        Back
      </CustomButton>
    </Modal>
  );
};
