import Slider from 'react-slick';

import { TabletOff, TabletOn } from '../../../../../../helpers/responsive';

import { tabs } from './data';
import { List, SliderWrap, Tab, Wrapper } from './styles';

import '../../../../../../assets/css/slick.scss';

const TabSlider = ({ tab, setTab }) => {
  const settings = {
    slidesToShow: 3,
    dots: false,
    arrows: false,
    infinite: true,
    focusOnSelect: true,
    variableWidth: true,
    speed: 400,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <Wrapper>
      <SliderWrap>
        <TabletOn>
          <Slider {...settings}>
            {tabs?.map((item, index) => (
              <Tab key={`${index + 1}`} isActive={tab === item} onClick={() => setTab(item)}>
                {item}
              </Tab>
            ))}
          </Slider>
        </TabletOn>
        <TabletOff>
          <List>
            {tabs?.map((item, index) => (
              <Tab key={`${index + 1}`} isActive={tab === item} onClick={() => setTab(item)}>
                {item}
              </Tab>
            ))}
          </List>
        </TabletOff>
      </SliderWrap>
    </Wrapper>
  );
};
export default TabSlider;
