import styled from 'styled-components';

export const Wrapper = styled.div`
  display: ${(props) => (props.isOpenModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.74) 100%);
  z-index: 1000;
  animation: opacity 0.5s ease-in-out;
  padding: 24px 16px 24px 16px;
  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const Content = styled.div`
  position: relative;
  overflow-x: hidden;
  width: 100%;
  max-width: 650px;
  height: 100%;
  max-height: 580px;
  padding: 16px 16px 0 16px;
  background: #1b1b1c;
  border-radius: 24px;
  padding: 56px;
  @media screen and (max-width: 767px) {
    padding: 24px 16px;
    max-width: none;
    height: 100%;
    max-height: none;
  }
`;
export const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: absolute;
  background: transparent;
  border-radius: 50%;
  top: 12px;
  right: 16px;
  stroke: #000;
  & svg {
    stroke: #fff;
    fill: #fff;
  }
  @media screen and (max-width: 767px) {
    position: absolute;
    top: 14px;
    right: 16px;
  }
`;

export const Crystal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  min-height: 56px;
  background: #010102;
  border: 2px solid ${(props) => props.border};
  backdrop-filter: blur(50px);
  border-radius: 12px;
  z-index: 2;
  overflow: hidden;
  & > img {
    width: 70px;
    height: 70px;
    object-fit: contain;
    display: block;
    border-radius: 12px;
  }
`;
export const Title = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  padding: 12px 0 4px 0;
`;
export const SubTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  padding-bottom: 24px;
`;
export const Period = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding-bottom: 24px;
`;
export const List = styled.div`
  padding-left: 8px;
`;
export const Ul = styled.ul`
  height: 100%;
  max-height: 220px;
  padding-left: 20px;
  margin: 0;
  & > li:last-child {
    padding-bottom: 0;
  }
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #474a52;
    border-radius: 8px;
    cursor: pointer;
  }
  @media screen and (max-width: 767px) {
    max-height: calc(100vh - 400px);
  }
`;

export const Li = styled.li`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  list-style: disc;
  padding: 0 16px 8px 0;
`;

export const Line = styled.div`
  width: 100%;
  height: 12px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  background: ${(props) => props.background};
  border-radius: 100px;
  z-index: 1;
  @media screen and (max-width: 767px) {
    height: 8px;
  }
`;
export const Button = styled.button`
  position: absolute;
  left: 110px;
  bottom: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50px);
  border-radius: 10px;
  z-index: 1;
  transition: 0.2s;

  &:hover {
    transition: 0.2s;
    background: rgba(255, 255, 255, 0.3);
    @media screen and (max-width: 1024px) {
      background: rgba(255, 255, 255, 0.1);
    }
  }
  @media screen and (max-width: 767px) {
    left: auto;
    right: 16px;
    bottom: 32px;
  }
`;
export const ButtonLeft = styled(Button)`
  left: 56px;
  @media screen and (max-width: 767px) {
    left: auto;
    right: 80px;
  }
`;
