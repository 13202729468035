import { FC } from 'react';

import { IconProps } from '..';

export const GlowSphere2Icon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="732"
      height="1230"
      viewBox="0 0 732 1230"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_1_404)">
        <ellipse
          cx="65.772"
          cy="924.316"
          rx="209.772"
          ry="209.316"
          fill="url(#paint0_linear_1_404)"
          fillOpacity="0.3"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1_404"
          x="-600.026"
          y="0.973938"
          width="1331.6"
          height="1330.68"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="228.013" result="effect1_foregroundBlur_1_404" />
        </filter>
        <linearGradient
          id="paint0_linear_1_404"
          x1="-144"
          y1="666.316"
          x2="266.052"
          y2="603.929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AF01EC" />
          <stop offset="1" stopColor="#0066FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
