import IconAirlove from '../../../../../../assets/icons/cp/airlove_t.webp';
import IconGoovy from '../../../../../../assets/icons/cp/goovy_t.webp';
import IconLexor from '../../../../../../assets/icons/cp/lexor_t.webp';
import IconPlayer from '../../../../../../assets/icons/cp/player_t.webp';
import IconTokenAirlove from '../../../../../../assets/icons/cp/token_airlove.svg';
import IconTokenGoovy from '../../../../../../assets/icons/cp/token_goovy.svg';
import IconTokenLexor from '../../../../../../assets/icons/cp/token_lexor.svg';
import IconTokenPlayer from '../../../../../../assets/icons/cp/token_player.svg';
import IconTokenVios from '../../../../../../assets/icons/cp/token_vios.svg';
import IconVios from '../../../../../../assets/icons/cp/vios_t.webp';

export const texts = {
  notice:
    ' Help DAOs to regain power in the world. Crystals of Power can only be minted if Shard Crystals are on a wallet balance.',
  subtitle:
    'Users mint utility tokens by\nredemption of MetaUnits via staking -\n5 different types of utility tokens have\nown minting contracts. Every mint transaction pays a commission to the Crystal of Power Holders',
  small_text: 'The minting points are hidden around in your favorite metaverses and games.',
  midle_text: 'Good luck to all Metaplayers!',
  portal: 'Find The Portal in your favorite game. It is a minting point for The Crystals of Power',
};

export const tokenomics = [
  {
    crystal: IconAirlove,

    token: IconTokenAirlove,
    crystal_name: 'AIRLOVE CRYSTAL',
    token_name: 'TOKEN AIRLOVE',

    crystal_description: 'Airlove NFT holders govern the streaming activity and event making',
    token_description: 'Minted Airlove Token to be paid for the event making, pay per view, streaming systems in meta',
    rate: [
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard1.svg',
      },
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard32.svg',
      },
    ],
  },

  {
    crystal: IconLexor,

    token: IconTokenLexor,
    crystal_name: 'LEXOR CRYSTAL',
    token_name: 'TOKEN LEXOR',
    crystal_description:
      'Crystal of Promotion and Advertisement Power NFT holders govern the paid promotion and ads activity',
    token_description:
      'Minted Lexor Token to be paid for the promotion on the Platform, paid up ads in the community channels',

    rate: [
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard1.svg',
      },
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard32.svg',
      },
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard4.svg',
      },
    ],
  },
  {
    crystal: IconPlayer,
    token: IconTokenPlayer,
    crystal_name: 'PLAYER CRYSTAL',
    token_name: 'TOKEN PLAYER',
    crystal_description: 'Player NFT holders govern the game and meta asset markets',
    token_description:
      'Minted PLAYER Token to be used to buy meta assets, gaming life stamina, buy lands, heroes, weapons etc',
    rate: [
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard1.svg',
      },
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard32.svg',
      },
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard4.svg',
      },
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard4.svg',
      },
    ],
  },
  {
    crystal: IconGoovy,
    token: IconTokenGoovy,
    crystal_name: 'GOOVY CRYSTAL',
    token_name: 'TOKEN GOOVY',

    crystal_description:
      'Crystal of Generating Power NFT holders govern the generative AI activity and meta constructing',
    token_description: 'Minted GOOVY Token to be used for generative AI, Metaverse\nConstructing',
    rate: [
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard1.svg',
      },
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard32.svg',
      },
    ],

    notice: false,
  },

  {
    crystal: IconVios,

    token: IconTokenVios,
    crystal_name: 'VIOS CRYSTAL',
    token_name: 'TOKEN VIOS',

    crystal_description: 'MetaSkynet Crystal of Investment Vision NFT govern the investment activity',
    token_description:
      'Minted VIOS token to be redeemed for the private allocation vision, private sales priority, automated invetsment vehicles',

    rate: [
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard1.svg',
      },
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard32.svg',
      },
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard4.svg',
      },
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard4.svg',
      },
      {
        icon: 'https://assets.metaplayerone.app/assets/images/shard/shard4.svg',
      },
    ],
  },
];
