import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 60px;
  @media screen and (max-width: 767px) {
    padding: 10px 10px 32px 10px;
  }
`;
export const Conatainer = styled.div`
  width: 600px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  background: #1b1b1c;
  border-radius: 24px;
  padding: 56px;
  @media screen and (max-width: 767px) {
    padding: 20px;
    border-radius: 8px;
  }
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
`;
export const Close = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: absolute;
  background: transparent;
  border-radius: 50%;
  top: 12px;
  right: 22px;
  stroke: #000;
  & svg {
    stroke: #fff;
    fill: #fff;
  }
  @media screen and (max-width: 767px) {
    position: absolute;
    top: 14px;
    right: 16px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  padding-bottom: 16px;
  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
  }
`;
export const MiddleText = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
export const SmallText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7e8390;
`;
export const InputWrap = styled.div`
  position: relative;
  width: 100%;
`;
export const Input = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 100px 16px 16px;
  height: 64px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  border: none;
  outline: none;
`;
export const OptionWrap = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Button = styled.button`
  width: 32px;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 6px;
  & > img {
    width: 100%;
  }
`;
export const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  min-width: 20px;
`;
export const ButtonWrap = styled.div`
  padding-top: 16px;
  @media screen and (max-width: 767px) {
    width: 100%;

    & button {
      width: 100%;
    }
  }
`;
export const List = styled.div`
  width: 100%;
  height: 100%;
  max-height: 74px;
  min-height: 74px;
  margin: 12px 0 0 0;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #8d8d8d;
    border-radius: 5px;
    cursor: pointer;
  }
`;
export const Item = styled.div`
  position: relative;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 10px 5px;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
`;
export const WrapCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Info = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #7e8390;
  padding-top: 6px;
`;
