/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { useEffect } from 'react';
import axios from 'axios';

import { chain, chainId, shard_address } from '../../../../const/const';
import Moralis from '../../../../const/moralis';
import useAuth from '../../../../helpers/auth';
import CardAssets from '../../card';
import EmptyCard from '../../empty-card';
import LoadingCard from '../../loading-card';

const ShardCrystals = ({ onClick, shards, setShards, loading, setLoading }) => {
  const { user } = useAuth();
  useEffect(() => {
    const load = async () => {
      try {
        const list = (
          await Moralis.Cloud.run('getWalletNFTs', {
            chain: chainId,
            address: user?.get('ethAddress'),
            tokenAddresses: [shard_address],
          })
        ).result;
        for (const token of list) {
          if (token.metadata) {
            token.metadata = JSON.parse(token.metadata);
          } else if (token.token_uri) {
            try {
              // eslint-disable-next-line no-await-in-loop
              await Moralis.Web3API.token.reSyncMetadata({
                chain,
                flag: 'metadata',
                mode: 'sync',
                token_address: token.token_address,
                token_id: token.token_id,
              });
              const { data } = await axios.get(token.token_uri);
              token.metadata = data;
            } catch (e) {
              console.error(e);
            }
          } else {
            try {
              await Moralis.Web3API.token.reSyncMetadata({
                chain,
                flag: 'uri',
                mode: 'sync',
                token_address: token.token_address,
                token_id: token.token_id,
              });
              const { data } = await axios.get(token.token_uri);
              token.metadata = data;
            } catch (e) {
              console.error(e);
            }
          }
        }
        setShards(list);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    if (loading) load();
  }, [loading, setLoading, setShards, user]);

  if (loading) return <LoadingCard />;
  if (shards?.length > 0)
    return shards?.map((item) => (
      <CardAssets
        key={item?.metadata?.animation_url}
        item={item?.metadata?.animation_url}
        onClick={() => onClick(item)}
      />
    ));
  return <EmptyCard />;
};
export default ShardCrystals;
