import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Modal from 'react-modal';
import axios from 'axios';

import { patterns } from '../../../../helpers/patterns';
import { Mobile } from '../../../../helpers/responsive';
import Success from '../../../cards/success';
import IconClose from '../../../icons/IconClose';
import { Input, SubmitBtn, Textarea } from '../../../ui';
import { Close, Conatainer, Form, Title, Wrapper } from '../styles';

const ApplyToDaoModal = (props) => {
  const { isOpenModal, closeModal } = props;
  const [status, setStatus] = useState(false);

  const methods = useForm({ mode: 'onSubmit' });
  const { handleSubmit, reset } = methods;

  const onSubmit = async (data) => {
    if (!status) {
      const formdata = new FormData();
      formdata.append('email', data.email);
      formdata.append('name', data.name);
      formdata.append('refference', data.refference);
      formdata.append('question', data.question);
      await axios.post('https://eowsowv0rbkorbl.m.pipedream.net', formdata);
      setStatus(true);
      reset();
    }
  };

  const close = () => {
    reset();
    closeModal();
  };
  return (
    <Modal isOpen={isOpenModal} onRequestClose={closeModal} shouldCloseOnOverlayClick ariaHideApp={false}>
      <Wrapper>
        <Conatainer>
          {!status ? (
            <>
              <Close onClick={close}>
                <IconClose />
              </Close>
              <Title>
                Alpha&nbsp;
                <Mobile>
                  <br />
                </Mobile>
                Season Application
              </Title>
              <FormProvider {...methods}>
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <Input
                    name="email"
                    placeholder="Email"
                    rules={{
                      required: true,
                      pattern: patterns?.email,
                    }}
                  />
                  <Input name="name" placeholder="Name" rules={{ required: true }} />
                  <Input name="refference" placeholder="Reference URL" rules={{ required: true }} />
                  <Textarea name="question" placeholder="Feel free to ask" rules={{ maxLength: 1000 }} />
                  <SubmitBtn submitted={status} />
                </Form>
              </FormProvider>
            </>
          ) : (
            <Success onClick={close} />
          )}
        </Conatainer>
      </Wrapper>
    </Modal>
  );
};
export default ApplyToDaoModal;
