import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled(Link)`
  ${(props) => props.rootClassName};
  --border-width: 2px;
  --border-radius: 22px;
  --color-1: #af01ec;
  --color-2: #0066ff;
  font-weight: bold;
  letter-spacing: 1px;
  color: #ddd;
  width: 120px;
  position: relative;
  border: 1px solid;

  background: linear-gradient(107.75deg, #af01ec 3.49%, #0066ff 97.12%),
    linear-gradient(107.75deg, #af01ec 3.49%, #0066ff 97.12%);
  background-position: var(--border-radius) 0, var(--border-radius) 100%;
  background-repeat: no-repeat;
  background-size: calc(100% - var(--border-radius) - var(--border-radius)) var(--border-width);
  padding: 14px 24px;
  border-radius: var(--border-radius);
  border: none;

  &::before,
  &::after {
    box-sizing: unset;
    content: '';
    display: block;
    position: absolute;
    width: var(--border-radius);
    top: 0;
    bottom: 0;
  }
  &::before {
    left: 0;
    border: var(--border-width) solid var(--color-1);
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    border-right-color: transparent;
  }
  &::after {
    right: 0;
    border: var(--border-width) solid var(--color-2);
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    border-left-color: transparent;
  }
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
`;

const PrimaryLink = ({ title, disabled = false, rootClassName = '', url }) => {
  return (
    <Wrapper
      disabled={disabled}
      locked={disabled}
      className="primary-button"
      rootClassName={rootClassName}
      to={url}
      target="_blank"
    >
      {title}
    </Wrapper>
  );
};
export default PrimaryLink;
