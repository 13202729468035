import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 598px;
  height: 264px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #1b1b1c;
  backdrop-filter: blur(50px);
  border-radius: 24px;
  padding: 56px;
  margin: 56px auto 56px auto;
  @media screen and (max-width: 767px) {
    padding: 24px;
  }
`;
export const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 30px;
  }
`;
export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 16px 0 48px 0;
  text-align: center;
  & button {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #e657dc;
    &:hover {
      text-decoration: underline;
    }
  }
`;
export const Button = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 8px;
  &:hover {
    color: #7e8390;
  }
`;
