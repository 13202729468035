import { Dispatch, FC, SetStateAction } from 'react';

import { TabItem, Tabs } from '../tabs';

import styles from './styles.module.scss';

interface ITabSelectorContainerProps {
  tabs: TabItem[];
  activeTab: TabItem;
  setActiveTab: Dispatch<SetStateAction<TabItem>>;
}

export const TabSelectorContainer: FC<ITabSelectorContainerProps> = ({ tabs, setActiveTab, activeTab }) => {
  return (
    <div className={styles.root}>
      <p className={styles.title}>Validate</p>
      <Tabs tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} />
    </div>
  );
};
