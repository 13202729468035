import IconAirlove from '../../../../assets/icons/cp/airlove.png';
import IconGoovy from '../../../../assets/icons/cp/goovy.png';
import IconLexor from '../../../../assets/icons/cp/lexor.png';
import IconPlayer from '../../../../assets/icons/cp/player.png';
import IconVios from '../../../../assets/icons/cp/vios.png';

export const roadmap = [
  {
    period: 'Q3 2022 - Q4 2022',
    title: 'LEXOR',
    subtitle: 'CRYSTAL OF PROMOTION POWER',
    crystal: IconLexor,
    border: '#48FF50',
    color_line: 'linear-gradient(270deg, rgba(255, 92, 1, 0) 0%, #48FF50 51.04%, rgba(255, 92, 1, 0) 100%)',
    options: [
      {
        option: 'MetaPlayerOne Social networking Platform launch.',
      },
      {
        option: 'Gamefi, DeFi, NFT, Meta communites begin to collaborate in a web3 format.',
      },
    ],
    full_options: [
      {
        option: '01.05 - 09.10.2022 Shard Crystals Raffle Mint, Lexor Crystal Exposure in Decentraland Escape Room.',
      },
      {
        option: '08.2022 MetaPlayerOne Game in Roblox - Survival',
      },

      {
        option: '09.2022 MetaPlayerOne WEB3 Social Network beta release and Alpha Season Opening (for crystal holders',
      },
      {
        option: '10.2022 MetaUnit Pre-IDO campaign ',
      },
      {
        option: '08.11.2022 MEU Whitelist Minting',
      },
      {
        option: '10.11.2022 Metaunit IDO campaign',
      },
    ],
  },
  {
    period: 'Q3 2023 - Q4 2023',
    title: 'GOOVY',
    subtitle: 'CRYSTAL OF META CONSTRUCTION',
    crystal: IconGoovy,
    border: '#FF5C00',
    color_line: 'linear-gradient(270deg, rgba(255, 92, 1, 0) 0%, #FF5C00 51.04%, rgba(255, 92, 1, 0) 100%)',
    options: [
      {
        option: 'MetaPlayerOne Generative AI launch - SOIKA MOCKINGJAY',
      },
      {
        option: 'Multimodal AI playground to generate content',
      },
      {
        option: 'Metaverse and Game Maker.',
      },
      {
        option: 'Goovy Token Launch',
      },
      {
        option: 'Goovy Crystal Exposure',
      },
    ],
    full_options: [
      {
        option: '01.2023 MetaUnit Portal SD',
      },
      {
        option: '27.02.2023 Gods of Meta 1st Mint Campaign ',
      },

      {
        option: '01.2023 MetaUnit Portal SDK ',
      },
      {
        option: '03.2023 Custom MetaPlayerOne MetaSpace Builder release',
      },
      {
        option: '04.2022 MetaSpace Developers involvement, GOOVY Crystals of Power exposure and activation ',
      },
      {
        option: 'Q3.2023 Custom MetaPlayerOne Metaverse Builder release',
      },

      {
        option: 'Q4.2023 Alfa-Zeta Ecosystem Connection Beta Launch',
      },
      {
        option: 'Q4.2023 MetaSkynet System Deployment ',
      },

      {
        option: 'Q4.2023 MP1 GamePlay Protocol',
      },
      {
        option: 'Q1 2024 MP1 Layer 3 MH Protocol Beta Release',
      },
      {
        option: 'Q2.2024 Custom MetaPlayerOne Decentralized GameFi Builder Beta',
      },
      {
        option: 'Q4.2024 DEFI WARS - The Last Earth play-to-earn release ',
      },
      {
        option: 'Q2.2025 Paradise Occupied Metaverse Lands private allocation campaign  ',
      },
    ],
  },
  {
    period: 'Q2 2024 - Q2 2025',
    title: 'PLAYER',
    subtitle: 'CRYSTAL OF METAPLAYER POWER',
    crystal: IconPlayer,
    border: '#8A61FF',
    color_line: 'linear-gradient(270deg, rgba(255, 92, 1, 0) 0%, #8A61FF 51.04%, rgba(255, 92, 1, 0) 100%)',
    options: [
      {
        option: 'MetaPlayerOne Games Launch.',
      },
      {
        option: 'Metaplayer Crystal Exposure - Player Tokenomics Start.',
      },
    ],
    full_options: [
      {
        option: '04.2024 GameFi Players involvement, MetaPLayer Crystals of Power exposure and activation         ',
      },
      {
        option: '06.2024 Assets of Meta 1st Mint Campaign',
      },
      {
        option: '07.2024 Heroes and Super Heroes of Meta 1st Mint Campaign',
      },
      {
        option: '08.2024 Spirits of Meta 1st Mint Campaign ',
      },
      {
        option: '09.2024 Beasts of Meta 1st Mint Campaign',
      },
      {
        option: '10.2024 Spirit Mutations 1st Campaign',
      },
      {
        option: '11.2024 Beast Mutations 1st Campaign',
      },
      {
        option: 'Q4.2024 DEFI WARS - The Last Earth play-to-earn release',
      },
      {
        option: 'Q2.2025 Paradise Occupied Metaverse Lands private allocation campaign ',
      },
    ],
  },
  {
    period: 'Q4 2023 - Q1 2025',
    title: 'AIRLOVE',
    subtitle: 'CRYSTAL OF EVENT MAKING & BROADCASTING POWER',
    crystal: IconAirlove,
    border: '#D51FCE',
    color_line: 'linear-gradient(270deg, rgba(255, 92, 1, 0) 0%, #D51FCE 51.04%, rgba(255, 92, 1, 0) 100%)',
    options: [
      {
        option: 'MetaPlayerOne Event Management and broadcasting system launch, pay per view protocol activation.',
      },
      {
        option: 'Airlover Crystal Exposure - Airlove Token Mining Start.',
      },
    ],
    full_options: [
      {
        option: 'Q4.2023 Metaverse real-time, movie, music, video broadcasting system launch         ',
      },
      {
        option: 'Q4.2023 Metaverse Event Management System Launch',
      },

      {
        option: 'Q4.2023 AIRLOVE Crystal Exposure and Activation ',
      },
      {
        option: 'Q1.2024 Meta Events Locations Management and Meta Channels Laucnch ',
      },
      {
        option: 'Q2.2024 Pay per view protocol launch',
      },
      {
        option: 'Q2.2024 Decentralized Advertizement Management System Launch',
      },
      {
        option: 'Q2.2024 Metaverse chatting, voice channel protocol ',
      },
    ],
  },
  {
    period: 'Q2 2025 - Q3 2026',
    title: 'VIOS',
    subtitle: 'CRYSTAL OF PRIVATE ALLOCATOIN & INVESTMENTS POWER',
    crystal: IconVios,
    border: '#ffbf33',
    color_line: 'linear-gradient(270deg, rgba(255, 92, 1, 0) 0%, #ffbf33 51.04%, rgba(255, 92, 1, 0) 100%)',
    options: [
      {
        option:
          'MetaPlayerOne WEB3 Structured Invetsment management tools release, RFT based mechanics imlementation, invetsmet automations.',
      },
      {
        option: 'Visionary Crystal Exposure - VIOS Token Mining Start.',
      },
    ],
    full_options: [
      {
        option: 'Q2.2025 VIOS Crystal Exposure and Activation ',
      },
      {
        option: 'Q2.2025 1st MetaProject RFT based fundrasing (Advanced NFT Staking)',
      },

      {
        option: 'Q4.2025 Prortfolio Managenet Tools for DAOs',
      },
      {
        option: 'Q1.2026 Structured Investment Management Protocol',
      },
    ],
  },
];
