import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;
export const Button = styled.button`
  width: 48px;
  min-width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #8f13ef;
  border-radius: 50%;
  margin-left: 24px;
  overflow: hidden;
  padding: 0;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
`;
export const OptonList = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  display: ${(props) => (props.isProfile ? 'flex' : 'none')};
  flex-direction: column;
  padding: 24px;
  background: #010102;
  border: 1px solid #010102;
  border-radius: 16px;
  box-sizing: border-box;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  @media screen and (max-width: 767px) {
    top: 40px;
  }
`;
export const Number = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding-bottom: 16px;
`;
