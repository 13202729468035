import { useState } from 'react';
import { clsx } from 'helpers/clsx';
import { Tabs } from 'modules/participate/components';
import { SimpleTable } from 'modules/participate/components/simple-table';

import { content, headers, tabs } from './mock';

import styles from './styles.module.scss';

export const MyStaking = ({ className }: any) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.infoBlock}>
        <div className={styles.infoBox}>
          <p className={styles.infoText}>CLAIMABLE REWARDS</p>
          <p className={styles.infoText}>-- MEU</p>
          <p className={styles.title}>$ .. USD</p>
        </div>
        <div className={styles.infoBox}>
          <p className={styles.title}>Total Staking:</p>
          <p className={styles.value}>0.00 MEU</p>
        </div>
        <div className={styles.infoBox}>
          <p className={styles.title}>Total Rewards</p>
          <p className={styles.value}>0.00 MEU</p>
        </div>
      </div>
      <Tabs isUnderlined tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} className={styles.tabs} />
      {activeTab.value === 'myValidators' && <SimpleTable headers={headers} content={content} />}
    </div>
  );
};
