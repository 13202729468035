export const crystals = [
  {
    image: 'https://assets.metaplayerone.app/assets/images/crystals-power/power1.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/crystals-power/power2.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/crystals-power/power3.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/crystals-power/power4.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/crystals-power/power5.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/crystals-power/power1.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/crystals-power/power2.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/crystals-power/power3.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/crystals-power/power4.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/crystals-power/power5.png',
  },
];
export const crystalsPowerMock = [
  {
    image: 'https://assets.metaplayerone.app/assets/crystals-cop/cop1.png',
    video: 'https://assets.metaplayerone.app/assets/crystals-cop/cop1.mp4',
    name: 'PLAYER',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/crystals-cop/cop2.png',
    video: 'https://assets.metaplayerone.app/assets/crystals-cop/cop2.mp4',
    name: 'GOOVY',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/crystals-cop/cop3.png',
    video: 'https://assets.metaplayerone.app/assets/crystals-cop/cop3.mp4',
    name: 'AIRLOVE',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/crystals-cop/cop4.png',
    video: 'https://assets.metaplayerone.app/assets/crystals-cop/cop4.mp4',
    name: 'VIOS',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/crystals-cop/cop5.png',
    video: 'https://assets.metaplayerone.app/assets/crystals-cop/cop5.mp4',
    name: 'LEXOR',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/crystals-cop/cop1.png',
    video: 'https://assets.metaplayerone.app/assets/crystals-cop/cop1.mp4',
    name: 'PLAYER',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/crystals-cop/cop2.png',
    video: 'https://assets.metaplayerone.app/assets/crystals-cop/cop2.mp4',
    name: 'GOOVY',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/crystals-cop/cop3.png',
    video: 'https://assets.metaplayerone.app/assets/crystals-cop/cop3.mp4',
    name: 'AIRLOVE',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/crystals-cop/cop4.png',
    video: 'https://assets.metaplayerone.app/assets/crystals-cop/cop4.mp4',
    name: 'VIOS',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/crystals-cop/cop5.png',
    video: 'https://assets.metaplayerone.app/assets/crystals-cop/cop5.mp4',
    name: 'LEXOR',
  },
];
