import { useLocation, useNavigate } from 'react-router';

import { Button, SubTitle, Title, Wrapper } from './styles';

const LoadingCard = () => {
  const push = useNavigate();
  const { search } = useLocation();

  const onClick = () => {
    push(`/${search}`);
    window.scrollTo(0, 0);
  };

  return (
    <Wrapper>
      <Title>Loading...</Title>
      <SubTitle>Please wait</SubTitle>
      <Button onClick={onClick}>back to main</Button>
    </Wrapper>
  );
};
export default LoadingCard;
