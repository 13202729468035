import { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { clsx } from 'helpers/clsx';

import styles from './styles.module.scss';

interface IBaseInputProps {
  className?: string;
  wrapperClassName?: string;
  value?: string;
  setValue?: Dispatch<SetStateAction<string>>;
  type?: 'text' | 'textarea';
  endAdornment?: ReactNode;
  placeholder?: string;
}

export const BaseInput: FC<IBaseInputProps> = ({
  className,
  wrapperClassName,
  value,
  setValue,
  type = 'text',
  endAdornment,
  placeholder,
}) => {
  return (
    <div className={clsx(wrapperClassName, styles.wrapper)}>
      <input
        type={type}
        className={clsx(styles.input, className)}
        value={value}
        onChange={({ target: { value: newValue } }) => setValue && setValue(newValue)}
        placeholder={placeholder}
      />
      {!!endAdornment && endAdornment}
    </div>
  );
};
