import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Modal from 'react-modal';
import axios from 'axios';

import { patterns } from '../../../../helpers/patterns';
import Success from '../../../cards/success';
import IconClose from '../../../icons/IconClose';
import { Input, SubmitBtn } from '../../../ui';
import { Close, Conatainer, Form, Title, Wrapper } from '../styles';

const ApplyToWhitelistModal = (props) => {
  const { isOpenModal, closeModal } = props;
  const [status, setStatus] = useState(false);
  const methods = useForm({ mode: 'onSubmit' });
  const { handleSubmit, reset } = methods;

  const onSubmit = async (data) => {
    if (!status) {
      const formdata = new FormData();
      formdata.append('email', data.email);
      formdata.append('wallet', data.wallet);
      await axios.post('https://eodlirrwxnfdf0c.m.pipedream.net', formdata);
      setStatus(true);
      reset();
    }
  };
  const close = () => {
    reset();
    closeModal();
  };
  return (
    <Modal isOpen={isOpenModal} onRequestClose={closeModal} shouldCloseOnOverlayClick ariaHideApp={false}>
      <Wrapper>
        <Conatainer>
          {!status ? (
            <>
              <Close onClick={close}>
                <IconClose />
              </Close>
              <Title>Join IDO Whitelist</Title>
              <FormProvider {...methods}>
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <Input
                    name="email"
                    placeholder="Email"
                    rules={{
                      required: true,
                      pattern: patterns?.email,
                    }}
                  />
                  <Input
                    name="wallet"
                    placeholder="Wallet"
                    rules={{ required: true, pattern: patterns?.eth_address }}
                  />
                  <SubmitBtn />
                </Form>
              </FormProvider>
            </>
          ) : (
            <Success onClick={close} />
          )}
        </Conatainer>
      </Wrapper>
    </Modal>
  );
};
export default ApplyToWhitelistModal;
