import styled from 'styled-components';

export const Content = styled.div`
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transform: ${(props) => (props.isOpen ? 'translate(0, 0)' : 'translate(0, -100%)')};
  transition: 0.5s;
  z-index: 10000;
`;
export const Body = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 160px 0 40px 0;
  background-color: #010102;
  overflow-y: auto;
  @media screen and (max-width: 767px) {
    padding: 100px 0 40px 0;
  }
  @media screen and (max-height: 770px) {
    padding: 40px 0;
  }
`;
export const Logo = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat';
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  & span {
    font-weight: 600;
    padding-left: 16px;
  }
`;
export const Navigation = styled.button`
  height: 48px;
  width: 100%;
  padding: 0 8px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => (props.isActive ? '#7e8390' : '#fff')};
  transition: 0.3s;
  &:hover {
    color: #7e8390;
    transition: 0.3s;
  }
`;
export const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 24px;
  right: 14px;
`;
export const WrapBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 24px 0 0 0;
  & div:nth-child(2) {
    width: 100%;
    margin-top: 16px;
  }
  & button {
    margin-top: 16px;
  }
  @media screen and (max-width: 767px) {
    padding: 24px 20px 0 20px;
    width: 100%;
    & div:nth-child(2) {
      width: 100%;
    }
    & button {
      width: 100%;
    }
  }
`;
export const Number = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 16px 0 32px 0;
  margin: 0 auto;
`;
export const Avatar = styled.button`
  width: 48px;
  min-width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #8f13ef;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  margin: 0 auto;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
`;
