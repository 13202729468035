import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import IconArrowBack from '../../assets/icons/arrow-back.svg';
import IconMinus from '../../assets/icons/minus.svg';
import IconPlus from '../../assets/icons/plus.svg';
import { Flex } from '../../components/containers';
import { MintingShardModal, ShardDetailModal } from '../../components/modals';
import useAuth from '../../helpers/auth';
import { Desktop, TabletOff, TabletOn } from '../../helpers/responsive';

import BigCard from './big-card';
import Card from './card';
import Pagination from './pagination';
import { Back, Column, Container, ControllPanel, List, Title, Wrap, Wrapper, Zoom } from './styles';

function shuffle(array) {
  // eslint-disable-next-line no-plusplus
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

const amount = 1220;
const arr = Array.from(Array(amount + 1).keys()).slice(1);
const list = shuffle(arr);

const CatalogPage = () => {
  const navigate = useNavigate();
  const tokensPerPage = window.innerWidth < 768 ? 50 : 200;
  const { user, authenticate } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isOpenCrystal, setOpenCrystal] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(1);
  const [activeToken, setActiveToken] = useState();
  const [activeIndex, setActiveIndex] = useState(list[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [amountPage, setAmountPage] = useState();
  const lastTokenIndex = currentPage * tokensPerPage;
  const firstTokenIndex = lastTokenIndex - tokensPerPage;
  const currentToken = list?.slice(firstTokenIndex, lastTokenIndex);

  const openMint = async () => {
    if (!user) {
      try {
        const key = enqueueSnackbar('Trying to connect wallet', {
          persist: true,
          variant: 'info',
        });
        await authenticate((isAuth) => {
          if (!isAuth) throw Error();
        });
        setOpenCrystal(true);
        closeSnackbar(key);
      } catch (e) {
        enqueueSnackbar('Error! Wallet is not connected!', {
          autoHideDuration: 3000,
          variant: 'error',
        });
      }
    } else {
      setOpenCrystal(true);
    }
  };
  useEffect(() => {
    if (activeIndex !== activeToken?.attributes?.number) {
      setLoading(true);
      const getTokens = async () => {
        try {
          const response = await axios.get(
            `https://metaplayerone.mypinata.cloud/ipfs/QmPfVvKoUbnuB7JxVWFKjyRt4XhrJSz1A5AefKDG3WZyRE/${activeIndex}.json`,
          );
          setActiveToken(response.data);
          setLoading(false);
        } catch (err) {
          console.error(err);
        }
      };
      getTokens();
    }
  }, [activeIndex, activeToken?.attributes?.number]);

  const selectToken = (item) => {
    setActiveIndex(item);
    setOpenModal(true);
  };
  const nextPage = () => {
    if (currentPage < amountPage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const paginate = (pageNumbers) => {
    setCurrentPage(pageNumbers);
  };

  const handlePrev = () => {
    if (list.indexOf(activeIndex) > 0) {
      setActiveIndex(list[list.indexOf(activeIndex) - 1]);
      if (list.indexOf(activeIndex) - 1 < firstTokenIndex) {
        prevPage();
      }
    }
  };
  const handleNext = () => {
    if (list.indexOf(activeIndex) < amount - 1) {
      setActiveIndex(list[list.indexOf(activeIndex) + 1]);
    }
    if (list.indexOf(activeIndex) + 1 >= lastTokenIndex) {
      nextPage();
    }
  };

  const plus = () => {
    if (size < 3) {
      setSize(size + 1);
    }
  };
  const minus = () => {
    if (size > 1) setSize(size - 1);
  };

  return (
    <Wrapper>
      <Container>
        <Back onClick={() => navigate(-1)}>
          <img src={IconArrowBack} alt="" />
          back
        </Back>
        <Title className="font">Shard Crystals</Title>

        <Wrap>
          <TabletOff>
            <BigCard
              loading={loading}
              key={activeToken?.name}
              item={activeToken}
              activeIndex={activeIndex}
              prev={handlePrev}
              next={handleNext}
              amount={amount}
              list={list}
              openMint={openMint}
            />
          </TabletOff>
          <Column>
            <ControllPanel>
              <Desktop>
                <Flex>
                  <Zoom onClick={plus} isDisabled={size === 3}>
                    <img src={IconPlus} alt="" />
                  </Zoom>
                  <Zoom onClick={minus} isDisabled={size === 1}>
                    <img src={IconMinus} alt="" />
                  </Zoom>
                </Flex>
              </Desktop>
              <Pagination
                tokensPerPage={tokensPerPage}
                currentPage={currentPage}
                amount={amount}
                setCurrentPage={setCurrentPage}
                setAmountPage={setAmountPage}
                paginate={paginate}
                nextPage={nextPage}
                prevPage={prevPage}
              />
            </ControllPanel>
            <List size={size}>
              {currentToken?.map((index) => (
                <Card item={index} key={`${index + 1}`} size={size} onClick={selectToken} activeToken={activeToken} />
              ))}
            </List>
          </Column>
        </Wrap>
      </Container>
      <TabletOn>
        <ShardDetailModal
          isOpenModal={isOpenModal}
          closeModal={() => setOpenModal(false)}
          item={activeToken}
          prev={handlePrev}
          next={handleNext}
          activeIndex={activeIndex}
          amount={amount}
          loading={loading}
          list={list}
          openMint={openMint}
        />
      </TabletOn>
      <MintingShardModal isOpenModal={isOpenCrystal} closeModal={() => setOpenCrystal(false)} />
    </Wrapper>
  );
};
export default CatalogPage;
