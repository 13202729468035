import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  /* padding: ${(props) => props.size * 2}px;
  @media screen and (max-width: 767px) {
    padding: ${(props) => props.size * 1}px;
  } */
  &.variant1 {
    width: 5%;
    @media screen and (max-width: 767px) {
      width: 20%;
    }
  }
  &.variant2 {
    width: 7.14%;
    @media screen and (max-width: 767px) {
      width: 20%;
    }
  }
  &.variant3 {
    width: 12.5%;
    padding: 4px;
    @media screen and (max-width: 767px) {
      width: 20%;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 3px;
  }
`;
export const Inner = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  border: none;
  padding-top: 100%;
`;
export const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
`;
export const Item = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgba(255, 255, 255, 0.1); */
  border-radius: ${(props) => props.size * 4}px;
  border: 1px solid ${(props) => (props.isActive ? '#E657DC' : '#181818')};
  overflow: hidden;
  @media screen and (max-width: 767px) {
    border-radius: 8px;
  }
  & img {
    /* width: 100%; */
    height: 100%;
    object-fit: contain;
    display: block;
  }
`;
