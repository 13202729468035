import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 40px;
  overflow-x: hidden;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  @media screen and (max-width: 767px) {
    padding: 0;
    background: #000000d1;
  }
`;
export const Conatainer = styled.div`
  width: 800px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border-radius: 24px;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    width: 100%;
    border-radius: 0;
    padding: 56px 0px 24px 0px;
  }
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0 32px 0;
`;
export const Close = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: absolute;
  background: transparent;
  border-radius: 50%;
  top: 12px;
  right: 12px;
  stroke: #000;
  z-index: 1;
  & svg {
    stroke: #fff;
    fill: #fff;
  }
  @media screen and (max-width: 767px) {
    position: absolute;
    top: 16px;
  }
`;

export const Title = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  padding-bottom: 24px;
  @media screen and (max-width: 767px) {
    font-size: 22px;
    line-height: 24px;
    padding-bottom: 8px;
  }
`;
export const Item = styled.div`
  height: 400px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  overflow: hidden;
  background-color: #000;
  & img {
    max-height: 100%;
  }
  & video {
    animation: opacity 0.7s ease-in-out;
    width: 100%;
    height: 100%;
  }
  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    align-items: center;
  }
`;
export const Description = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  padding-bottom: 32px;
  @media screen and (max-width: 767px) {
    padding-top: 8px;
    font-size: 20px;
    line-height: 22px;
  }
`;
export const ButtonPrev = styled.button`
  position: absolute;
  left: 56px;
  top: 60%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50px);
  border-radius: 8px;
  opacity: ${(props) => (props.isDisabled ? '0.5' : '1')};
  cursor: ${(props) => (props.isDisabled ? 'auto' : 'pointer')};
  &:hover {
    transition: 0.2s;
    background: rgba(255, 255, 255, 0.3);
    @media screen and (max-width: 1024px) {
      background: rgba(255, 255, 255, 0.1);
    }
  }
  @media screen and (max-width: 767px) {
    left: 16px;
  }
`;

export const ButtonNext = styled(ButtonPrev)`
  left: auto;
  right: 56px;
  @media screen and (max-width: 767px) {
    right: 16px;
  }
`;
