import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 60px;
  @media screen and (max-width: 1024px) {
    padding: 32px;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 10px 30px 10px;
  }
`;
export const Conatainer = styled.div`
  width: 600px;

  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background: #1b1b1c;
  border-radius: 24px;
  padding: 56px;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    width: 100%;
    border-radius: 12px;
    padding: 24px;
  }
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0 32px 0;
`;
export const Close = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: absolute;
  background: transparent;
  border-radius: 50%;
  top: 12px;
  right: 12px;
  stroke: #000;
  & svg {
    stroke: #fff;
    fill: #fff;
  }
  @media screen and (max-width: 767px) {
    position: absolute;
    top: 16px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;

  @media screen and (max-width: 767px) {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
`;
