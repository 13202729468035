import { useState } from 'react';

import { Content, Inner, Item, Wrapper } from './styles';

const CardAssets = ({ item, onClick, size, activeToken }) => {
  const [isLoaded, setLoaded] = useState(false);
  const getClass = () => {
    switch (size) {
      case 3:
        return 'variant3';
      case 2:
        return 'variant2';
      default:
        return 'variant1';
    }
  };

  return (
    <Wrapper className={getClass()} size={size}>
      <Inner>
        <Content>
          <Item
            style={isLoaded ? { filter: 'blur(0)' } : { filter: 'blur(5px)' }}
            onClick={() => onClick(item)}
            size={size}
            isActive={item === activeToken?.attributes?.number}
          >
            <img
              onLoad={() => setLoaded(true)}
              src={`https://assets.metaplayerone.app/assets/resized/${item}.png`}
              alt=""
            />
          </Item>
        </Content>
      </Inner>
    </Wrapper>
  );
};
export default CardAssets;
