/* eslint-disable camelcase */

export const subTitle = [
  'We have created Shard Crystals as a super pass across web3 communities. Shard Crystals NFT - 1220 units - opens access to private channels and metaverses. Gives energy and a lot more in the game play  ',
  'Assets of Metaplayerone worlds can be generated by anyone utilizing Goovy tokens\nand added to the ecosystem or sold by the Player token.',
  'Avatars of Metaplayerone worlds can be generated by anyone utilizing Goovy tokens and added to the ecosystem or sold by the Player token.',
  'We have created Crystals of Power for the separated decentralized governance of the 5\ndifferent ecosystems. 5 types of Crystals of Power NFT - 3300 units - 5 utility token mint smart contracts share a commission to these  NFT holders in real time',
];
export const shards = [
  {
    image: 'https://assets.metaplayerone.app/assets/shard/91.webp',
    video: 'https://assets.metaplayerone.app/assets/shard/91.mp4',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/shard/94.webp',
    video: 'https://assets.metaplayerone.app/assets/shard/94.mp4',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/shard/97.webp',
    video: 'https://assets.metaplayerone.app/assets/shard/97.mp4',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/shard/98.webp',
    video: 'https://assets.metaplayerone.app/assets/shard/98.mp4',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/shard/100.webp',
    video: 'https://assets.metaplayerone.app/assets/shard/100.mp4',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/shard/103.webp',
    video: 'https://assets.metaplayerone.app/assets/shard/103.mp4',
  },
];
// export const shards = [
//   {
//     image: 'https://assets.metaplayerone.app/assets/images/shard/1.png',
//     video: 'https://assets.metaplayerone.app/assets/videos/1.mp4',
//   },
//   {
//     image: 'https://assets.metaplayerone.app/assets/images/shard/2.png',
//     video: 'https://assets.metaplayerone.app/assets/videos/2.mp4',
//   },
//   {
//     image: 'https://assets.metaplayerone.app/assets/images/shard/3.png',
//     video: 'https://assets.metaplayerone.app/assets/videos/3.mp4',
//   },
//   {
//     image: 'https://assets.metaplayerone.app/assets/images/shard/4.png',
//     video: 'https://assets.metaplayerone.app/assets/videos/4.mp4',
//   },
//   {
//     image: 'https://assets.metaplayerone.app/assets/images/shard/5.png',
//     video: 'https://assets.metaplayerone.app/assets/videos/5.mp4',
//   },
//   {
//     image: 'https://assets.metaplayerone.app/assets/images/shard/6.png',
//     video: 'https://assets.metaplayerone.app/assets/videos/6.mp4',
//   },
// ];
export const locked = [
  {
    image: 'https://assets.metaplayerone.app/assets/images/shard/crystal-lock3.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/shard/crystal-lock3.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/shard/crystal-lock3.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/shard/crystal-lock3.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/shard/crystal-lock3.png',
  },
];
export const locked_m = [
  {
    image: 'https://assets.metaplayerone.app/assets/images/shard/crystal-lock3.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/shard/crystal-lock3.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/shard/crystal-lock3.png',
  },
];
