import { clsx } from 'helpers/clsx';

import styles from './styles.module.scss';

export const SimpleTable = ({ headers, content, tdClassName = '', thClassName = '' }) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {headers.map((el) => (
            <th key={el} className={clsx(styles.th, thClassName)}>
              {el}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {content.map((rowItem) => (
          <tr key={rowItem.id}>
            {Object.values(rowItem)
              .slice(1, Object.values(rowItem).length)
              .map((columnValue, idx) => (
                <td key={`${rowItem.id}_${idx + 1}`} className={clsx(styles.td, tdClassName)}>
                  {columnValue as any}
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
