import { FC } from 'react';

import styles from './styles.module.scss';

interface ILoaderProps {
  className?: string;
}

const Loader: FC<ILoaderProps> = ({ className }) => {
  return <div className={`${styles.loader} ${className}`} />;
};
export default Loader;
