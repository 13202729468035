/* eslint-disable react/no-unknown-property */
import { useEffect } from 'react';
import Modal from 'react-modal';

import IconClose from '../../icons/IconClose';

import { Close, Conatainer, Content, Wrapper } from './styles';

const TeaserModal = (props) => {
  const { isOpenModal, closeModal, src } = props;
  useEffect(() => {
    if (isOpenModal) {
      if (document.getElementById('header')) {
        document.getElementById('header').style.zIndex = '0';
      }
      if (document.getElementById('panorama')) {
        document.getElementById('panorama').style.zIndex = '-1';
      }
    } else {
      if (document.getElementById('header')) {
        document.getElementById('header').style.zIndex = '1000';
      }
      if (document.getElementById('panorama')) {
        document.getElementById('panorama').style.zIndex = '0';
      }
    }
  }, [isOpenModal]);
  return (
    <Modal isOpen={isOpenModal} onRequestClose={closeModal} shouldCloseOnOverlayClick ariaHideApp={false}>
      <Wrapper>
        <Conatainer>
          <Content>
            <Close onClick={closeModal}>
              <IconClose />
            </Close>
            <iframe
              id="player"
              src={src}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer"
              autoPlay
              clipboard-write="true"
              encrypted-media="true"
              gyroscope="true"
              picture-in-picture="true"
              allowFullScreen="allowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              msallowfullscreen="msallowfullscreen"
              oallowfullscreen="oallowfullscreen"
              webkitallowfullscreen="webkitallowfullscreen"
            />
          </Content>
        </Conatainer>
      </Wrapper>
    </Modal>
  );
};

export default TeaserModal;
