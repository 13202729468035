import { Dispatch, FC, SetStateAction } from 'react';
import { clsx } from 'helpers/clsx';

import styles from './styles.module.scss';

export type RadioItem = {
  title: string;
  value: string;
};

interface IRadioButtonProps {
  values: RadioItem[];
  activeValueKey: string;
  onChange: Dispatch<SetStateAction<RadioItem>>;
}

export const RadioButton: FC<IRadioButtonProps> = ({ values, activeValueKey, onChange }) => {
  console.log(activeValueKey);
  return (
    <div className={styles.form}>
      {values.map(({ title, value }) => (
        <button key={value} type="button" onClick={() => onChange({ title, value })}>
          <div className={clsx(styles.dot, activeValueKey === value && styles.checked)} />
          {title}
        </button>
        // <label className={styles.formControl} key={title} htmlFor={value}>
        //   <input
        //     type="radio"
        //     name={value}
        //     id={value}
        //     checked={activeValueKey === value}
        //     onClick={() => onChange({ title, value })}
        //   />
        //   {title}
        // </label>
      ))}
    </div>
  );
};
