import { useEffect, useState } from 'react';

import { TokenDetailModal } from '../../components/modals';
import TABS from '../../const/tabs';
import useAuth from '../../helpers/auth';
import { TabSlider } from '../main/sections/meta-skynet/sliders';

import EmptyCard from './empty-card';
import { Container, List, Title, Wrapper } from './styles';
import { AssetsOfMeta, CrystalOfPower, GodsOfMeta, ShardCrystals } from './tabs';

const AssetsPage = () => {
  const { user } = useAuth();
  const [tab, setTab] = useState(TABS.SHARD_CRYSTAL);
  const [isOpenModal, setOpenModal] = useState(false);
  const [activeToken, setActiveToken] = useState();
  const [shards, setShards] = useState([]);
  const [crystalsPower, setCrystalsPower] = useState([]);
  const [loadingShard, setLoadingShard] = useState(true);
  const [loadingCrystals, setLoadingCrystals] = useState(true);
  const [tokens, setTokens] = useState([]);
  const onClick = (item) => {
    setOpenModal(true);
    setActiveToken(item);
  };

  const getList = () => {
    switch (tab) {
      case TABS.SHARD_CRYSTAL:
        return (
          <ShardCrystals
            setShards={setShards}
            shards={shards}
            setLoading={setLoadingShard}
            loading={loadingShard}
            onClick={onClick}
          />
        );
      case TABS.ASSETS_OF_META:
        return <AssetsOfMeta />;
      case TABS.GODS_OF_META:
        return <GodsOfMeta />;
      case TABS.CRYSTALS_OF_POWER:
        return (
          <CrystalOfPower
            setCrystalsPower={setCrystalsPower}
            crystalsPower={crystalsPower}
            setLoading={setLoadingCrystals}
            loading={loadingCrystals}
            onClick={onClick}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (tab === TABS.SHARD_CRYSTAL) {
      setTokens(shards);
    } else if (tab === TABS.CRYSTALS_OF_POWER) {
      setTokens(crystalsPower);
    } else {
      setTokens([]);
    }
  }, [crystalsPower, shards, tab]);

  useEffect(() => {
    if (!user) {
      setCrystalsPower([]);
      setShards([]);
    }
  }, [user]);

  const handlePrev = () => {
    setActiveToken(
      // eslint-disable-next-line no-unsafe-optional-chaining
      tokens[tokens?.indexOf(activeToken) - 1 >= 0 ? tokens?.indexOf(activeToken) - 1 : tokens?.indexOf(activeToken)],
    );
  };
  const handleNext = () => {
    setActiveToken(
      tokens[
        // eslint-disable-next-line no-unsafe-optional-chaining
        tokens?.indexOf(activeToken) + 1 < tokens?.length
          ? // eslint-disable-next-line no-unsafe-optional-chaining
            tokens?.indexOf(activeToken) + 1
          : tokens?.indexOf(activeToken)
      ],
    );
  };
  return (
    <Wrapper>
      <Container>
        <Title className="font">My assets</Title>
        <TabSlider setTab={setTab} tab={tab} />
        <List>{user ? getList() : <EmptyCard />}</List>
      </Container>
      <TokenDetailModal
        isOpenModal={isOpenModal}
        closeModal={() => setOpenModal(false)}
        item={activeToken}
        prev={handlePrev}
        next={handleNext}
        tokens={tokens}
      />
    </Wrapper>
  );
};
export default AssetsPage;
