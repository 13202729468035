import { FC } from 'react';
import CustomButton from 'modules/participate/components/custom-button';

import { MOCK_INFO_DATA } from './mock';

import styles from './styles.module.scss';

interface ITokenInfoProps {
  handleBecomeValidator: () => void;
  isApplyToAcceleration?: boolean;
}

export const TokenInfo: FC<ITokenInfoProps> = ({ handleBecomeValidator, isApplyToAcceleration }) => {
  return (
    <div className={styles.root}>
      <div className={styles.infoBox}>
        {MOCK_INFO_DATA.map(({ title, value, isTokenValue }) => (
          <div className={styles.textContainer} key={title}>
            <p className={styles.title}>{title}</p>
            <div className={styles.valueContainer}>
              <p className={styles.value}>{value}</p>
              {isTokenValue && <p className={styles.tokenName}>MEU</p>}
            </div>
          </div>
        ))}
      </div>
      <CustomButton onClick={handleBecomeValidator} variant="filled" className={styles.becomeValidatorButton}>
        {isApplyToAcceleration ? 'apply to acceleration' : 'become a validator'}
      </CustomButton>
    </div>
  );
};
