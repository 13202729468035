import { terms } from './data';
import { Block, Container, SubTitle, Text, Title, Wrapper } from './style';

const Terms = () => (
  <Wrapper>
    <Container>
      <Title>Terms & Conditions</Title>
      {terms?.map((item) => (
        <Block key={item?.title}>
          <SubTitle>{item?.title}</SubTitle>
          <Text dangerouslySetInnerHTML={{ __html: item?.text }} />
        </Block>
      ))}
    </Container>
  </Wrapper>
);

export default Terms;
