import TagManager from 'react-gtm-module';
import { MoralisProvider } from 'react-moralis';
import { SnackbarProvider } from 'notistack';

import { appId, serverUrl } from './const/const';
import BaseRouter from './router';

const tagManagerArgs = {
  gtmId: 'GTM-TXSWG7Z',
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  return (
    <MoralisProvider appId={appId} serverUrl={serverUrl}>
      <SnackbarProvider maxSnack={3}>
        <BaseRouter />
      </SnackbarProvider>
    </MoralisProvider>
  );
};

export default App;
