/* eslint-disable camelcase */
import { useState } from 'react';
import Modal from 'react-modal';

import IconBack from '../../../assets/icons/arrow-left.svg';
import IconChecked from '../../../assets/icons/checked.svg';
import { Column, Flex } from '../../containers';
import IconClose from '../../icons/IconClose';
import { FilledButton } from '../../ui';

import { crystal, my_crystals } from './data';
import {
  Amount,
  Back,
  Block,
  CenterWrap,
  Checked,
  Close,
  Conatainer,
  Description,
  Footer,
  Gradient,
  Image,
  Item,
  List,
  Name,
  Notice,
  Rate,
  Row,
  SmallCrystal,
  SmallText,
  Step,
  Title,
  Wrap,
  WrapList,
  Wrapper,
} from './styles';

const MintingAssetModal = (props) => {
  const { isOpenModal, closeModal } = props;
  const [step, setStep] = useState('5');
  const onClose = () => {
    closeModal();
    setStep('1');
  };

  return (
    <Modal isOpen={isOpenModal} onRequestClose={closeModal} shouldCloseOnOverlayClick ariaHideApp={false}>
      <Wrapper>
        <Conatainer>
          <Close onClick={onClose}>
            <IconClose />
          </Close>
          {step === '1' && (
            <>
              <Title>Asset of Meta</Title>
              <Row>
                <Image>
                  <img src={crystal.image} alt="" />
                </Image>
                <Column>
                  <Name>{crystal.name}</Name>
                  <Description>{crystal.description}</Description>
                  <Rate>Minting rate</Rate>
                  <Wrap>
                    <SmallCrystal>
                      <img src="https://assets.metaplayerone.app/assets/images/assets/shard.png" alt="" />
                    </SmallCrystal>
                  </Wrap>
                </Column>
              </Row>
              <Footer>
                <FilledButton title="Choose crystals for burn" onClick={() => setStep('2')} />
                <Step>Step {step} of 3</Step>
              </Footer>
            </>
          )}
          {step === '2' && (
            <>
              <Title>Choose crystals for burn</Title>
              <WrapList>
                <List>
                  {my_crystals?.map((item, index) => (
                    <Item key={`${index + 1}`}>
                      <img src={item?.image} alt="" />
                      <Checked>
                        <img src={IconChecked} alt="" />
                      </Checked>
                    </Item>
                  ))}
                </List>
                {my_crystals?.length > 12 && <Gradient />}
              </WrapList>

              <Footer>
                <Flex>
                  <Back onClick={() => setStep('1')}>
                    <img src={IconBack} alt="" />
                  </Back>
                  <FilledButton title="review" onClick={() => setStep('3')} />
                  <Block>
                    <Rate>Minting rate</Rate>
                    <Amount>{crystal.rate} crystals</Amount>
                  </Block>
                  <Block>
                    {my_crystals.length >= crystal.rate ? (
                      <>
                        <Rate>You pick</Rate>
                        <Amount>{crystal.rate} crystals</Amount>
                      </>
                    ) : (
                      <Notice>
                        You dont have enough crystals
                        <br /> for minting
                      </Notice>
                    )}
                  </Block>
                </Flex>
                <Step>Step {step} of 3</Step>
              </Footer>
            </>
          )}
          {step === '3' && (
            <>
              <Title>Mint Crystal Of Power</Title>
              <Row>
                <Image>
                  <img src={crystal.image} alt="" />
                </Image>
                <Column>
                  <Name>{crystal.name}</Name>
                  <Description>{crystal.description}</Description>
                  <Rate>Crystals for burn</Rate>
                  <Wrap>
                    <SmallCrystal>
                      <img src="https://assets.metaplayerone.app/assets/images/shard/shard52.svg" alt="" />
                    </SmallCrystal>
                  </Wrap>
                </Column>
              </Row>
              <Footer>
                <Flex>
                  <Back onClick={() => setStep('2')}>
                    <img src={IconBack} alt="" />
                  </Back>
                  <FilledButton title="MINT crystal" />
                </Flex>

                <Step>Step {step} of 3</Step>
              </Footer>
            </>
          )}
          {step === '4' && (
            <>
              <Title>Signing transaction</Title>
              <SmallText>Sign the transaction in your Wallet</SmallText>
            </>
          )}
          {step === '5' && (
            <CenterWrap>
              <Image>
                <img src={crystal?.image} alt="" />
              </Image>
              <Title>Congratulations!</Title>
              <Description>{crystal?.description}</Description>
              <FilledButton title="ok, got it" onClick={onClose} />
            </CenterWrap>
          )}
        </Conatainer>
      </Wrapper>
    </Modal>
  );
};

export default MintingAssetModal;
