import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
`;
export const Container = styled.div`
  display: block;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  padding: 0 100px;
  @media screen and (max-width: 1024px) {
    padding: 0 32px;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
  }
`;
export const Title = styled.div`
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  text-align: center;
  padding: 48px 0 24px 0;
  @media screen and (max-width: 1024px) {
    font-size: 32px;
    line-height: 40px;
  }
  @media screen and (max-width: 767px) {
    padding: 24px 0;
  }
`;
export const List = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 24px 0;
  @media screen and (max-width: 767px) {
    padding: 24px 20px;
  }
`;
