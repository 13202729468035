import { Dispatch, FC, SetStateAction } from 'react';
import { clsx } from 'helpers/clsx';

import styles from './styles.module.scss';

export type TabItem = {
  title: string;
  value: string;
};

interface ITabsProps {
  tabs: TabItem[];
  activeTab: TabItem;
  setActiveTab: Dispatch<SetStateAction<TabItem>>;
  size?: 'md' | 'sm';
  isUnderlined?: boolean;
  className?: string;
}
export const Tabs: FC<ITabsProps> = ({ tabs, activeTab, setActiveTab, size = 'md', isUnderlined, className }) => {
  return (
    <div className={clsx(styles.tabs, isUnderlined && styles.underlined, className)}>
      {tabs.map(({ value, title }) => (
        <button
          className={clsx(styles.tabItem, styles[size], activeTab.value === value && styles.activeTabItem)}
          key={value}
          type="button"
          onClick={() => setActiveTab({ title, value })}
        >
          {title}
          {isUnderlined && <div className={styles.border} />}
        </button>
      ))}
    </div>
  );
};
