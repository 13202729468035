import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ProvideAuth } from './helpers/AuthProvider';
import CatalogPage from './modules/catalog';
import MainPage from './modules/main';
import AssetsPage from './modules/my-assets';
import Participate from './modules/participate';
import Terms from './modules/terms-conditions';
import Layout from './layout';

const BaseRouter = () => {
  return (
    <BrowserRouter>
      <ProvideAuth>
        <Layout>
          <Routes>
            <Route exact path="/" element={<MainPage />} />
            <Route path="/my-assets" element={<AssetsPage />} />
            <Route path="/catalog" element={<CatalogPage />} />
            <Route path="/terms-conditions" element={<Terms />} />
            <Route path="/validators" element={<Participate />} />
          </Routes>
        </Layout>
      </ProvideAuth>
    </BrowserRouter>
  );
};

export default BaseRouter;
