import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
`;
export const Close = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: fixed;
  background: transparent;
  border-radius: 50%;
  top: 12px;
  right: 12px;
  stroke: #000;
  & svg {
    stroke: #fff;
    fill: #fff;
  }
`;
export const Conatainer = styled.div`
  width: auto;
  height: auto;
  overflow-y: auto;
  position: relative;
  display: flex;
  justify-content: center;
  background: transparent;
`;
export const Content = styled.div`
  width: fit-content;
  height: fit-content;
  background: transparent;
  & iframe {
    width: 950px;
    height: 620px;
    background: transparent;
    @media screen and (max-width: 1024px) {
      width: 750px;
      height: 420px;
    }
    @media screen and (max-width: 767px) {
      width: 360px;
      height: 300px;
    }
  }
`;
export const SubTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  padding-bottom: 32px;
  @media screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 20px;
    color: #7e8390;
    padding-bottom: 24px;
  }
`;
