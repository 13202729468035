import { useFormContext } from 'react-hook-form';
import lget from 'lodash/get';

import { renderInputError } from '../../../helpers/renderInputError';

import { Error, Input, Wrapper } from './styles';

const HookInput = ({ name, placeholder, rules, type = '', step = 1, setForm = () => {}, form = {} }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const hasError = lget(errors, name);
  return (
    <Wrapper className="input_wrap">
      {form ? (
        <Input
          step={step}
          error={hasError}
          placeholder={placeholder}
          type={type}
          {...register(name, {
            ...rules,
            onChange: (e) => {
              setForm({
                ...form,
                [name]: e.target.value,
              });
            },
          })}
          onWheel={(e) => e.target.blur()}
        />
      ) : (
        <Input
          step={step}
          error={hasError}
          placeholder={placeholder}
          type={type}
          {...register(name, {
            ...rules,
          })}
          onWheel={(e) => e.target.blur()}
        />
      )}

      {hasError && <Error>{renderInputError(hasError, rules)}</Error>}
    </Wrapper>
  );
};
export default HookInput;
