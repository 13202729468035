/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import Modal from 'react-modal';
import axios from 'axios';

import IconEfir from '../../../assets/icons/ethereum.svg';
import IconMinus from '../../../assets/icons/minus.svg';
import IconPlus from '../../../assets/icons/plus.svg';
import shardabi from '../../../const/abis/Shard.json';
import { isDev, shard_address, shard_gold_price, shard_price, shard_white_price } from '../../../const/const';
import Moralis from '../../../const/moralis';
import useAuth from '../../../helpers/auth';
import { getError } from '../../../helpers/getError';
import { Flex } from '../../containers';
import IconClose from '../../icons/IconClose';
import { FilledButton } from '../../ui';

import {
  Button,
  ButtonWrap,
  Close,
  Conatainer,
  Info,
  Input,
  InputWrap,
  Item,
  List,
  MiddleText,
  Number,
  OptionWrap,
  Row,
  SmallText,
  Title,
  WrapCenter,
  Wrapper,
} from './styles';

const MintingShardModal = (props) => {
  const { isOpenModal, closeModal } = props;
  const { user, web3 } = useAuth();
  const [step, setStep] = useState(1);
  const [price, setPrice] = useState(shard_price);
  const [limit, setLimit] = useState(3);
  const [amount, setAmount] = useState(1);
  const [transaction, setTransaction] = useState();
  const [tokens, setTokens] = useState([]);
  const [list, setList] = useState([]);
  const [error, setError] = useState('Something went wrong');

  useEffect(() => {
    const load = async () => {
      if (web3) {
        const ShardContract = new web3.eth.Contract(shardabi, shard_address);
        // if incorrect network - not working. Web3 needs to be fixed
        const isGold = await ShardContract.methods.isGoldList().call();
        if (isGold) {
          setPrice(shard_gold_price);
          setLimit(10);
        } else {
          const isWhite = await ShardContract.methods.isWhiteList().call();
          if (isWhite) {
            setPrice(shard_white_price);
          } else {
            setPrice(shard_price);
          }
        }
      }
    };
    load();
  }, [user, web3]);
  const onAmountMinus = () => {
    if (amount > 0) {
      setAmount(amount - 1);
    } else {
      setAmount(0);
    }
  };
  const onAmountPlus = () => {
    if (amount < limit) {
      setAmount(amount + 1);
    } else {
      setAmount(amount);
    }
  };
  const onClose = () => {
    closeModal();
    setAmount(1);
    setStep(1);
  };

  const mint = async () => {
    setStep(2);
    try {
      const ShardContract = new web3.eth.Contract(shardabi, shard_address).methods;
      console.log(price * amount * 10 ** 18 + 64);
      await ShardContract.mint(amount).call({
        from: user.get('ethAddress'),
        value: price * amount * 10 ** 18 + 64,
      });
      const tx = await ShardContract.mint(amount).send({
        from: user.get('ethAddress'),
        value: price * amount * 10 ** 18 + 64,
      });
      setTransaction(tx.transactionHash);
      const args = {
        dataLayer: {
          event: 'purchase',
          purchase: {
            id: tx.transactionHash,
            affiliation: window.location.href,
            total_value: price * amount,
            currency: 'ETH',
            products: [
              {
                name: 'MetaSkynet Shard Crystal',
              },
            ],
          },
        },
      };
      TagManager.dataLayer(args);
      await Moralis.Cloud.run('add-affilate', {
        hash: tx.transactionHash,
        url: window.location.href,
        user: user.get('ethAddress'),
      });
      if (amount > 1) {
        // eslint-disable-next-line no-restricted-syntax
        for (const id of tx.events.Transfer.map(({ returnValues }) => returnValues?.tokenId)) {
          // eslint-disable-next-line no-await-in-loop
          list.push(await ShardContract.tokenURI(id).call());
        }
      } else {
        list.push(await ShardContract.tokenURI(tx?.events?.Transfer.returnValues?.tokenId).call());
      }
      setTokens(list);

      setStep(3);
    } catch (e) {
      console.error(e);
      setError(getError(e));
      setStep(4);
    }
  };
  useEffect(() => {
    const load = async () => {
      const kk = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const token_uri of tokens) {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await axios.get(token_uri);
        kk.push(data);
      }
      setList(kk);
    };
    load();
  }, [tokens]);
  return (
    <Modal isOpen={isOpenModal} onRequestClose={closeModal} shouldCloseOnOverlayClick ariaHideApp={false}>
      <Wrapper>
        <Conatainer>
          {step !== 2 && (
            <Close onClick={onClose}>
              <IconClose />
            </Close>
          )}

          {step === 1 && (
            <>
              <Title>Mint Shard Crystals</Title>
              <InputWrap>
                <Input>Amount</Input>
                <OptionWrap>
                  <Button onClick={onAmountMinus}>
                    <img src={IconMinus} alt="" />
                  </Button>
                  <Number>{amount}</Number>
                  <Button onClick={onAmountPlus}>
                    <img src={IconPlus} alt="" />
                  </Button>
                </OptionWrap>
              </InputWrap>

              <Info>max amount 3 Shard Crystals</Info>
              <Row>
                <MiddleText>Total price</MiddleText>
                <Flex>
                  <MiddleText>{Math.round(amount * price * 100) / 100}&nbsp;ETH&nbsp;</MiddleText>
                  <img src={IconEfir} alt="" />
                </Flex>
              </Row>
              <ButtonWrap>
                <FilledButton title="Mint crystal" disabled={amount < 1 || amount > 10} onClick={mint} />
              </ButtonWrap>
            </>
          )}
          {step === 2 && (
            <>
              <Title>Signing transaction</Title>
              <SmallText>Please confirm the transaction in your wallet</SmallText>
            </>
          )}
          {step === 3 && (
            <WrapCenter>
              <Title>Congratulations!</Title>
              {/* <SmallText>You just minted {tokens?.length} crystals.</SmallText> */}
              <SmallText>You are one of the Shard Crystals Holders!</SmallText>
              <List>
                {list?.map((item, index) => (
                  <Item key={`${index + 1}`}>
                    <img src={item.image} alt="" />
                  </Item>
                ))}
              </List>
              <ButtonWrap>
                <FilledButton
                  onClick={() =>
                    window.open(`https://${isDev ? 'goerli.' : ''}etherscan.io/tx/${transaction}`, '_blank')
                  }
                  title="CHECK TRANSACTION"
                />
              </ButtonWrap>
            </WrapCenter>
          )}
          {step === 4 && (
            <>
              <Title>Error</Title>
              <SmallText>{error}</SmallText>
              <ButtonWrap>
                <FilledButton onClick={() => setStep(1)} title="TRY AGAIN" />
              </ButtonWrap>
            </>
          )}
        </Conatainer>
      </Wrapper>
    </Modal>
  );
};
export default MintingShardModal;
