import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexCenter = ({ children }) => <Wrapper className="flex_center">{children}</Wrapper>;

export default FlexCenter;
