import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 60px;
  @media screen and (max-width: 767px) {
    background: rgba(0, 0, 0, 0.35);
    padding: 10px 16px 32px 16px;
    height: 100%;
  }
`;
export const Conatainer = styled.div`
  width: 600px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  background: transparent;
  border-radius: 24px;
  padding: 56px;
  @media screen and (max-width: 767px) {
    padding: 20px 0;
    border-radius: 8px;
  }
`;

export const Close = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: absolute;
  background: transparent;
  border-radius: 50%;
  top: 12px;
  right: 16px;
  stroke: #000;
  & svg {
    stroke: #fff;
    fill: #fff;
  }
  @media screen and (max-width: 767px) {
    position: absolute;
    top: 14px;
    right: 0;
  }
`;
export const Title = styled.div`
  width: 100%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 32px;
`;
