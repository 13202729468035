import { FC } from 'react';
import { clsx } from 'helpers/clsx';

import { TextBlock } from '..';

import styles from './styles.module.scss';

interface IBridgeDepositProps {
  className?: string;
}

export const BridgeDeposit: FC<IBridgeDepositProps> = ({ className }) => {
  return (
    <div className={clsx(className)}>
      <TextBlock
        title="Deposit MEU to bridge"
        value={
          <div>
            <p className={styles.blockValue}>MEU is used for automatically signing transactions on the bridge</p>
            <div className={styles.blockValue}>
              Deposit at least <span className={styles.highlightedText}>100 MEU</span> to your bridge operator address:
              <div className={styles.boxedValue}>
                <span className={styles.highlightedText}>69fg....Hkp2</span>
              </div>
            </div>
            <p className={styles.blockValue}>You will need to monitor the balance of this address to avoid slashing </p>
          </div>
        }
      />
    </div>
  );
};
