import styled from 'styled-components';

const Button = styled.button`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(107.75deg, #af01ec 3.49%, #0066ff 97.12%);
  border-radius: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 1px;
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
    box-shadow: 0px 5px 19px rgb(198 138 248 / 25%);
    @media screen and (max-width: 1024px) {
      box-shadow: none;
    }
  }
`;

const SubmitBtn = ({ onClick = () => {} }) => {
  return (
    <Button onClick={onClick} type="submit">
      Submit
    </Button>
  );
};
export default SubmitBtn;
