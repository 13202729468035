import LazyLoad from 'react-lazyload';

import { Column, Crystal, CrystalWrapper, Shards, SubTitle, Title } from '../style';

const CrystalCard = ({ item }) => {
  return (
    <CrystalWrapper>
      <Crystal>
        <LazyLoad className="lazy" debounce={0}>
          <img src={item?.crystal} alt="" />
        </LazyLoad>
      </Crystal>
      <Column className="crystal">
        <Title>{item?.crystal_name}</Title>
        <SubTitle>{item?.crystal_description}</SubTitle>
      </Column>
      <Shards>
        {item?.rate.map((shard, i) => (
          <LazyLoad className="lazy" once key={`${i + 1}`} debounce={0}>
            <img src={shard?.icon} alt="" />
          </LazyLoad>
        ))}
      </Shards>
    </CrystalWrapper>
  );
};
export default CrystalCard;
