import { FC } from 'react';
import { useNavigate } from 'react-router';
import { clsx } from 'helpers/clsx';
import CustomButton from 'modules/participate/components/custom-button';

import { TextBlock } from '..';

import styles from './styles.module.scss';

interface IRequirementsProps {
  className?: string;
}

export const Requirements: FC<IRequirementsProps> = ({ className }) => {
  const push = useNavigate();

  return (
    <div className={clsx(className)}>
      <TextBlock
        title="Requirements"
        value="Everything you need to know before becoming a validator."
        className={styles.textBlock}
      />
      <TextBlock
        titleSize="md"
        title="Required:"
        value={
          <div className={styles.contentText}>
            <p>
              Stake minimum <span className={styles.highlightedText}> 50,000 MEU</span>
            </p>
            <p>
              Node stake minimum <span className={styles.highlightedText}> 1000 MEU</span>
            </p>
            <p>Online and up to date</p>
            <p>A long-term commitment</p>
          </div>
        }
        className={styles.textBlock}
      />
      <div className={styles.whitelistBlock}>
        <p className={styles.title}>Details on:</p>
        <CustomButton
          className={styles.buttonWrapper}
          containedButtonClassName={styles.button}
          variant="contained"
          onClick={() => push('#')}
        >
          MetaPlayerOne Documentation
        </CustomButton>
      </div>
    </div>
  );
};
