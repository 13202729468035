import { lazy, Suspense } from 'react';

import Loader from '../../components/loader';
import { TabletOff, TabletOn } from '../../helpers/responsive';

import styles from './styles.module.scss';

const Teaser = lazy(() => import('./sections/teaser'));
const Bulding = lazy(() => import('./sections/bulding'));
const Daos = lazy(() => import('./sections/daos'));
const GpuCloud = lazy(() => import('./sections/gpu-cloud'));
const InMedia = lazy(() => import('./sections/in-media'));
const MetaSkynet = lazy(() => import('./sections/meta-skynet'));
const MetaUnit = lazy(() => import('./sections/meta-unit'));
const MetaVerse = lazy(() => import('./sections/meta-verse'));
const MpNetwork = lazy(() => import('./sections/mp-network'));
const PlayP2e = lazy(() => import('./sections/play-p2e'));
const Roadmap = lazy(() => import('./sections/roadmap'));
const Soika = lazy(() => import('./sections/soika'));
const AiPlayground = lazy(() => import('./sections/ai-playground'));
const MetaSpace = lazy(() => import('./sections/meta-space'));
// const Partners = lazy(() => import('./sections/partners'));

const MainPage = () => {
  return (
    <Suspense fallback={<Loader className={styles.loader} />}>
      <Teaser />
      <AiPlayground />
      <Soika />
      <TabletOn>
        <Bulding />
      </TabletOn>
      <MpNetwork />
      <GpuCloud />
      <Daos />
      <TabletOff>
        <Bulding />
      </TabletOff>
      <MetaSpace />
      <PlayP2e />
      <MetaUnit />
      <MetaSkynet />
      <MetaVerse />
      <Roadmap />
      <InMedia />
      {/* <Partners /> */}
    </Suspense>
  );
};
export default MainPage;
