import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
`;

export const Container = styled.div`
  display: block;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  padding: 0 100px 32px 32px;
  @media screen and (max-width: 1280px) {
    padding: 0 60px 32px 32px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 32px 32px 32px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 20px 20px 20px;
  }
`;
export const Column = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;
export const Title = styled.div`
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  text-align: center;
  padding: 0 0 24px 0;
  @media screen and (max-width: 1024px) {
    font-size: 32px;
    line-height: 40px;
  }
  @media screen and (max-width: 767px) {
    padding: 16px 0;
  }
`;
export const List = styled.div`
  /* width: calc(100% + ${(props) => props.size * 4}px);
  transform: translate(${(props) => props.size * -2}px, 0); */
  width: calc(100% + 4px);
  transform: translate(-2px, 0);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 24px 0;
  @media screen and (max-width: 767px) {
    padding: 16px 0;
  }
`;

export const ControllPanel = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2px 0 0;
`;
export const Button = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin: 0 0 0 12px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  opacity: ${(props) => (props.isDisabled ? '0.5' : '1')};
  &:hover {
    background: ${(props) => (props.isDisabled ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.3)')};
    cursor: ${(props) => (props.isDisabled ? 'auto' : 'pointer')};
  }
  @media screen and (max-width: 1024px) {
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`;
export const Zoom = styled(Button)`
  margin: 0 12px 0 0;
  opacity: ${(props) => (props.isDisabled ? '0.5' : '1')};
  cursor: auto;
`;
export const Page = styled(Button)`
  background: ${(props) => (props.isActive ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};
`;
export const Wrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
export const Back = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 24px 0 0 68px;
  & > img {
    margin-right: 8px;
  }
  transition: 0.4s;
  &:hover {
    transition: 0.4s;
    opacity: 0.6;
  }
  @media screen and (max-width: 1024px) {
    margin: 0;
  }
`;
export const Amount = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #7e8390;
  padding-right: 24px;
  & > span {
    color: #fff;
  }
`;
