import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 4px 0 0 0;
  min-height: 76px;
`;

export const Input = styled.input`
  width: 100%;
  height: 48px;
  background: #1b1b1c;
  border-radius: 8px;
  border: 1px solid ${(props) => (props.error ? '#E03636' : '#7E8390')};
  padding: 0 16px;
  outline: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  ::placeholder {
    color: #acacac;
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #e03636;
  padding-top: 4px;
`;
