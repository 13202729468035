import { FC, ReactNode } from 'react';
import { clsx } from 'helpers/clsx';

import styles from './styles.module.scss';

interface ITextBlockProps {
  title: string;
  titleSize?: 'md' | 'sm';
  value: string | ReactNode;
  className?: string;
  titleClassName?: string;
}

export const TextBlock: FC<ITextBlockProps> = ({ title, value, className, titleSize, titleClassName }) => {
  return (
    <div className={clsx(styles.root, className)}>
      <p className={clsx(styles.title, titleSize && styles[titleSize], titleClassName)}>{title}</p>
      {typeof value === 'string' ? <p className={styles.value}>{value}</p> : value}
    </div>
  );
};
