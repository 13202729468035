import Slider from 'react-slick';

import IconLock from '../../../../../../assets/icons/lock.svg';
import ArrowL from '../../../../../../assets/icons/slide-left.svg';
import ArrowR from '../../../../../../assets/icons/slide-right.svg';

import { assets } from './data';
import { Button, ButtonLeft, Icon, Slide, SliderWrap, Wrapper } from './styles';

import '../../../../../../assets/css/slick.scss';

const ArrowNext = ({ className, style, onClick }) => (
  <Button style={{ ...style }} onClick={onClick} className={className}>
    <img src={ArrowR} alt="" />
  </Button>
);
const ArrowPrev = ({ className, style, onClick }) => (
  <ButtonLeft style={{ ...style }} onClick={onClick} className={className}>
    <img src={ArrowL} alt="" />
  </ButtonLeft>
);

const AssetsLockedSlider = () => {
  const settings = {
    slidesToShow: 5,
    dots: false,
    arrows: true,
    infinite: true,
    swipe: false,
    speed: 300,
    centerMode: true,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          speed: 400,
          slidesToShow: 1,
          swipe: true,
        },
      },
    ],
  };
  return (
    <Wrapper>
      <SliderWrap>
        <Slider {...settings}>
          {assets?.map((item, index) => (
            <Slide className="slide-ref" key={`${index + 1}`}>
              <img className="slide-img" src={item.image} alt="" />
              <Icon src={IconLock} alt="" />
            </Slide>
          ))}
        </Slider>
      </SliderWrap>
    </Wrapper>
  );
};
export default AssetsLockedSlider;
