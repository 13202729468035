import { clsx } from 'helpers/clsx';
import { LoopIcon } from 'modules/participate/assets';
import { BaseInput } from 'modules/participate/components';

import styles from './styles.module.scss';

export const Search = ({ wrapperClassName }: any) => {
  return (
    <BaseInput
      endAdornment={<LoopIcon />}
      wrapperClassName={clsx(styles.wrapper, wrapperClassName)}
      placeholder="Search by project name"
      className={clsx(styles.input)}
    />
  );
};
