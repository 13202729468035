import { FC, useState } from 'react';
import { clsx } from 'helpers/clsx';
import { BaseInput } from 'modules/participate/components';

import { AboutPartners } from '../about-partners';
import { TextBlock } from '..';

import styles from './styles.module.scss';

interface IValidatorInfoProps {
  className?: string;
  isNodeOptionSelected: boolean;
}

export const ValidatorInfo: FC<IValidatorInfoProps> = ({ className, isNodeOptionSelected }) => {
  const [adminAddress, setAdminAddress] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validatorAddress, setValidatorAddress] = useState('');
  const [bridgeOperatorAddress, setBridgeOperatorAddress] = useState('');
  const [commissionRate, setCommissionRate] = useState('');
  return (
    <>
      <div className={clsx(className)}>
        {isNodeOptionSelected && (
          <button className={styles.ourPartners} type="button" onClick={() => setIsModalOpen(true)}>
            Our Partners
          </button>
        )}
        <TextBlock
          title="Validator information"
          value="Fill in the information for your validator profile"
          className={styles.titleTextBlock}
        />
        <TextBlock
          titleSize="sm"
          title="Public Address"
          value={
            <BaseInput
              value={adminAddress}
              setValue={setAdminAddress}
              placeholder="0xae....758769t17tfswfdq,dv,hsvxasnx"
            />
          }
          className={styles.formTextBlock}
        />
        <TextBlock
          titleSize="sm"
          title="Node ID"
          value={
            <BaseInput
              value={validatorAddress}
              setValue={setValidatorAddress}
              placeholder="16UAUgfchvjbknlk;kjlhguyftdrsezerdtfyguhijo"
            />
          }
          className={styles.formTextBlock}
        />
        <TextBlock
          titleSize="sm"
          title="BLS Public Key"
          value={
            <BaseInput
              value={bridgeOperatorAddress}
              setValue={setBridgeOperatorAddress}
              placeholder="metaplayerone:..."
            />
          }
          className={styles.formTextBlock}
        />
        {!isNodeOptionSelected && (
          <TextBlock
            titleSize="sm"
            title="Pls provide a desired commission rate for delegators (%)"
            value={<BaseInput value={commissionRate} setValue={setCommissionRate} placeholder="0-20" type="textarea" />}
            className={styles.formTextBlock}
          />
        )}
      </div>
      <AboutPartners isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
    </>
  );
};
