/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

import ArrowL from '../../../assets/icons/slide-left.svg';
import ArrowR from '../../../assets/icons/slide-right.svg';
import { Flex } from '../../../components/containers';
import Preloader from '../../../components/loader/index';
import { FilledButton } from '../../../components/ui';
import { legendary } from '../data';

import { ButtonNext, ButtonPrev, Item, Text, Title, Wrapper } from './styles';

const BigCard = (props) => {
  const { item, next, prev, activeIndex, amount, loading, list, openMint } = props;
  const getRarity = () => {
    switch (true) {
      case legendary?.includes(item?.attributes?.number):
        return 'Legendary';
      default:
        return 'Epic';
    }
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  class Video extends React.Component {
    shouldComponentUpdate(nextProps) {
      if (nextProps.value !== this.props.value) {
        return true;
      }
      return false;
    }

    render() {
      return (
        <video type="video/mp4" muted autoPlay loop playsInline disableRemotePlayback>
          <source src={item?.animation_url} type="video/mp4" />
        </video>
      );
    }
  }

  return (
    <Wrapper>
      <Item>{loading ? <Preloader /> : <Video />}</Item>
      <Title>{item?.name}</Title>
      <Text>{getRarity()}</Text>
      <Flex>
        <ButtonPrev onClick={prev} isDisabled={list.indexOf(activeIndex) === 0}>
          <img src={ArrowL} alt="" />
        </ButtonPrev>
        <ButtonNext onClick={next} isDisabled={list.indexOf(activeIndex) === amount - 1}>
          <img src={ArrowR} alt="" />
        </ButtonNext>
      </Flex>
      <FilledButton title="random mint" onClick={openMint} />
    </Wrapper>
  );
};

export default BigCard;
