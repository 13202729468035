import styled from 'styled-components';

export const Wrapper = styled.div`
  position: sticky;
  top: 120px;
  width: 352px;
  min-width: 352px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border-radius: 24px;
  overflow: hidden;
  padding: 0 32px 24px 0;
  .filled-button {
    margin-top: 32px;
  }
`;

export const Title = styled.div`
  white-space: nowrap;
  text-align: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  padding: 8px 0 8px 0;
  @media screen and (max-width: 767px) {
    font-size: 28px;
    line-height: 34px;
  }
`;
export const Text = styled.div`
  white-space: nowrap;
  text-align: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  padding: 0 0 16px 0;
  @media screen and (max-width: 767px) {
    font-size: 28px;
    line-height: 34px;
  }
`;
export const Item = styled.div`
  height: 320px;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  overflow: hidden;
  background-color: #000;
  & img {
    max-height: 100%;
  }
  & video {
    animation: opacity 0.7s ease-in-out;
    width: 100%;
    height: 100%;
  }
  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    align-items: center;
  }
`;

export const ButtonPrev = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50px);
  border-radius: 4px;
  margin: 0 6px;
  opacity: ${(props) => (props.isDisabled ? '0.5' : '1')};
  cursor: ${(props) => (props.isDisabled ? 'auto' : 'pointer')};
  & > img {
    width: 16px;
  }
  &:hover {
    transition: 0.2s;
    background: ${(props) => (props.isDisabled ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.3)')};
    @media screen and (max-width: 1024px) {
      background: rgba(255, 255, 255, 0.1);
    }
  }
  @media screen and (max-width: 767px) {
    left: 16px;
  }
`;

export const ButtonNext = styled(ButtonPrev)``;
