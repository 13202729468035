/* eslint-disable no-plusplus */
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link as Scroll } from 'react-scroll';
import { useSnackbar } from 'notistack';

import ImgLogo from '../../assets/icons/logo.svg';
import { Flex, FlexEnd } from '../../components/containers';
import { PrimaryButton } from '../../components/ui';
import useAuth from '../../helpers/auth';
import { TabletOff, TabletOn } from '../../helpers/responsive';
import useHeight from '../../helpers/useHeightComponent';
import usePosition from '../../helpers/usePosition';

import { navigations } from './data';
import Profile from './profile';
import { ConnectButton, Container, Line, Logo, LogoWrapper, MenuBtn, Navigation, Wrapper } from './styles';

const Header = ({ setOpenMenu }) => {
  const { user, authenticate } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isDisable, setDisable] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const { pathname, search } = useLocation();
  const push = useNavigate();
  const onClickNav = (link) => {
    if (link) {
      push(`/${link}`);
      return;
    }
    if (pathname !== '/') {
      window.scrollTo(0, 0);
    }
    push(`/${search}`);
  };
  window.addEventListener('resize', () => {
    document.fonts.ready.then(() => {
      const font = document.getElementsByClassName('font');
      for (let i = 0; i < font.length; i++) {
        font[i].style.filter = 'blur(0)';
      }
    });
  });
  document.fonts.ready.then(() => {
    const font = document.getElementsByClassName('font');
    for (let i = 0; i < font.length; i++) {
      font[i].style.filter = 'blur(0)';
    }
  });
  document.addEventListener('DOMContentLoaded', () => {
    const lazyBackgrounds = [].slice.call(document.querySelectorAll('.lazy-background'));

    if ('IntersectionObserver' in window) {
      const lazyBackgroundObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            lazyBackgroundObserver.unobserve(entry.target);
          }
        });
      });

      lazyBackgrounds.forEach((lazyBackground) => {
        lazyBackgroundObserver.observe(lazyBackground);
      });
    }
  });

  const login = async () => {
    try {
      setDisable(true);
      await authenticate();
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Error! Wallet is not connected!', {
        autoHideDuration: 3000,
        variant: 'error',
      });
      setDisable(false);
    }
    setDisable(false);
  };

  const options = usePosition();
  const height = useHeight();
  const watch = (item) => {
    if (item.link === 'my-assets' && pathname === '/my-assets') return true;
    if (item.link === 'validators' && pathname === '/validators') return true;
    if (item.position - 80 <= lastScrollY && item.position + item.heightWrap - 80 >= lastScrollY && pathname === '/')
      return true;
    return false;
  };
  useEffect(() => {
    const handleScroll = () => {
      setLastScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const navigationLinks = useMemo(() => navigations(options, height), [height, options]);
  return (
    <Wrapper id="header">
      <Container>
        <LogoWrapper>
          <Scroll to="teaser" smooth offset={0}>
            <Logo onClick={() => push('/')}>
              <img src={ImgLogo} alt="" />
            </Logo>
          </Scroll>
        </LogoWrapper>
        <Flex>
          <TabletOff>
            <Flex className="margin-right: 24px;">
              {navigationLinks.map((item, index) => (
                <Scroll className="nav_link nav_link_header" key={`${index + 1}`} to={item?.link} smooth>
                  <Navigation
                    className="font"
                    onClick={() => onClickNav(item.isNewPage ? item?.link : '')}
                    isActive={watch(item)}
                  >
                    {item.label}
                  </Navigation>
                </Scroll>
              ))}
            </Flex>
            <FlexEnd className="min-width: 185px;">
              {!user && (
                <ConnectButton>
                  <PrimaryButton
                    onClick={login}
                    title="connect wallet"
                    disabled={isDisable}
                    rootClassName="width: 100%;"
                  />
                </ConnectButton>
              )}
              <Profile />
            </FlexEnd>
          </TabletOff>
          <TabletOn>
            <MenuBtn onClick={() => setOpenMenu(true)}>
              <Line />
            </MenuBtn>
          </TabletOn>
        </Flex>
      </Container>
    </Wrapper>
  );
};
export default Header;
