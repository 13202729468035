export const IconYoutube = ({ className }: any) => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1243_87002)">
      <path
        d="M31.8333 9.67447C31.6521 9.02812 31.2992 8.44293 30.812 7.98114C30.311 7.50515 29.6971 7.16465 29.028 6.99181C26.524 6.33181 16.492 6.33181 16.492 6.33181C12.3098 6.28422 8.12857 6.49351 3.972 6.95847C3.30292 7.14409 2.69008 7.49219 2.188 7.97181C1.69467 8.44647 1.33733 9.03181 1.15067 9.67314C0.702256 12.0888 0.484388 14.5416 0.499999 16.9985C0.483999 19.4531 0.701332 21.9051 1.15067 24.3238C1.33333 24.9625 1.68933 25.5451 2.184 26.0158C2.67867 26.4865 3.29467 26.8265 3.972 27.0065C6.50933 27.6651 16.492 27.6651 16.492 27.6651C20.6796 27.7128 24.8661 27.5035 29.028 27.0385C29.6971 26.8656 30.311 26.5251 30.812 26.0491C31.3053 25.5785 31.656 24.9931 31.832 24.3558C32.2921 21.9411 32.5158 19.4873 32.5 17.0291C32.5346 14.5606 32.3112 12.0952 31.8333 9.67314V9.67447ZM13.3027 21.5638V12.4345L21.6493 16.9998L13.3027 21.5638Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1243_87002">
        <rect width="32" height="32" fill="white" transform="translate(0.5 0.998535)" />
      </clipPath>
    </defs>
  </svg>
);
