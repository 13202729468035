import { useState } from 'react';
import { clsx } from 'helpers/clsx';
import { PreviewBanner } from 'modules/participate/components/preview-banner';

import { Tabs } from '../../components/tabs';

import { BecomeValidator, ValidatorsView } from './components';

import styles from './styles.module.scss';

interface IValidatorsProps {
  className?: string;
}

const MOCK_TABS = [
  {
    title: 'Validation POOLS',
    value: 'validationPools',
  },
  {
    title: 'Validation NODES',
    value: 'validationNodes',
  },
];

export const Validators = ({ className }: IValidatorsProps) => {
  const [isBecomeValidatorView, setIsBecomeValidatorView] = useState(false);
  const [activeTab, setActiveTab] = useState(MOCK_TABS[0]);
  return (
    <div className={clsx(styles.root, className)}>
      <BecomeValidator
        className={clsx(styles.becomeValidator, isBecomeValidatorView && styles.visible)}
        setIsBecomeValidatorView={setIsBecomeValidatorView}
      />
      <div className={clsx(styles.pageContent, !isBecomeValidatorView && styles.visible)}>
        <PreviewBanner className={styles.banner} />
        <Tabs isUnderlined tabs={MOCK_TABS} activeTab={activeTab} setActiveTab={setActiveTab} className={styles.tabs} />
        <ValidatorsView currentTab={activeTab.value} setIsBecomeValidatorView={setIsBecomeValidatorView} />
      </div>
    </div>
  );
};

Validators.displayName = 'Validators';
