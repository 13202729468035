import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 16.6%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  @media screen and (max-width: 1280px) {
    width: 20%;
  }
  @media screen and (max-width: 900px) {
    width: 25%;
  }
  @media screen and (max-width: 767px) {
    width: 50%;
  }
`;
export const Inner = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  border: none;
  padding-top: 100%;
`;
export const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
`;
export const Item = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  & video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
`;
