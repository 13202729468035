import { FC } from 'react';

import { IconProps } from '..';

export const GlowSphere1Icon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="724"
      height="1055"
      viewBox="0 0 724 1055"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g filter="url(#filter0_f_1_405)">
        <ellipse
          cx="666.772"
          cy="389.316"
          rx="209.772"
          ry="209.316"
          fill="url(#paint0_linear_1_405)"
          fillOpacity="0.3"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1_405"
          x="0.973938"
          y="-276.026"
          width="1331.6"
          height="1330.68"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="228.013" result="effect1_foregroundBlur_1_405" />
        </filter>
        <linearGradient
          id="paint0_linear_1_405"
          x1="457"
          y1="389.316"
          x2="867.052"
          y2="326.929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AF01EC" />
          <stop offset="1" stopColor="#0066FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
