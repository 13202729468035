import { FC } from 'react';

import { TextBlock } from '..';

interface IConfirmationProps {
  className?: string;
}

export const Confirmation: FC<IConfirmationProps> = ({ className }) => {
  return (
    <div className={className}>
      <TextBlock
        title="Congratulations!"
        value="Your Node will be activated soon and start validating at the next epoch time, find your application status in the candidates list."
      />
    </div>
  );
};
