export const MOCK_INFO_DATA = [
  {
    title: 'Validators',
    value: '0',
  },
  {
    title: 'Delegators',
    value: '6732',
  },
  {
    title: 'Total Stake',
    value: '49.893.814.74',
    isTokenValue: true,
  },
  {
    title: 'Total Rewards',
    value: '0.00',
    isTokenValue: true,
  },
];
