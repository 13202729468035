import { useEffect } from 'react';
import styled from 'styled-components';

import IconPrev from '../../../assets/icons/chevron-left.svg';
import IconNext from '../../../assets/icons/chevron-right.svg';
import { Flex } from '../../../components/containers';
import { Amount, Button, Page } from '../styles';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const Pagination = ({ tokensPerPage, paginate, currentPage, setAmountPage, nextPage, prevPage, amount }) => {
  const pageNumbers = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= Math.ceil(amount / tokensPerPage); i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    setAmountPage(pageNumbers?.length);
  }, [pageNumbers?.length, setAmountPage]);
  const getFirstPagination = () => {
    switch (true) {
      case currentPage === 1:
        return 0;
      case currentPage === 2:
        return 0;
      case currentPage > 2:
        return currentPage - 2;
      default:
        return null;
    }
  };
  const getLastPagination = () => {
    switch (true) {
      case currentPage === 1:
        return 3;
      case currentPage === 2:
        return 3;
      case currentPage > 2:
        return currentPage + 1;
      default:
        return null;
    }
  };
  const showPages = pageNumbers?.slice(getFirstPagination(), getLastPagination());
  return (
    <Wrapper>
      <Amount>
        {tokensPerPage * currentPage < amount ? tokensPerPage * currentPage : amount}
        <span>&nbsp;of&nbsp;{amount}</span>
      </Amount>
      <Flex>
        <Button onClick={() => prevPage()} isDisabled={currentPage === 1}>
          <img src={IconPrev} alt="" />
        </Button>
        {showPages?.map((number) => (
          <Page key={number} onClick={() => paginate(number)} isActive={number === currentPage}>
            {number}
          </Page>
        ))}
        <Button onClick={() => nextPage()} isDisabled={currentPage === pageNumbers?.length}>
          <img src={IconNext} alt="" />
        </Button>
      </Flex>
    </Wrapper>
  );
};
export default Pagination;
