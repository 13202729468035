export const terms = [
  {
    title: 'Latest	Version:	20	MAY 2022',
    text: `<p>DAO	Metaplayer FZ-LLC	("Metaplayer	Social	Network",	“Metaplayerone	MetaSandbox”,	“Metaplayerone	World,	
    Meta,	GameFi	builder”,	“MP1“,	we”,	“our”,	“us”or	“Company”),	a	private	limited	company	incorporated	under	
    the	laws	 of	UAE,	license number	100621,	Dubai	Media	 City, operates	 a	website	 at	metaplayerone.app (the	
    "Website")	 and	 an	 associated	 application	 (“App”)	 via	 which	 MP1 aggregates	 and	 provides	 information	 on	
    metaverses,	meta	startup	tokens,	play-to-earn	games	and	other	virtual	space	related	information,	as	well	as	
    APIs	(including	but	not	limited	to	the	MP1 API),	widgets,	plugins,	extensions,	patches,	listing	of	events,	whether	
    developed	 by	 MP1 or	 other	 third	 parties,	 token	 swap	 functionalities	 (as	 developed	 and	 provided	 by	 third	
    parties),	features	for	online	subscription,	registration	of	account,	registration	of	interest	for	any	of	our	services	
    or	 events,	 our	 messaging	 services,	 our	 loyalty	 rewards	 program,	 update	 functions,	 third	 party	 products	
    (including	applications,	widgets,	coins	and	tokens),	and/or	any	other	services	and	products	provided	by	our	
    Company	whether	by	itself	or	in	conjunction	with	other	third	parties	(individually	and	collectively,	our	“Site	
    Services”).	The	Website	and	App,	our	Site	Services,	as	well	as	any	related	forums,	blogs,	social	media	pages	and	
    other	relevant	platforms	operated	or	maintained	by	MP1 shall	hereinafter	be	severally	and	collectively	referred	
    to	as	the	“Site”.<p> 

    <p>By	accessing	or	using	our	Site	(or	any	of	our	Site	Services),	listing	your	event	on	our	Site,	downloading	our	App,	
    signing	up	for	any	of	our	services	or	products	in	any	way,	you	hereby	accept	these	Website	Terms	of	Use,	our	
    Privacy	Policy,	as	well	as	any	other	terms	of	service	(including	rules	and	guidelines)	that	may	be	implemented	
    from	time	to	time	in	relation	to	our	Site	(collectively,	the	"Agreement").	<p>

    <p>MP1 reserves	the	right	at	its	sole	discretion	to	to	amend	any	provision	of	the	Agreement	(including	this	Terms	
    of	 Use)	 at	 any	 time	 in	 our	 sole	 discretion	 without	 any	 prior	 notice	 to	 you,	 and	 you	 agree	 that	 it	 is	 your	
    responsibility	 to	 check	 for	 the	 updated	 terms	 of	 the	Agreement	 on	 our	Website	 regularly.	 In	any	event,	 by	
    continuing	to	use	any	aspect	of	our	Site	and/or	any	of	our	Site	Services	you	will	be	deemed	to	have	accepted	all	
    amendments	to	the	Agreement	as	may	be	implemented	by	MP1 from	time	to	time.	 If	you	do	not	wish	to	be	
    bound	by	the	Agreement,	do	not	use	any	aspect	of	the	Site	or	Site	Services,	cease	all	access	to	and	use	of	our	
    Site,	our	Site	Services,	our	products	and	services	immediately.	<p>`,
  },
  {
    title: '1. Legal	Capacity	&	Access',
    text: `<p><span>1.1</span>By	accessing	our	Site	and	continuing	to	access	our	Site	and/or	Site	Services,	you	represent	that	you	are	
    above	18	years	of	age,	and	legally	capable	of	entering	into	and	being	bound	by	contracts,	including	but	not	
    limited	to	the	Agreement.<p> 

    <p><span>1.2</span>In	the	event	that	you	are	accessing	our	Site	and/or	Site	Services	on	behalf	of	any	incorporation,	partnership,	
    association,	 organization	 or	 any	 other	 entity	 type	 (“Entity”),	 your	 continued	 access	 of	 our	 Site	 and/or	 Site	
    Services	shall	constitute	your	representation	to	us	that	you	have	been	duly	authorized	by	such	Entity	to	use	
    our	Site	and	our	Site	Services,	perform	any	transactions	or	activities	thereon,	and	to	therefore	bind	your	Entity	
    to	the	Agreement	and	to	any	such	transactions	or	activities	that	you	have	performed.	Accordingly,	when	the	
    term	“you”	is	used	herein	this	Website	Terms	of	Use,	“you”	shall	refer	not	only	to	you	the	actual	user	as	an	
    individual,	but	also	the	Entity	that	you	are	representing,	and	therefore	both	you	and	the	Entity	are	jointly	and	
    severally	 bound	 under	 the	 Agreement.	 Accordingly,	 you	 also	 hereby	 undertake	 that	 the	 Entity	 which	 you	
    represent	is	capable	of	and	agreeable	to	being	bound	by	the	Agreement.	<p>

    <p><span>1.3</span>In	the	event	that	you	submit	a	form	or	query	to	us,	or	you	register	with	us	an	account	(whether	in	your	
    personal	capacity	or	on	behalf	of	the	Entity	that	you	represent),	you	undertake	to	ensure	that	all	information	
    you	provide	to	our	Company	thereto	registration	is	accurate	and	updated,	and	will	promptly	notify	us	via	email	
    to	connect@MP1.market	in	the	event	of	any	changes	to	such	information.<p>

    <p><span>1.4</span>In	the	event	that	you	register	an	account	with	us,	you	are	fully	responsible	for	ensuring	that	your	account	
    login	and	passwords	are	kept	strictly	confidential	and	secure,	as	all	activities	conducted	via	your	account	shall	
    be	 deemed	 as	 all	 acts	 duly	 performed	 and/or	 authorized	 by	 you	 without	 the	 requirement	 for	 any	 further	
    verifications	on	our	part.	Should	you	become	aware	of	any	security	breaches	or	unauthorized	access	to	your	
    account,	you	must	notify	our	Company	immediately	via email	to	connect@MP1.market.	Nonetheless,	you	agree	
    that	you	will	be	fully	responsible	for	all	acts	and	transactions	(including	but	not	limited	to	postings,	Activity	
    Uploads	listings,	and	usage	of	any	of	our	Site	Services)	that	take	place	via	your	account prior	to	your	aforesaid	
    notification	 of	 security	 breach	 to	 us,	and	 you	 undertake	 to	 honour	and	 see	 to	 completion	all	 such	acts	and	
    transactions	that	has	happen<p>

    <p><span>1.5</span>Should	 you	 (whether	directly	by	 yourself	 or	indirectly	 via	 your	 request	 to	us)	upload,	post,	publish,	 or	
    transmit	any	information	on	or	via	our	Site,	or	you	communicate	with	any	other	users	of	our	Site,	you	agree	to	
    be	fully	responsible	for	all	such	posts	and	communications.	Accordingly,	you	undertake	to	only	use	the	Site	in	
    a	legal	and	considerate	manner,	not	to	misuse	or	abuse	the	Site	or	its	other	users,	including	but	not	limited	to	
    not	causing	any	defamation,	harassment,	sedition,	collecting	data	of	other	users	for	your	own	purposes,	and	
    introducing	virus,	trojans	or	hacks.	In	the	event	that	you	misuse	or	abuse	the	Site,	we	shall	be	fully	entitled	to	
    suspend	 or	 ban	 your	 account,	 and/or	 remove	 your	 information	 from	 our	 Site	 without	 any	 prior	 notice	 or	
    liability	to	you.	Nonetheless,	you	agree	that	we	shall	not	be	howsoever	liable	for	any	posts	or	communications	
    by	third	parties	that	offend	you,	although	we	will	sincerely	look	into	any	complaints	in	relation	to	such	posts	
    or	communications	that	you	communicate	to	us	of	in	writing.	<p>

      `,
  },
  {
    title: '2. Updates	 &	 Modifications',
    text: `
    <p><span>2.1</span>You	agree	that	it	is	your	responsibility	to	regularly	check	our	Website	Terms	of	Use,	our	Privacy	Policy,	
    to	keep	yourself	updated	of	any	changes	or	modifications.	<p>

    <p><span>2.2</span>You	agree	that	the	terms	of	our	Agreement	may	be	amended,	modified,	varied	or	revised	from	time	to	time	
    without	any	prior	notice	to	you,	and	your	continued	use	of	our	Site	and/or	Site	Services	 following	any	such	
    changes	constitutes	your	agreement	 to	be	 fully	bound	by	our amended	 terms.	The	date	 that	appears	at	 the	
    commencement	 of	 each	 relevant	 terms	 and	 conditions	 constitutes	 the	 latest	 version	 of	 such	 terms	 and	
    conditions	that	is	in	force	and	binding	on	you	as	part	of	the	Agreement.<p>

    <p><span>2.3</span>You	agree	that	we	are	entitled	to howsoever	modify,	vary,	expand,	suspend,	interrupt,	terminate,	cancel	or	
    discontinue	any	services	or	products	or	accessibility	to	any	feature	or	part	of	our	Site	and/or	Site	Services	at	
    any	time	in	our	sole	discretion	without	any	prior	notice	or	liability to	you,	even	if	you	have	a	registered	account	
    with	us.	<p>

    <p><span>2.4</span>Certain	of	our	Site	Services	incorporate	or	require	the	use	of	third	party	products	and	services.	Therefore	
    your	use	of	such	 third	party	products	and	services	will	be	subject	 to	 the	respective	 third	party’s	 terms	and	
    conditions	and	privacy	policies,	which	you	agree	is	your	responsibility	to	regularly	review	from	time	to	time	to	
    determine	their	acceptability	to	you	before	you	use	such	third	party	products	and	services.	<p>

    <p><span>2.1</span>For	the	avoidance	of	doubt,	all	coin,	token	or	digital	currency	swap	and	exchange	functions	found	on	or	linked	
    to	the	Website	are	provided	“as	is”	received	from	third	parties,	and	are	not	the	products	of	MP1,	as	MP1 does	
    not	process	such	swaps	or	exchanges,	nor	is	MP1 custodian	to	any	form	of	e-money,	stored	value,	digital	coins	
    or	tokens.	<p>

    `,
  },
  {
    title: '3. Our	Site	Content',
    text: `
    <p>(A)	General<p>

    <p><span>3.1</span>No	information,	write-ups,	listings,	prices,	events,	data,	photographs,	pictures,	graphs,	charts,	articles,	news	
    updates,	 budgets,	 forecasts,	 token	 swap	 market	 values,	 project	 information,	 testimonials,	 status,	 team	
    information	of	any	company,	advertisements,	data,	analysis,	reports,	media	files,	APIs,	loyalty	rewards	writeups,	and	other	content	on	our	Site	(collectively,	“Site	Content”),	or	our	newsletters,	EDMs,	marketing	materials,	
    promotional	updates,	API	updates,	support	patches,	messages	(via	any	channels),	or	other	emails	(collectively,	
    "Updates")	 which	 you	 may	 have	 viewed,	 subscribed	 to	 or	 downloaded	 via	 our
    Site	or	Site	Services,	constitutes	advice	of	any	kind,	including	but	not	limited	to	financial	advice,	trading	advice,	
    investment	advice,	insurance	advice,	legal	advice	or	any	other	 form	of	advice	 for	which	a	license	to	provide	
    such	advice	may	be	required	under	applicable	law.	“Site	Content”	and	“Updates”	shall	collectively	be	referred	
    to	as	“Content”.<p>

    <p><span>3.2</span>The content	 on	 our	 Site	 and	 Updates	 are	 meant	 to	 provide	 information	 regarding	 our	 Company,	 our	
    products,	our	services,	our	loyalty	points	scheme,	our	APIs,	our	applications,	rankings,	trade	volume	data,	new	
    startups,	 new	 coin	 launches,	 and	 other	 information	 related	 to	 digital worlds.	 The	 aforesaid	 information	
    constitutes	general	knowledge	only,	and	are	not	meant	for	any	reliance	howsoever	for	any	purpose	of	any	kind	
    whatsoever	by	any	Entity	or	individual.	You	are	therefore	strongly	advised	to	procure	your	own checks	and	
    professional	advice	before	you	decide	to	make	any	trade,	investments	or	swaps	as	no	Content	constitutes	any	
    trading	 or	 investment	 advice	 or	 guarantees	 of	 any	 kind	 (including	 but	 not	 limited	 to	 guarantees	 on	
    gains/benefits/returns).	For	the	avoidance	of	doubt,	our	Company	makes	available	the	Content	and	third	party	
    products	on	our	Site	based	on	information	and/or	products	(such	as	token	swap	functionalities)	procured	from	
    third	party	sources.<p>

    <p>Even	 though	 such	 third	 parties	 may	 have	 agreed	 or	 declared	 that	 all	 information	 and	 products	 that
    they	 provide	 will	 be	 correct	 and/or	 secure,	 and	 our	 Company	 has	 done	 its	 reasonable	 checks	 as	 may	 be	
    commercially	practicable	before	uploading	such	Content	and	products	onto	our	Site,	our	Company	is	not	able	
    to	provide	any	warranties	thereon.	<p>

    <p><span>3.3</span>Our	Site	Services	include,	without	limitation,	our	MP1 API	which	as	of	now	is	available	for	use	without	any	
    charges,	subject	 to	compliance	with	our	API	Terms	of	Service,	and	 third	party	provided	services	/	products	
    such as	token	swaps	and	identification	protection.	Such	third	party	provided	services	are	subject	to	the	terms	
    and	 conditions	 of	 the	 third	 party	 service	 providers.	We	make	 no	 direct	 or	indirect	warranties	 of	any	 kind,	
    express	or	implied,	in	relation	to	our	Site	Services	(including	but	not	limited	to	the	MP1 API	and	third	party	
    provided	functionalities),	and	you	are	therefore	strongly	encouraged	to	conduct	your	own	checks,	verifications,	
    consult	your	advisors	and	satisfy	yourself	of	the	suitability	of	our	Site	Services	as	well	as	the	acceptability	(to	
    you)	of	the	terms	and	conditions	of	the	respective	third	party	service	providers	whose	services/products	are	
    made	available	or	referenced	to	on	our	Site	before	you	use	any	such	Site	Services.	All	use	of	our	Site	Services	
    (including	third	party	provided	services/products)	
    is	at	your	sole	risk	and	discretion.	<p>

    <p><span>3.4</span>Our	Content	may	contain	information	on	third	party	products	and	services,	in	particular	but	not	limited	to	
    Metaverses, Games,	 third	party	tokens	and	secure	identity	vaults	(such	as	MetaMask).	However,	this	does	not	
    imply	any	association	with	or	endorsement	by	such	 third	parties,	even	if	certain	 functionalities	on	our	Site	
    requires	the	use	of	such	third	party	products	(for	example,	the	installation	of	MetaMask	in	order	to	utilize	token	
    swap	functionalities).	The	content	of	such	third	party	services	and	products	as	stated	on	our	Site	and	in	our	
    Updates	are	based	on	information	made	publicly	available	or	by	such	third	parties	to	us,	and	we	therefore	make	
    no	representations	or	warranties	on	the	accuracy	or	updatedness	of	such	third-party	service/product	related	
    information.	You	are	encouraged	to	conduct	your	own	checks	on	any	such	third	party	products	or	services	that	
    you	see	on	our	Site	and/or	our	Updates	before	subscribing	to,	registering	an	account	for,	installing,	trading,	
    swapping,	 investing,	 purchasing	 or	 using	 any	 such	 third	 party
    products	/	services,	whether	from	third	party	sites	or	via	our	Site.<p>

    <p><span>3.5</span>Since	the	Platform	is	fully	decentralized	and	operates	by	DAO	navigation	principle,	our	Content	will	
    contain	information	from	third	party	companies	or	DAO	that	are	seeking	to	sell	assets	inside	the	community,	
    any	 assets	 on	 the	 platform	 which	 is	 not	 a	 peer-to-peer	 transaction	 can	 be	 sold	 only	 inside	 the	 specific	
    community	interconnected	in	the	 form	of	Decentralized	Autonomous	Organization,	and	cannot	be	visible	to	
    other	users	(“DAOs”).	The	information	on	such	sales	 in	our	Content	is	made	available	by	the	DAOs themselves	
    or	procured	from	third	party	public	resources.	The	listing	of	Private	NFT	drops	or	token	sale	allocations within	
    the	DAO	on	our	Site	does	not	indicate	any	express	or	implied	endorsement	by	our Company	of	the	services,	
    products	or	creditworthiness	of	the selling	DAO.	<p>

    <p><span>3.6</span>As	part	of	our	provision	of	information	regarding	metaverses	or	games,	utility	tokens,	and	other	related	
    digital	space information	on	our	Site,	our	Content	may	contain	videos	and	podcasts	(or	links	to	such	videos	and	
    podcasts)	pertaining	to	the	aforesaid	subject-matter	made	available	via	YouTube,	or	the	use	of	YouTube	API	
    Services	(as	defined	in	the	YouTube	Terms	of	Service).	<p>

    <p>In	order	to	access	such	podcasts	and	videos,	you	must	agree	to	be	bound	by	the	“YouTube	Terms	of	Service”	
    found	at	<a href='https://www.youtube.com/t/terms' target="_blank">https://www.youtube.com/t/terms.</a>	 In	 the	event	 that	 you	do	not	agree	with	any	 of	 the	 terms	and	
    conditions	 therein	 the	YouTube	Terms	of	Service,	you	are	not	permitted	 to	access	 the	aforesaid	videos	and	
    podcasts	(or	weblinks	thereto)	found	on	our	Site	and	in	our	Content.<p>

    <p><span>3.7</span>Any	monetary	figures	cited	in	our	Content	may	have	been	converted	from	local	currency	to	United	States	
    Dollars,	 or from	 one	 currency	 to	 another,	 using	 internet	 currency	 conversion	 sites,	 and	 such	 values	 may	
    therefore	vary	or	be	subject	to	change	depending	on	the	rates	provided	by	different	currency	conversion	sites.	<p>

    <p><span>3.8</span>Where	 the	 Content	 contained	 on	 our	Site	and	Updates	are	 derived	 from	 non-English	language	 sources,	
    translations	into	English	may	have	been	done	using	language	conversion	tools.	As	such,	our	Company	assumes	
    no	legal	liability	for	any	inaccuracies	or	misunderstandings	due	to	translations.	<p>

    <p><span>3.9</span>Web	links	to	our	DAOs,	Projects’ websites	may	be	provided	in	our	Content.	Such	web	links	are	to	provide	
    more	information	regarding	the	Projects,	however,	these	web	links	do	not	constitute	any	endorsement	of	the	
    Projects or	their	products	or	services	by	the Company,	nor	do	such	web	links	create	any	form	of	association	or	
    relationship	between	our	Company	and	the	Projects	or	WEB3	Profile.	<p>

    <p><span>3.10</span>Reviews	 of	 our	 services,	 products,	 Content,	 and	 other	 matters	 related	 to	 our	 Company	 provided	 by	
    individuals	or	third party	companies	as	reflected	in	our	Content	are	the	personal	views	of	such	individuals	or	
    third	 party	 companies,	 and	 do	 not	 represent	 the	 view	 of	 the	 Company,	 nor	 any	 implied	 endorsement	 or	
    recommendation	by	the	Company.	Such	reviews	are	also	not	to	be	deemed	as	any	warranty,	express	or	implied,	
    of	the	quality	or	efficacy	of	the	reviewed	products,	services	or	other	subject-matter.	<p>

    <p><span>3.11</span>We	may	make	available	limited	trial	services	via	our	Site	and/or	our	Updates.	You	agree	that	when	you	
    contact	us	for	any	such	free	trial	services,	it	is	not	guaranteed	that	such	free	trial	services	or	certain	features	
    thereto	will	definitely	be	made	available	to	you,	or	the	trial	period	may	be	different	from	what	may	have	been	
    published	on	our	Site,	our	Updates	or	on	other	third	party	channels.<p>

    <p><span>3.12</span>Promotions,	trial	usage,	and	discounts	(if	any)	as	featured	on	our	Site	and/or	our	Updates	are	for	limited	
    time-periods	only	and	subject	to	specific	terms	and	conditions	indicated	as	may	be	applicable	in	order	to	enjoy	
    such	promotions,	trial	usage,	and/or	discounts.	Once	a	promotional	or	trial	period	expires,	you	are	no	longer	
    eligible	 to	 participate	in	 such	 promotions	 or	 trial	 usages,	 and	 our	 Company	is	 not	 howsoever	 obligated	 to	
    provide	any	such	previously	offered	privileges,	trial	usage	or	discounts	to	you	even	if	you	have	contacted	our	
    Company	with	queries	during	the	promotional	period.	<p>

    <p><span>3.13</span>Without	limiting	the	generality	of	the	provisions	thereunder	Section	3(A)	above,	part	of	our	Site	Content	
    includes	information	on	and	the	availability	of	a	loyalty	rewards	scheme	(“Loyalty	Program”)	currently	known	
    as	“MP1 Partnership”	(or	such	other	name	as	may	be	determined	by	our	Company	in	it's	sole	discretion	from	
    time	to	time).	<p>

    <p><span>3.14</span>All	redemptions,	discounts,	promotions,	vouchers,	and	other	privileges	available	thereunder	the	Loyalty	
    Program	are	made	available	 “AS	 IS”	by	 our	Company	and	 our	collaborating	business	partners,	without	any	
    warranties	 of	 any	 kind,	 whether	 implied	 or	 express,	 including	 without	 limitation	 quality,	 availability,	
    merchantability	or	fitness	for	purpose	(even	if	our	Company	has	been	notified	in	advance	of	such	purpose).	
    <p>

    <p><span>3.15</span>MP1 retains	full	sole	discretion	to	howsoever	vary	or	discontinue	any	privileges	thereunder	the	Loyalty	
    Program,	including	without	limitation	discontinuing	redemption	for	any	product	even	if	you	have	used	your	
    loyalty	points	(or	“Candies”)	to	redeem	such	product	(in	which	case	MP1 reserves	the	right	to	substitute	such	
    discontinued	product	with	another	item	of	similar	value	or	to	credit	back	the	Candies	that	you	have	used	for	
    such	redemption).	<p>

    <p><span>3.16</span>For	 the	 avoidance	 of	 doubt,	 our	 Company	 retains	 full	 sole	 discretion	 on	 the	 governing	 rules,	
    implementation,	operation,	variation	and	discontinuation	of	any	Loyalty	Program	that	our	Company	has	made	
    available	or	may	make	available	from	time	to	time,	including	without	limitation	determining	when	and	how	
    many	loyalty	points	should	be	awarded,	to	not	approve	any	redemptions	using	any	loyalty	points,	amend	the	
    number	of	loyalty	points	required	 for	any	redemption,	remove	or	discontinue	redemption	products,	and/or	
    choose	not	to	reward	any	persons	who	participates	in	any	games	or	activities	for	loyalty	points	or	other	gains.	<p>

    `,
  },
  {
    title: '2. Intellectual	Property',
    text: `
    <p>(A)	Ownership	<p>

    <p><span>4.1</span>All	 Content	 and	 Site	 Services,	 including	 but	 not	 limited	 to,	 write-ups,	 compilations,	 listings,	 analysis,	
    summaries,	extracts,	derivations,	articles,	 translations,	quotations,	loyalty	reward	schemes,	contracts,	 terms	
    and	conditions,	APIs,	widgets,	plugins,	extensions,	software,	algorithms,	source	codes,	object	codes,	forecasts,	
    budgets,	 analysis,	 credit	 ratings,	 reports,	 logs,	 diagrams,	 graphs,	 charts,	 layout,	 photographs,	 drawings,	
    financial	information	 and	 other	 data,	 as	 displayed	 on	 our	Site	 and	Site	Services,	in our	 Content	 and	in	 our	
    Updates,	are	protected	by	copyright,	design	rights,	trade	mark	rights	and/or	other	intellectual	property	rights	
    (whether	 owned	 by	 our	 Company	 or	licensed	 to	 our	 Company,	 or	 permitted	 under	 the	law	 for	 use	 by	 our	
    Company),	whether	registered,	registable	or	otherwise.	You	agree	therefore	that	without	our	Company's	prior	
    written	consent	(or	as	may	be	specifically	stated	on	our	Site	in	relation	to	any	particular	portion	of	our	Content	
    such	as	use	of	our	MP1 API	pursuant	to	terms,	except	for	the	limited	use	as	permitted	thereunder	Section	4(B)	
    below,	 you	are	not	 otherwise	permitted	 to	howsoever	 reproduce,	copy,	download,	decompile,	disassemble,	
    extract,	store,	distribute,	lease,	time-share,	publish,	sell,	translate,	modify	or	create	derivative	works	from,	any	
    part	of	our	Content,	products	or	services	found	on	our	Site,	our	Site	Services,	or	in	our	Updates.	We	reserve	our	
    rights	to	take	legal	action	against	you	for	any	such	unauthorised	use	of	our	Content.	<p>

    <p><span>4.2</span>For	clarification,	and	without prejudice	to	Clause	4.1	above,	all	Content	and	Site	Services	(including	but	not	
    limited	 to	 the	MP1 API	and	other	 third	party	products/services	such	as	YouTube)	are	strictly	protected	by	
    copyright	laws.	You	are	therefore	not	permitted	to	howsoever	copy,	reproduce,	duplicate,	download,	derive,	
    modify,	translate,	hack,	distribute,	lease,	rent	or	howsoever	deal	with	any	part	of	our	Content	or	Site	Services	
    except	to	view	or	use	strictly	pursuant	to	the	purpose	for	which	it	was	made	available	<p>

    <p>(on	 your	 computer,	 mobile	 phone,	 tablet	 and	 other	 personal	 devices	 or	 Entity’s	 devices	 that	 are	 under
    and	securely	in	your	control)	as	may	be	permitted	herein	and/or	by	the	other	provisions	of	the	Agreement.	In	
    the	event	that	any	other	provisions	of	the	Agreement	permit	you	to	download,	store,	use,	retain	or	copy	any	
    part	 of	 our	 Content	 or	 any	 mobile	 application	 or	 other	 software	 or	 feature	 (individually	 and	 collective	
    “Downloads”)	available	on	our	Site,	you	agree	that	such	consent	shall	not	constitute	the	transfer	or	assignment	
    of	 any	 rights	 or	 ownership	 in	 such	 Downloads	 to	 you,	 and	 you	 are	 merely	 granted	 a	 non-exclusive,	 nonsublicensable,	 non-transferable	 and	 revocable	 (at	 any	 time	 by	 our	 Company)	 licence
    to	 use	 the	 Downloads	 pursuant	 to	 the	 purpose	 for	 which	 our	 Company granted	 its	 consent.
    The	aforegoing	shall	fully	apply	to	your	use	of	our	MP1API	(as	permitted	under	our	API	Terms	of	Service).	<p>

    <p><span>4.3</span>All	company	names	(including	but	not	limited	to	our	Company's	name, Project names,	and	the	company	
    names	of	any	third	parties,	such	as	YouTube,	featured	on	our	Site),	logos,	trade	marks,	service	marks,	brands,	
    whether	 registered	 or	 otherwise	 (collectively	 the	 "Branding")	 represented	 on	 our	 Site,
    our	 Site	 Services	 and	in	 our	 Updates	 belong	 to	 our	 Company	 or	 to	 third	 parties	 who	 have	 agreed	 (or	 our	
    Company	is	so	permitted	under	applicable	law)	to	display	their	Branding	on	our	Site	and	Site	Services,	in	our	
    Content	 and	 our	Updates.	 You	 are	 therefore	 not	 permitted	 to	 copy,	 replicate,	modify,	extract,	 download	 or	
    howsoever	 use	 any	 such	 Branding	 for	 any	 purpose	 whatsoever	 without	 the	 prior	 written	 consent	 of	 our	
    Company.	<p>

    <p>(B)	Limited	Use	<p>

    <p><span>4.4</span>Subject	to	the	provisions	of	this	Website	Terms	of	Use	and	your	compliance	thereto,	our	Company	hereby	
    grants	you	a	limited,	personal,	non-exclusive,	non-sub-licensable	and	non-transferable	license	to	use	our	Site	
    Content,	 in	 each	 case	 solely	 for	 your	 Personal	 Use	 (as	 defined	 in	 Clause	 4.5	 below)	 only	 and
    not	 for	 any	 commercial	 purpose.	 Except	 for	 the	 aforegoing	license,	 you	 have	 no	 other	 rights	 to	 any	 of	 our	
    Content,	 Site	 Services,	 Branding,	 products	 or	 services,	 and	 without	 limiting	 the	 generality	 of	 Clauses	 4.1
    to	4.3	above,	you	may	not	modify,	edit,	copy,	distribute,	reproduce,	publish,	display,	perform,	license,	sell,	rent,	
    lease,	loan,	develop	derivations,	create	any	index,	translate,	reverse	engineer,	alter,	enhance,	provide	access	to	
    or	in	any	way	exploit	any	part	of	the	Content,	our	Site	Services,	Branding,	or	any	of	our	services	or	products	in	
    any	manner.	
    <p>

    <p><span>4.5</span>“Personal	Use”	shall	refer	to	your	non-commercial	review	and	republication	(on	a	non-commercial	site)	of	
    some	or	part	of	our	Site	Content	(as	screenshots	or	screen	captures	without	any	modification	thereto	and	with	
    due	attribution	to	MP1),	and	the	linking	of	our	Website,	subject	to	Clause	4.6	below.	<p>

    <p><span>4.6</span>	You	may	republish	some	or	part	of	our	Site	Content	(individually	and	collectively,	the	“ScreenCaptures”),	
    and/or	place	one	or	more	links	 to	 the	Website	 (individually	and	collectively,	 the	 “Link”)	on	 your	own	noncommercial	 website,	 blog	 or	 other	 platform	 (individually	 and	 collectively,	 “Your	 Platform”),
    provided	that:	<p>

    <p><span>1</span>Any	 Link	 shall	 be	 titled	 only	 as	 "Link	 to	 metaplayerone.app”;<p>

    <p><span>2</span>Any	ScreenCaptures	shall	be	duly	attributed	with	the	phrase	“Screenshot	from	metaplayerone.app”;	<p>

    <p><span>3</span>No	ScreenCaptures	shall	contain	 third	party	information,	write-ups	or	 feedback	(i.e.	information	 from	an	
    user	of	MP1 or	information	regarding	other	entities,	which	are	not	information	from	or	about	MP1 itself),	nor	
    any	personal	data	(i.e.	information	that	can	be	used	singly	or	in	conjunction	with	other	available	information	
    to	identify	an	individual);	<p>

    <p><span>4</span>Your	Platform	shall	not	contain	any	content	 that	is	unlawful,	 threatening,	scandalous,	seditious,	abusive,	
    libelous,	defamatory	or	otherwise	inappropriate	to	the	image	of	MP1 (as	may	be	determined	by	our	Company	
    in	our	sole	discretion);	<p>

    <p><span>5</span>The	look	and	feel	of	all	content	that	accompanies	the	ScreenCaptures	and/or	Link	or	is	on	the	same	page	as	
    any	ScreenCaptures	or	the	Link	shall	not	(as	determined	in	the	sole	discretion	of	MP1)	bear	any	write- ups,	
    pictures,	content,	insinuations,	or	implications	 that	may	lower	 the	reputation	of	MP1,	damage	or	dilute	 the	
    goodwill	associated	with	MP1,	the	reputation	or	any	Branding	<p>

    <p><span>6</span>No	 content	 on	 Your	 Platform	 shall	 contain	 any	 information	 that	 (in	 MP1’s	 sole	 discretion)	 may	 create
    any	 false	 impression	 that	 you,	 Your	 Platform,	 services,	 products	 or	 any	 other	 website,	 services,	 products,	
    person	or	entity	is	endorsed	by,	sponsored	by	or	otherwise	associated	with	MP1 or	any	of	its	business	partners	
    (as	 reflected	 by	 their	 names,	logos,	 or	 branding	 on	 the	Website),	 or	 that	any	activity	engaged	in	 by	 you	 or	
    anyone	else	has	been	howsoever	endorsed	by	MP1.	<p>

    <p>You	agree	that	our	Company	retains	full	rights	to	revoke	our	consent	granted	herein	to	permit	your	Link	or	
    ScreenCaptures	at	any	time	in	our	sole	discretion	without	any	prior	notice	or	liability	to	you.	Upon	our	email	
    notification	to	you	that	you	may	no	longer	provide	a	Link	or	ScreenCaptures	on	Your	Platform,	you	undertake	
    to	promptly	(in	no	event	later	than	two	days	after	our	notice	to	you)	remove	all	such	objectionable	Link	or	
    ScreenCaptures	from	Your	Platform.<p>

    `,
  },
  {
    title: '5. Third	Party	Sites	and	Browsing	Experience',
    text: `
    <p><span>5.1</span>Please	note	that	we	do	not	control	any	links,	services,	content,	products	(including	but	not	limited	to	the	
    YouTube	videos)	or	resources	provided	by	other	third	parties	via	or	referenced	or	linked	to	our	Site,	our	Site	
    Services,	or	in	our	Updates,	even	if	such	third	party’s	website,	services	and/or	products	are	expressly	made	
    available	on	our	Site	and/or	may	be	co-branded	with	ours	by	bearing	our	Company	logo	or	name,	are	made	
    available	for	redemption	thereunder	our	loyalty	points	scheme,	and/or	our	Company	receives	compensation	
    from	such	third	party	sites.	We	seek	your	further	understanding	that	we	are	unable	to	control	or	influence	any	
    third	parties'	actions	even	if	their	websites	are	hyperlinked	to	our	Site,	our	Site	Services,	or	in	our	Updates.	
    Accordingly,	should	you	decide	to	use	or	access	such	third	parties’	products,	services	and/or	websites,	all	use	
    and	access	are	at	your	sole	risk	and	subject	to	the	terms	and	conditions	of	use	of	such	third	parties’.	If	you	have	
    any	query	on	the	terms	and	conditions	of	use	of	such	third	parties’	websites	or	their	services	or	products,	please	
    contact	the	third	parties	directly.	<p>

    <p><span>5.2</span>For	clarification,	some	of	our	third	party	links	are	“affiliate	links”.	“Affiliate	links”	refer	to	third	party	links	
    on	our	Site	pursuant	to	which	our	Company	may	receive	compensation	from	the	referenced	third	party,	which	
    may	be	in	 the	 form	of	 tokens,	digital	currency,	 fiat	currency,	services	or	products	 from	such	affiliated	 third	
    parties.	Such	compensation	to	our	Company	may	be	provided	to	our	Company	even	if	you	do	not	click	on	the	
    affiliate	links,	and/or	additional	compensation	may	be	provided	to	our	Company	if	you	do	click	on	such	affiliate	
    links	 or	 take	 any	 further	 action	in	 relation	 to	 such	 affiliate	links	 (for	 example,	 signing	 up	 on	 the	 affiliate’s	
    website	or	using	the	affiliate’s	application).	Each	affiliate	link	is	identified	by	an	affiliate	icon	next	to	it.	<p>

    <p><span>5.3</span>Without	limiting	the	generality	of	Clauses	5.1	and	5.2	above,	our	Company	hereby	disclaims	any	and	all	
    scams,	 frauds,	 and	 other	 non-genuine	 services	 or	 products	 that	 may	 be	 displayed	 in	 any	 advertisements,	
    banners	or	podcasts	displayed	on	our	Site	(even	if	such	displayed	services	or	products	are	from	affiliated	third	
    parties	 as	 described	 in	 Clause	 5.2	 above,	 or	 are	 part	 of	 the	 redemption	 rewards	 provided	 thereunder	 our	
    Loyalty	Program).	Our	Company	is	careful	about	the	advertisement	placements	that	it	accepts	and	the	podcasts	
    that	 it	 displays,	 however,	 it	 is	 not	 possible	 for	 us	 to	 check	 and	 verify	 the	 authenticity	 or	 legality	 of	 each	
    advertiser,	advertisement,	podcaster,	podcast	content,	product	or	service	that	is	advertised	with	our	Company.	
    As	such,	you	agree	that	you	will conduct	your	own	due	diligence	and	checks,	as	well	as	accept	all	risks	thereto,	
    should	 you	 in	 your	 own	 voluntary	 discretion	 purchase,	 redeem,	 subscribe	 or	 sign	 on	 for	 any	 products	 or	
    services	advertised,	or	opined	on,	by	third	parties	(including	affiliated	third	parties)	on	our	Site.	<p>

    <p><span>5.4</span>You	agree	that	our	Company	may	employ	cookies,	action	tags,	dynamic	device	identifiers,	or	other	legal	
    technological	 means	 to	 record	 your	 anonymised	 accessing	 of	 our	 Site	 for	 the	 purpose	 of	 providing	 better	
    services	and	enhancing	your	browsing	experience.	<p>

    `,
  },
  {
    title: '6. Minors',
    text: `
    <p>Our	 products	 and	 services	 are	 targeted	 for	 adults	 and	 business	 entities.	 Our	 Site,	 our	 Site	 Services,
    our	 Content	 and	 our	 Updates	 are	 therefore	 not	 meant	 for	 persons	 who	 may	 be	 defined	 as	 minors	 under	
    applicable	 law.	 However,	 it	 is	 not	 possible	 for	 our	 Company	 to	 determine	 whether	 any	 user	 of	 our	 Site
    or	 Site	 Services	is	 a	minor.	 If	 you	 are	 a	minor,	 please	 discontinue	 the	 use	 of	 our	 Site	 and	 all	 Site	 Services	
    immediately.	It	is	the	responsibility	of	parents and	legal	guardians	to	monitor	whether	a	minor	is	using	our	Site	
    or	Site	Services.<p>
    `,
  },
  {
    title: '7. Minors',
    text: `
    <p><span>7.1</span>In	the	event	that	you	provide	us	with	any	feedback	and	comments,	whether	via	email	to	our	Company	or	
    any	postings,	we	thank	you	for	taking	the	time	to	write to	us,	and	your	feedback	and	comments	are	appreciated.	
    Any	such	feedback,	postings,	and	comments	from	you	(“Feedback”)	shall	become	and	remain	the	property	of	
    our	Company.	Our	Company	shall	be	entitled	in	its	sole	discretion	to	howsoever	use,	publish	or	disseminate	
    such	Feedback,	with	or	without	attribution	to	you	as	the	Feedback’s	author,	and	without	having	to	notify	you	
    or	 seek	 your	 consent	in	advance.	Accordingly,	 you	agree	 that	 you	assign	 (without	 the	 requirement	 for	any	
    remuneration)	all	ownership	(including	but	not	limited	to	copyright)	in	the	Feedback to	our	Company	once	you	submit	your	Feedback	 to	our	Company,	and	you	 further	agree	 to	waive	all	moral	
    rights	over	your	Feedback	once	submitted	to	the	Company.	
    <p>

    <p><span>7.2</span>Kindly	 note	 that	 you	 are	 responsible	 for	 your	 Feedback,	 and	 you	 should	 ensure	 that	 such	 Feedback
    does	 not	 contain	 any	 libelous,	 scandalous,	 defamatory,	 offensive,	 seditious,	 misleading,	 misrepresentative,	
    abusive	or	infringing	contents,	particularly	if	such	Feedback	will	be	published	to	the	public	whether	via	our	
    Site,	or	our	Updates,	or	other	means	(even	if	you	were	not	aware	that	your	Feedback	would	be	published).	You	
    agree	to	fully	hold	harmless	and	indemnify	our	Company	for	all	losses	and	costs	suffered	or	incurred	by	our	
    Company	due	to	your	Feedback,	including	but	not	limited	to	third	party	claims,	legal	fees	on	a	solicitor- client	
    basis,	settlement	amounts,	fines,	penalties,	and	law	enforcement	actions.	Accordingly,	and	without	prejudice	to	
    the	 aforegoing,	 you	 also	 hereby	 undertake	 to	 fully	 indemnify,	 defend	 and	 hold	 harmless
    our	 Company,	 our	 employees,	 agents,	 officers,	 shareholders,	 and	 directors	 from	 claims,	 demands,	 direct	
    damages,	indirect	damages,	consequential	damages,	loss	of	opportunities,	loss	of	reputation,	legal	costs	(on	a	
    client-solicitor	 basis)	 and	 other	 losses	 of	 any	 kind	 that	 may	 arise	 in	 relation	 to	 any	 dispute	 that	 you	 may	
    howsoever	have	with	another	user	of	our	Site.<p>
    `,
  },
  {
    title: '8. Disclaimers	and	Limitation	of	Liability',
    text: `
    <p><span>8.1</span>While	 we	 have	 exercised	 due	 care	 in	 the	 preparation	 of	 all	 Content	 displayed	 and/or	 made	 available
    on	our	Site,	our	Site	Services,	and	in	our	Updates,	such	content,	data,	information,	Content	and	materials	are	
    provided	“AS	IS”,	“WITH	ALL	FAULTS"	and	"AS	AVAILABLE”.	Information	pertaining	to	Projects	are	provided	
    by	the	Projects themselves	and/or	third	party	sources	that	feature	information	regarding	the	Project.	<p>

    <p><span>8.2</span>Your use of our site, our site services, and our updates, as well as your viewing, downloading of content (including but not limited to apis such as the mp1videos on youtube and mp1api), is at your own discretion and risk. our company makes no claims, representations, warranties (express or implied) or promises about the quality, accuracy, updateness, non-omissions, continued accessibility, clarity, resolution, noninterruptedness, speed, fitness for a particular purpose (even if we have been informed in advance of such purpose), correctness of financial data and trading volume, trading returns, accuracy of token swaps, security of transactions, projected investment/subscription returns, failed encryption or failure to encrypt, data corruption, quality or quantity of redemption rewards, the acts or omissions of other users of our site, or reliability of our site, our site services, our content or our updates, including but not limited to their safety or security, freedom from computer viruses, worms, trojan horses, and fullproof security against third party hackers.<p>

    <p><span>8.3</span>In addition, our company makes no claims, representations, warranties (express or implied) or promises about the correctness of our content or site services, that any errors in any part of our content or site services will be corrected, that resolutions will be enhanced, merchantablity, quality, timeliness of delivery, usability, continued availability of redemption awards, availability of loyalty points, fitness for a particular purpose (even if we have been informed in advance of such purpose), suitability for specific viewing requirements, returns on investments, creditworthiness, financial status, quality of services/products of startups, security of token swaps, market value accuracy, accuracy of financial information (including projections, budgets and forecasts), accuracy of credit check results, ratings and other due diligence reports, the acts or omissions of other users of our site, non-infringement or reliability of any writeups, products or services displayed on our site are not focused on any kind of financial advice, product or crowdsale – only peer-to-peer transactions are allowed at our site services and/or in our updates. you, at your own volition and discretion, use our site services, access our content, enter into subscriptions, sign-ups and/or enter into other transactions via our site after having done your own due diligence checks and with due consideration, and therefore all subscriptions, sign-ups, and/or other transactions are at your sole risk and voluntary assumption of liability.<p>

    <p><span>8.4</span>Any	information	that	may	be	provided	in	our	Content	in	relation	to	any	Project (where	such	information	is	
      not	provided	by	the	Project itself)	are	based	on	third	party	websites,	service	providers	and	bureaus,	and	we	
      are	therefore	unable	to	warrant	or	undertake	any	liability	on	the	reliability,	validity	and	accuracy	of	such third	
      party	reports.<p>


    <p><span>8.5</span>In no circumstances, to the fullest extent permitted at law, shall our company nor any of its shareholders, directors, officers, agents, representatives or employees be liable for any damages, loss, loss of customers, lost profits, lost interest payments, lost principals, lost business, lost opportunities, loss of investments, lost data, cost of servicing or repair of tablet, smart phones, mobile phones, computer or other equipment, special damages, indirect or consequential loss or damages, howsoever arising or suffered as a result of any use (or inability to use) of our site or our updates or content or site services or our services or our products, or our company's breach of any provision herein this website terms of use or other provisions of the agreement, or misrepresentation or negligence or fraud by any seller, whether due to viruses or third party hackers or embedded malware, or any reliance on or use of the information, content, third party links, the services or products introduced or described herein our site, our site services, or our updates even if our company has been advised of such use or reliance in advance.<p>

    <p><span>8.6</span>Our	Company	further	disclaims	any	and	all	liability	for	any	losses	or	damages	that	you	may	incur	as	a	direct	
    result	of	accessing	or	howsoever	using	(including	but	not	limited	to	storing	of	information	on)	our	Site,	our	Site	
    Services,	our	Content	or	our	Updates,	including	but	not	limited	to	any	virus,	trojan	horse,	malware	or	worm	
    attacks	on	your	tablet,	smart	phone,	or	computer.	<p>

    <p><span>8.7</span>For	the	avoidance	of	doubt,	at	all	times,	our	Company	acts	primarily	as	an	information	service	provider	
    with	ancillary	services	such	as	decentralized	service	access,	and	never	as	a	sales	representative,	trading	
    broker,	 intermediary,	 agent,	 principal,	 financial	 advisor,	 exchange,	 brokerage,	 clearing	 house	 or	
    trading	 platform	 of	 any	 kind	 or	 capacity	 for	 which	 a	 licence	 from	 the	 Monetary	 Authority	 may	 be	
    required.	Accordingly,	no	information	in	our	Content	is	to	be	regarded	as	an	offer,	solicitation,	or	an	invitation	
    to	treat	by	our	Company.	Nothing	in	our	Content	constitutes	any	advice	or	recommendation	to	subscribe to	any	
    particular	Project or	DAO,	trade	in	any	digital coins,	purchase	any	tokens	or	perform	any	token	swaps.	Should	
    you	wish	to	subscribe	to	any	particular	Project,	please	contact	the	relevant	DAO directly.	Similarly,	should	you	
    wish	to	trade	in	any	specific	coins,	please	contact	your	broker.	If	you	wish to	conduct	a	decentralized	token	
    swap	using	any	of	our	third	party	provided/hyperlinked,	or	MP1 token	swap	functionalities,	please	conduct	
    your	own	checks	(including	the	third	party’s	terms	and	conditions)	to	satisfy	yourself	that	your	intended	swap	
    should	be performed	before	so	doing.	Accordingly,	you	agree	that	our	Company	bears	no	liability	whatsoever	
    to	you	in	relation	to	any	subscription,	product	(including	coin)	purchase,	trade	or	swap	that	you	carry	out	at	
    your	sole	risk	and	discretion.	<p>

    <p><span>8.8</span>You	are	strongly	encouraged	to	conduct	your	own	due	diligence	checks	and	procure	your	own	professional	
    advice	before	subscribing,	trading,	purchasing,	using	our	MP1 API,	swapping	any	tokens,	or	entering	into	any	
    transactions	due	to	use	of	or	access	to	our	Site.	You	should	only	enter	into	any	transaction	after	due	and	careful	
    consideration,	understanding	the	risks,	and	having	considered	whether	you	are	able	to	bear	such	risks	of	losses.	
    It	is	 therefore	in	 your	 total	 and	 sole	 discretion	 as	 to	 whether	 to	 use	 any	 of	 our	 Site	 Services,	 swap,	 trade,	
    purchase	 and/or	 subscribe	 to	 any	DAO listed	 on	 our	 Site.	 You	 are	 at	 no	 time	 under	 any	 duress	 from	 our	
    Company,	Projects and/or	any	other	users	of	our	Site	to	trade,	swap,	purchase,	subscribe	and/or	enter	into	any	
    transactions.	Therefore,	you	agree	that	you	voluntarily	assume	all	risks	and	full	liability	in	all	your	transactions,	
    and	undertake	that	you	shall	not	howsoever	hold	the	Company	liable	or	responsible	whatsoever	in	the	event	
    of	 any	 damages	 or	 losses	 suffered,	 including	 but not	 limited	 to	 losses	 that	 you	 may	 suffer	 due	 to	
    misrepresentation,	negligent	information	or	fraudulent	acts	of	any	Projects.	<p>

    <p><span>8.9</span>In	the	event	that	our	Company’s	liability	to	you	in	relation	to	our	Site,	our	Site	Services,	our	Content,	our	
    Updates,	products	and/or	services	featured	on	our	Site	and/or	Updates,	cannot	be	fully	disclaimed,	you	agree	
    that	our	Company’s	total	liability	to	you	shall	not	exceed	$5,	which	you	agree	is	a	reasonable	compensation	
    amount	taking	into	consideration	the	limited	nature	of	our	Company’s	services.	Accordingly,	you	agree	that	
    upon	receipt	of	$5	from	the	Company,	you	will	waive	all	rights	against	the	Company	and	will	make	no	further	
    claims	whatsoever	against	the	Company.	<p>

    `,
  },
  {
    title: '9. Non-Interference',
    text: `
    <p>	You	undertake	that	you	shall	not	howsoever	mine	data	from,	scrape,	interfere	or	attempt	to	interfere	with	
    any	part	of	our	Site	or	Site	Services,	disrupt	accessibility	to	our	Site	(or	any	part	thereof),	or	bypass	any	security	
    measures	that	we	may	include	with	our	Site	and/or	Site	Services,	including	but	not	limited	to	the	utilization	of	
    any	 screen-scraper,	 hacks,	 spider,	 robot,	 virus,	 worms	 or	 other	 means	 to	 access	 or	 attack	 our	 Site	 or	 Site	
    Services	for	any	purpose	without	our	prior	written	consent.	<p>
    `,
  },
  {
    title: '10.	Indemnification	',
    text: `
    <p><span>10.1</span>You	 agree	 to	 fully	 indemnify,	 defend	 and	 hold	 our	 Company	 and	 its	 shareholders,	 directors,	 officers,	
    employees,	representatives,	agents,	subcontractors,	and	licensors	harmless	from	and	against	any	and	all	claims	
    (including	but	not	limited	to	third	party	claims	 for	intellectual	property	infringement	due	to	your	breach	of	
    Section	 4	 and/or	 Clause	 9.1	 herein),	 damages,	 costs	 and	 expenses,	including	 but	 not	limited	legal	 fees	 and	
    settlement	payments	on	a	full	indemnity	(solicitor-client)	basis	arising	from	or	related	to	your	breach	of	any	of	
    the	provisions	herein	this	Website	Terms	of	Use,	our	Privacy	Policy,	API	Terms	of	Service,	Listing	T&Cs,	any	
    other	service	provisions	of	the	Agreement,	your	use	of	our	Site,	our	Site	Services,	our	Content	and/or	Updates.	<p>

    <p><span>10.2</span>For	 the	avoidance	of	doubt,	 regardless	of	 the	indemnification	 received	by	 the	Company	 from	 you,	 the	
    Company	shall	have	full	authority	and	charge	over	its	own	defence,	legal	actions,	and	settlement	proceedings	
    in	relation	to	any	third	party	claims	without any	reference	to	you.	<p>
    `,
  },
  {
    title: '11.	Governing	Law	and	Jurisdiction',
    text: `
    <p><span>11.1</span>Our	Website	Terms	of	Use	and	accordingly	your	agreement	thereto	shall	be	governed	by	and	construed	
    in	accordance	with	the	laws	of	UAE	without	reference	to	any	conflict-of-law	principles,	including	cases	to	be	
    considered	under	Islamic	law.<p>

    <p><span>11.2</span>In	the	event	of	any	disputes	arising	from	this	Website	Terms	of	Use	or	any	of	the	other	provisions	of	the	
    Agreement,	your	use	of	our	Site,	our	Site	Services	and/or	Content,	you	must	first	contact	our	Company’s	officer	
    at	<a href="https://metarelations@metaplayerone.app" target="_blank">metarelations@metaplayerone.app</a> regarding	your	dispute,	and	use	your	best	endeavours	to	amicably	settle	
    any	dispute	in	good	faith.	We	on	our	part	will	also	use	our	best	endeavours	to	amicably	settle	your	concerns	in	
    good	faith.	However,	if	no	amicable	resolution	is	reached	within	30	days,	both	parties	agree	to	submit	to	the	
    exclusive	jurisdiction	of	the	Courts	of	 UAE.	<p>
    `,
  },
  {
    title: '12.	Miscellaneous',
    text: `
    <p><span>12.1</span>You	 agree	 to	 fully	 indemnify,	 defend	 and	 hold	 our	 Company	 and	 its	 shareholders,	 directors,	 officers,	
    employees,	representatives,	agents,	subcontractors,	and	licensors	harmless	from	and	against	any	and	all	claims	
    (including	but	not	limited	to	third	party	claims	 for	intellectual	property	infringement	due	to	your	breach	of	
    Section	 4	 and/or	 Clause	 9.1	 herein),	 damages,	 costs	 and	 expenses,	including	 but	 not	limited	legal	 fees	 and	
    settlement	payments	on	a	full	indemnity	(solicitor-client)	basis	arising	from	or	related	to	your	breach	of	any	of	
    the	provisions	herein	this	Website	Terms	of	Use,	our	Privacy	Policy,	API	Terms	of	Service,	Listing	T&Cs,	any	
    other	service	provisions	of	the	Agreement,	your	use of	our	Site,	our	Site	Services,	our	Content	and/or	Updates.	<p>

    <p><span>12.2</span>The	failure	or	delay	of	our	Company	at	any	time	to	enforce	any	of	its	rights	hereunder	our	Website	Terms	
    of	Use	and	other	terms	and	conditions	of	the	Agreement	shall	not	be	constituted	as	a waiver	thereof	and	shall	
    in	no	manner	affect	our	Company's	rights	at	a	later	time	to	enforce	the	same.	<p>

    <p><span>12.3</span>You	agree	that	you	shall	not	hold	our	Company	for	any	delay	or	failure	in	performance	(including	but	not	
      limited	to	non-accessibility	to	our	Site	and/or	Site	Services)	due	to	events	beyond	our	Company’s	reasonable	
      control,	including	but	not	limited	to	natural	catastrophes,	civil	riots,	acts	of	war,	shortage	of	utilities,	and	any	
      applicable	laws	and	regulations.	<p>
     
    <p>©	MP1 2022</p>  
    `,
  },
];
