import styled from 'styled-components';

import Icon from '../../../assets/icons/success.svg';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  padding-top: 12px;
`;

const Button = styled.button`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(94.47deg, #e657dc 5.47%, #8e40e4 93.52%);
  border-radius: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 1px;
  transition: 0.3s;
  margin-top: 24px;
  &:hover {
    transition: 0.3s;
    box-shadow: 0px 5px 19px rgb(198 138 248 / 25%);
    @media screen and (max-width: 1024px) {
      box-shadow: none;
    }
  }
`;

const Success = ({ onClick }) => {
  return (
    <Wrapper>
      <img src={Icon} alt="" />
      <Title>Success</Title>
      <Button onClick={onClick}>Continue</Button>
    </Wrapper>
  );
};
export default Success;
