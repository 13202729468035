import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Tabs } from 'modules/participate/components';
import { Search } from 'modules/participate/components/search';
import { TokenInfo } from 'modules/participate/components/token-info';

import { SimpleTable } from '../../../../components/simple-table';

import {
  MOCK_VALIDATORS_FILTERS,
  validationNodesTableContent,
  validationNodesTableHeaders,
  validationPoolsTableContent,
  validationPoolsTableHeaders,
} from './mock';

import styles from './styles.module.scss';

interface IValidatorsViewProps {
  setIsBecomeValidatorView: Dispatch<SetStateAction<boolean>>;
  currentTab: string;
}
export const ValidatorsView: FC<IValidatorsViewProps> = ({ setIsBecomeValidatorView, currentTab }) => {
  const [filter, setActiveFilter] = useState(MOCK_VALIDATORS_FILTERS[0]);
  const getCurrentTable = () => {
    switch (currentTab) {
      case 'validationPools':
        return {
          headers: validationPoolsTableHeaders,
          content: validationPoolsTableContent,
        };
      case 'validationNodes':
        return {
          headers: validationNodesTableHeaders,
          content: validationNodesTableContent,
        };
      default:
        return {};
    }
  };
  return (
    <div className={styles.content}>
      <TokenInfo handleBecomeValidator={() => setIsBecomeValidatorView(true)} />
      <div className={styles.dataView}>
        <div className={styles.header}>
          {currentTab === 'validationPools' && (
            <Tabs size="md" tabs={MOCK_VALIDATORS_FILTERS} activeTab={filter} setActiveTab={setActiveFilter} />
          )}
          <Search wrapperClassName={styles.search} />
        </div>
        <SimpleTable
          headers={getCurrentTable().headers}
          content={getCurrentTable().content(filter.value)}
          tdClassName={currentTab === 'validationNodes' && styles.td}
          thClassName={currentTab === 'validationNodes' && styles.th}
        />
      </div>
    </div>
  );
};
