import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 120px;
  .container {
    max-width: 1440px;
    padding: 0 60px;
    @media (max-width: 1024px) {
      padding: 0 36px;
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 32px;
  }
`;
export const Border = styled.div`
  width: 100%;
  height: 2px;
  background: linear-gradient(107.75deg, #af01ec 3.49%, #0066ff 97.12%);
  margin-bottom: 120px;
  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
`;
export const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  &.legalContainer {
    margin-top: 24px;
    width: fit-content;
    flex-direction: row;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 200px);
  grid-template-rows: repeat(1, 1fr);
  column-gap: 20px;
  margin-left: 70px;
  margin-right: 30px;
  @media (max-width: 1240px) {
    grid-template-columns: repeat(3, 120px);
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 36px 0 56px;
  }
`;
export const LinkGroupName = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #7e8390;
  margin-bottom: 16px;
`;
export const LinkGroupItem = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: white;
  display: block;
  a {
    color: ${(props) => (props.isActive ? '#7e8390' : '#fff')};
    transition: 0.3s;
    &:hover {
      color: #7e8390;
      transition: 0.3s;
    }
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  @media (max-width: 1024px) {
    white-space: nowrap;
  }
`;
export const Socials = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Icon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c2c8db;
  .socialIcon {
    width: 24px;
    height: 24px;
    transition: all 0.3 ease-in-out;
    &:hover {
      transition: all 0.3 ease-in-out;
      color: white;
    }
  }
  &:not(:first-child) {
    margin-left: 16px;
  }
`;
export const EmailFormContainer = styled.div`
  background: linear-gradient(99.78deg, #9f0bee 0.69%, #145bfd 98.32%);
  border-radius: 10px;
  padding: 40px;
  margin-left: auto;
  width: fit-content;
  max-width: 424px;
`;
export const FormTitle = styled.p`
  font-weight: 500;
  font-size: 31px;
  line-height: 40px;
`;
export const FormSubTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-top: 8px;
`;
export const FormInputContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  input {
    outline: none;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    background: #ffffff;
    border-radius: 6px;
    margin-right: 10px;
    padding: 12px;
    width: 100%;
  }
`;
export const FormArrowBox = styled.div`
  min-width: 40px;
  min-height: 40px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;

  &.disabled {
    opacity: 50%;
    cursor: not-allowed;
  }
`;
export const LegalText = styled.p`
  font-size: 12px;
  line-height: 12px;
  color: #7e8390;
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const MobileInfoContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .filled-button {
    padding: 8px;
    border-radius: 4px;
    div {
      padding: 8px;
      border-radius: 4px;
      height: 100%;
      width: 100%;
      background: transparent;
      padding: 0;
      font-size: 12px;
      line-height: 16px;
      text-transform: unset;
    }
  }
`;
