import { useState } from 'react';
import Slider from 'react-slick';

import ArrowL from '../../../../../../assets/icons/slide-left.svg';
import ArrowR from '../../../../../../assets/icons/slide-right.svg';
import Preloader from '../../../../../../components/loader';
import { shards } from '../../data';

import { Button, ButtonLeft, Slide, SliderWrap, Wrapper } from './styles';

import '../../../../../../assets/css/slick.scss';

const ArrowNext = ({ className, style, onClick }) => (
  <Button style={{ ...style }} onClick={onClick} className={className}>
    <img src={ArrowR} alt="" />
  </Button>
);
const ArrowPrev = ({ className, style, onClick }) => (
  <ButtonLeft style={{ ...style }} onClick={onClick} className={className}>
    <img src={ArrowL} alt="" />
  </ButtonLeft>
);

const ShardSlider = () => {
  const [isLoad, setLoad] = useState(true);
  const settings = {
    slidesToShow: 5,
    dots: false,
    arrows: true,
    infinite: true,
    lazyLoad: true,
    swipe: false,
    speed: 300,
    centerMode: true,
    focusOnSelect: true,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          speed: 400,
          slidesToShow: 1,
          swipe: true,
        },
      },
    ],
  };
  return (
    <Wrapper>
      <SliderWrap>
        <Slider {...settings}>
          {shards?.map((item, index) => (
            <Slide className="slide-ref" key={`${index + 1}`}>
              <div className="slide-video">
                <video
                  disableRemotePlayback
                  type="video/mp4"
                  muted
                  autoPlay
                  loop
                  playsInline
                  onLoadedData={() => setLoad(false)}
                >
                  <source src={item.video} type="video/mp4" />
                </video>
                {isLoad && <Preloader />}
              </div>
              <img className="slide-img" src={item.image} alt="" />
            </Slide>
          ))}
        </Slider>
      </SliderWrap>
    </Wrapper>
  );
};
export default ShardSlider;
