export const discord = {
  default: 'https://discord.gg/BywcEarrhR',
  whitelist: 'https://discord.gg/SVsyXBcUbP',
};

export const social = {
  instagram: 'https://www.instagram.com/metaplayerone.app/',
  twitter: 'https://twitter.com/DAOMetaplayer',
  youtube: 'https://www.youtube.com/watch?v=7LmNByVy2_A',
  tiktok: 'https://www.tiktok.com/@metaplayerone.app',
};
