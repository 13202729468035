/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import { useEffect } from 'react';
import axios from 'axios';

import { appId, chain, chainId, crystals, serverUrl } from '../../../../const/const';
import Moralis from '../../../../const/moralis';
import useAuth from '../../../../helpers/auth';
import CardAssets from '../../card';
import EmptyCard from '../../empty-card';
import LoadingCard from '../../loading-card';

const CrystalOfPower = ({ onClick, crystalsPower, setCrystalsPower, loading, setLoading }) => {
  const { user } = useAuth();

  useEffect(() => {
    const load = async () => {
      try {
        await Moralis.start({ serverUrl, appId });
        let results = [];
        await Promise.all(
          crystals.map(async (token_address) => {
            const temp = (
              await Moralis.Cloud.run('getWalletNFTs', {
                chainId,
                address: user?.get('ethAddress'),
                tokenAddresses: [token_address],
              })
            ).result;
            results = [...results, ...temp];
          }),
        );
        for (const token of results) {
          if (token.metadata) {
            token.metadata = JSON.parse(token.metadata);
          } else if (token.token_uri) {
            try {
              await Moralis.Web3API.token.reSyncMetadata({
                chain,
                flag: 'uri',
                mode: 'sync',
                token_address: token.token_address,
                token_id: token.token_id,
              });
              const { data } = await axios.get(token.token_uri);
              token.metadata = data;
            } catch (err) {
              console.error(err);
            }
          }
        }
        setCrystalsPower(results);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    if (loading) load();
  }, [loading, setCrystalsPower, setLoading, user]);
  if (loading) return <LoadingCard />;
  if (crystalsPower?.length > 0)
    return crystalsPower?.map((item) => (
      <CardAssets
        key={item?.metadata?.animation_url}
        item={item?.metadata?.animation_url}
        onClick={() => onClick(item)}
      />
    ));

  return <EmptyCard />;
};
export default CrystalOfPower;
