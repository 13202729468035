import ArrowL from '../../../assets/icons/slide-left.svg';
import ArrowR from '../../../assets/icons/slide-right.svg';
import { roadmap } from '../../../modules/main/sections/roadmap/data';
import IconClose from '../../icons/IconClose';

import {
  Button,
  ButtonLeft,
  CloseBtn,
  Content,
  Crystal,
  Li,
  Line,
  List,
  Period,
  SubTitle,
  Title,
  Ul,
  Wrapper,
} from './styles';

const MintingShardModal = (props) => {
  const { isOpenModal, closeModal, current, setCurrent } = props;
  const item = roadmap[current];
  // eslint-disable-next-line no-unsafe-optional-chaining
  const length = roadmap?.length - 1;
  const handleNext = () => {
    if (current < length) {
      setCurrent(current + 1);
    } else {
      setCurrent(0);
    }
  };
  const handlePrev = () => {
    if (current !== 0) {
      setCurrent(current - 1);
    } else {
      setCurrent(length);
    }
  };

  if (isOpenModal) {
    document.body.style.overflowY = 'hidden';
  } else {
    document.body.style.overflowY = 'auto';
  }

  return (
    <Wrapper isOpenModal={isOpenModal}>
      <Content>
        <CloseBtn onClick={closeModal}>
          <IconClose />
        </CloseBtn>

        <Line background={item?.color_line} />
        <Crystal border={item?.border}>
          <img src={item?.crystal} alt="" />
        </Crystal>

        <Title>{item?.title}</Title>
        <SubTitle>{item?.subtitle}</SubTitle>
        <Period>{item?.period}</Period>
        <List>
          <Ul>
            {item?.full_options.map((el, idx) => (
              <Li key={`${idx + 1}`}>{el.option}</Li>
            ))}
          </Ul>
        </List>
        <Button onClick={handleNext}>
          <img src={ArrowR} alt="" />
        </Button>
        <ButtonLeft onClick={handlePrev}>
          <img src={ArrowL} alt="" />
        </ButtonLeft>
      </Content>
    </Wrapper>
  );
};
export default MintingShardModal;
