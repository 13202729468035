import getReducedNumber from './getReducedNumber';

const getReplaceNumber = (value, step = 3) => {
  if (value >= 1000) {
    return getReducedNumber(value);
  }
  return (
    Number(value)
      .toFixed(step)
      .replace(/\.?0+$/, '') || 0
  );
};
export default getReplaceNumber;
