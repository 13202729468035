import { useState } from 'react';
import { clsx } from 'helpers/clsx';
import { PreviewBanner } from 'modules/participate/components/preview-banner';

import { BecomeValidator } from '../validators/components';

import { AcceleratorView } from './accelerator-view';

import styles from './styles.module.scss';

export const Acceleration = ({ className }: any) => {
  const [isBecomeValidatorView, setIsBecomeValidatorView] = useState(false);
  return (
    <div className={clsx(className)}>
      <BecomeValidator
        className={clsx(styles.becomeValidator, isBecomeValidatorView && styles.visible)}
        setIsBecomeValidatorView={setIsBecomeValidatorView}
        isBecomeAccelerator
      />
      <div className={clsx(styles.pageContent, !isBecomeValidatorView && styles.visible)}>
        <PreviewBanner className={styles.banner} />
        <AcceleratorView setIsBecomeValidatorView={setIsBecomeValidatorView} />
      </div>
    </div>
  );
};
