import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FlexSB = ({ children }) => <Wrapper>{children}</Wrapper>;

export default FlexSB;
