export const MOCK_STEPS = [
  {
    title: 'Requirements',
    value: 'requirements',
  },
  {
    title: 'Node setup',
    value: 'nodeSetup',
  },
  {
    title: 'Validator information',
    value: 'validatorInformation',
  },
  {
    title: 'Bridge deposit',
    value: 'bridgeDeposit',
  },
  {
    title: 'Initial stake',
    value: 'initialStake',
  },
  {
    title: 'Confirmation',
    value: 'confirmation',
  },
];
