export const getError = ({ message }) => {
  let e;

  try {
    e = JSON.stringify(message);
  } catch (el) {
    e = message.toString();
  }

  switch (true) {
    case e.includes('execution reverted: Limit exceeded. Max:'):
      return 'Drop limit overrized';
    case e.includes('execution reverted: Not enough funds'):
      return 'Not enough funds send';
    case e.includes('execution reverted: Wallet limit exceeded. Max: 10.'):
      return 'You can mint only 10 shards per whitelist account';
    case e.includes('execution reverted: Wallet limit exceeded. Max: 3.'):
      return 'You can mint only 3 shards per account';
    case e.includes("execution reverted: Can't burn token. Token does not exists!"):
      return 'You cant burn it! Token does not exist';
    case e.includes("execution reverted: Can't find token_uri. Token does not exists!"):
      return 'You cant get token uri! Token does not exist';
    case "Drop hasn't start":
      return "Drop hasn't start";
    case e.includes('execution reverted: Permission denied'):
      return 'Permission denied';
    case e.includes('User denied transaction signature'):
      return 'You rejected the transaction';
    case e.includes('rejected the transaction'):
      return 'You rejected the transaction';
    case e.includes('insufficient funds'):
      return 'Insufficient funds';
    case e.includes('Invalid parameters: must provide an Ethereum address.'):
      return 'Unable to recognize user, please re-log in';
    case e.includes('Event not started yet'):
      return 'Event not started yet';
    case e.includes('Not enough funds sent'):
      return 'Not enough funds send';
    case e.includes('You are not in whitelist'):
      return 'You are not in whitelist';
    default:
      return 'Something went wrong';
  }
};
