/* eslint-disable react/button-has-type */
import { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { clsx } from 'helpers/clsx';

import styles from './styles.module.scss';

interface ICustomButtonProps {
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  className?: string;
  variant: 'filled' | 'contained' | 'text';
  onClick: () => void;
  children: ReactNode | string;
  containedButtonClassName?: string;
}

const CustomButton: FC<ICustomButtonProps> = ({
  type = 'button',
  className,
  variant = 'filled',
  onClick,
  children,
  containedButtonClassName,
}) => {
  if (variant === 'contained')
    return (
      <div className={clsx(styles.wrapper, styles[variant], className)}>
        <button
          className={clsx(styles[variant], styles.button, containedButtonClassName)}
          type={type}
          onClick={onClick}
        >
          {children}
        </button>
      </div>
    );
  return (
    <button className={clsx(className, styles[variant], styles.button)} type={type} onClick={onClick}>
      {children}
    </button>
  );
};

export default CustomButton;
