import { useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { TabletOn } from '../helpers/responsive';

import Menu from './header/menu';
import Footer from './footer';
import Header from './header';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: ${(props) => (props.hide ? 'hidden' : 'initial')};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1024px) {
    overflow: hidden;
  }
`;
export const Content = styled.div`
  width: 100%;
  height: 100%;
`;
const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const [isOpenMenu, setOpenMenu] = useState(false);
  return (
    <Wrapper hide={pathname === '/'}>
      <Header setOpenMenu={setOpenMenu} />
      <Content>
        <TabletOn>
          <Menu isOpenMenu={isOpenMenu} setOpenMenu={setOpenMenu} />
        </TabletOn>
        {children}
      </Content>
      <Footer />
    </Wrapper>
  );
};
export default Layout;
