import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
export const Tab = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  white-space: nowrap;
  background: ${(props) => (props.isActive ? 'rgba(255, 255, 255, 0.1)' : 'transparent')}!important;
  border-radius: 40px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => (props.isActive ? '#fff' : '#7E8390')}!important;
  text-transform: uppercase;
  padding: 0 20px;
  min-width: 200px;
  margin: 0 16px;
  transition: 0.3s;
  &:hover {
    color: #fff !important;
    background: rgba(255, 255, 255, 0.1) !important;
  }
`;
export const SliderWrap = styled.div`
  width: 100%;
  height: 100%;
  .slick-slider {
    height: 100%;
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
        display: flex;
        justify-content: center;
        .slick-center {
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    border-radius: 16px;
  }
`;
export const List = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
