import { useFormContext } from 'react-hook-form';
import lget from 'lodash/get';

import { renderInputError } from '../../../helpers/renderInputError';

import { Error, Input, Wrapper } from './styles';

const HookTextarea = ({ name, placeholder, rules }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const hasError = lget(errors, name);
  return (
    <Wrapper>
      <Input
        error={hasError}
        placeholder={placeholder}
        {...register(name, {
          ...rules,
        })}
        onWheel={(e) => e.target.blur()}
      />
      {hasError && <Error>{renderInputError(hasError, rules)}</Error>}
    </Wrapper>
  );
};
export default HookTextarea;
