export const renderInputError = (hasError, rules) => {
  switch (hasError?.type) {
    case 'required':
      return 'This field is required!';
    case 'maxLength':
      return `Length exceeded, max ${rules[hasError?.type]} symbol`;
    case 'minLength':
      return `min length ${rules[hasError?.type]} symbol`;
    case 'max':
      return `Value exceeded, max ${rules[hasError?.type]}`;
    case 'min':
      return `Min value ${rules[hasError?.type]}`;
    case 'validate':
      return hasError?.message;

    default:
      return 'The field is filled incorrectly';
  }
};
