import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 60px;
  @media screen and (max-width: 767px) {
    padding: 10px 10px 32px 10px;
  }
`;
export const Conatainer = styled.div`
  width: 600px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  background: #1b1b1c;
  border-radius: 24px;
  padding: 56px;
  @media screen and (max-width: 767px) {
    padding: 20px;
    border-radius: 8px;
  }
`;
export const Form = styled.form`
  width: 100%;
  .input_wrap {
    min-height: 78px;
    & > input {
      height: 54px;
    }
  }
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 16px 0;
`;
export const Close = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: absolute;
  background: transparent;
  border-radius: 50%;
  top: 12px;
  right: 16px;
  stroke: #000;
  & svg {
    stroke: #fff;
    fill: #fff;
  }
  @media screen and (max-width: 767px) {
    position: absolute;
    top: 14px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  padding-bottom: 24px;
  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
  }
`;
export const MiddleText = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
export const SmallText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7e8390;
`;

export const ButtonWrap = styled.div`
  padding-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & button {
    min-width: 180px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;

    & button {
      width: 100%;
    }
  }
`;

export const WrapCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  & img {
    width: 30px;
    margin-left: 6px;
  }
  .flex_center {
    padding: 24px 0 16px 0;
  }
`;
