import { useState } from 'react';
import { clsx } from 'helpers/clsx';
import { Tabs } from 'modules/participate/components';

import styles from './styles.module.scss';

const tabs = [
  {
    title: 'ON-GOING',
    value: 'onGoing',
  },
  {
    title: 'CLOSED',
    value: 'closed',
  },
];

export const Proposal = ({ className }: any) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  return (
    <div className={clsx(styles.root, className)}>
      <Tabs tabs={tabs} isUnderlined activeTab={activeTab} setActiveTab={setActiveTab} className={styles.tabs} />
    </div>
  );
};
