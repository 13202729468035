import { Column, SubTitle, Title, Token, TokenWrapper } from '../style';

const TokenCard = ({ item }) => {
  const getShadow = () => {
    switch (item.token_name) {
      case 'TOKEN AIRLOVE':
        return 'box-shadow: 6px 0px 24px rgba(175, 1, 236, 0.2);';
      case 'TOKEN LEXOR':
        return 'box-shadow: 6px 0px 24px rgba(44, 202, 60, 0.2);';
      case 'TOKEN PLAYER':
        return 'box-shadow: 6px 0px 24px rgba(105, 76, 160, 0.2);';
      case 'TOKEN GOOVY':
        return 'box-shadow: 6px 0px 24px rgba(236, 128, 1, 0.2);';
      case 'TOKEN VIOS':
        return 'box-shadow: 6px 0px 24px rgba(251, 226, 3, 0.2);';
      default:
        return '';
    }
  };
  return (
    <TokenWrapper shadow={getShadow()}>
      <Token>
        <img src={item?.token} alt="" />
      </Token>
      <Column>
        <Title>{item?.token_name}</Title>
        <SubTitle>{item?.token_description}</SubTitle>
      </Column>
    </TokenWrapper>
  );
};
export default TokenCard;
