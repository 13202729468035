import { Dispatch, FC, SetStateAction } from 'react';
import { RadioButton, RadioItem } from 'components/ui/radio';
import { clsx } from 'helpers/clsx';

import { TextBlock } from '..';

import { MOCK_HARDWARE_REQUIREMENTS, MOCK_SETUP_INSTRUCTIONS } from './mock';

import styles from './styles.module.scss';

interface INodeSetupProps {
  className?: string;
  setCurrentValidatorType: Dispatch<SetStateAction<RadioItem>>;
  currentValidatorType: RadioItem;
}

export const NodeSetup: FC<INodeSetupProps> = ({ className, currentValidatorType, setCurrentValidatorType }) => {
  return (
    <div className={clsx(className, styles.root)}>
      <TextBlock
        title="Node setup"
        value="Make sure that vou follow the instructions in the documentation to initialize a validator node and get BLS public key, Node ID, Public Address (MEU not required)"
        className={styles.textBlock}
      />
      <TextBlock
        titleSize="sm"
        title="Hardware requirements for running a full Ronin node:"
        value={
          <div className={styles.hardwareRequirementsContainer}>
            {MOCK_HARDWARE_REQUIREMENTS.map(({ title, value }) => (
              <div className={styles.itemContainer} key={title}>
                <p>{title}</p>
                <p className={styles.highlightedText}>{value}</p>
              </div>
            ))}
          </div>
        }
        className={styles.textBlock}
      />
      <TextBlock
        titleSize="sm"
        title="Setup instructions:"
        value={
          <div className={styles.hardwareRequirementsContainer}>
            <RadioButton
              values={MOCK_SETUP_INSTRUCTIONS}
              activeValueKey={currentValidatorType.value}
              onChange={setCurrentValidatorType}
            />
          </div>
        }
        className={styles.textBlock}
      />
    </div>
  );
};
