import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
`;

export const Container = styled.div`
  display: block;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  padding: 32px 100px;
  @media screen and (max-width: 1280px) {
    padding: 32px 60px;
  }
  @media screen and (max-width: 1024px) {
    padding: 32px;
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #ffffff;
  padding-bottom: 24px;
  @media screen and (max-width: 1024px) {
    font-size: 32px;
    line-height: 40px;
  }
  @media screen and (max-width: 767px) {
    font-size: 28px;
    line-height: 32px;
  }
`;
export const SubTitle = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  padding-top: 16px;
`;
export const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-align: justify;
  & span {
    font-weight: 700;
    margin-right: 8px;
  }
  & a {
    color: #00acfe;
    &:hover {
      text-decoration: underline;
    }
  }
`;
export const Block = styled.div`
  display: block;
`;
