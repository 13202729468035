import { FC, useState } from 'react';
import { clsx } from 'helpers/clsx';
import { IconLogoSm } from 'modules/participate/assets';
import { BaseInput } from 'modules/participate/components';

import { TextBlock } from '..';

import styles from './styles.module.scss';

interface IInitialStakeProps {
  className?: string;
}

export const InitialStake: FC<IInitialStakeProps> = ({ className }) => {
  const [initialStake, setInitialStake] = useState('');
  return (
    <div className={clsx(className)}>
      <TextBlock
        title="Node setup"
        value={
          <div className={styles.contentText}>
            <p>The minimum validation pool stake amount: 50.000 MEU</p>
            <p>The minimum validation node stake amount: 1000 MEU</p>
          </div>
        }
        className={styles.textBlock}
      />
      <BaseInput
        value={initialStake}
        setValue={setInitialStake}
        placeholder="Amount"
        endAdornment={
          <div className={styles.endAdornment}>
            <p className={styles.availableBalance}>Available: 0.00 MEU</p>
            <div className={styles.maxContainer}>
              <button type="button" className={styles.highlightedText} onClick={() => setInitialStake('0')}>
                MAX
              </button>
              <p className={styles.amount}>50,000.00</p>
              <div className={styles.tokenContainer}>
                <IconLogoSm />
                <p className={styles.tokenName}>MetaUnit</p>
              </div>
            </div>
          </div>
        }
        wrapperClassName={styles.inputWrapperClassName}
      />
    </div>
  );
};
