import { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { Search } from 'modules/participate/components/search';
import { SimpleTable } from 'modules/participate/components/simple-table';
import { TokenInfo } from 'modules/participate/components/token-info';

import styles from './styles.module.scss';

interface IValidatorsViewProps {
  setIsBecomeValidatorView: Dispatch<SetStateAction<boolean>>;
}
type ValidatorsNodesInfoModel = {
  id: number;
  nodes: ReactNode;
  totalEarns: string;
  days: string;
  gpuCuda: string;
  uptime: string;
  status: ReactNode | string;
};

const accelerationTableContent: ValidatorsNodesInfoModel[] = [
  {
    id: 1,
    nodes: (
      <div className={styles.validatorsCell}>
        <div className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    totalEarns: '1000 MEU',
    days: '22',
    gpuCuda: '80 / 6000',
    uptime: '100.00 %',
    status: <span className={styles.highlightedText}>Acceleration</span>,
  },
];
const accelerationTableHeaders = ['Nodes', 'TOTAL EARNED', 'DAYS', 'GPU /CUDA', 'UPTIME', 'STATUS'];
export const AcceleratorView: FC<IValidatorsViewProps> = ({ setIsBecomeValidatorView }) => {
  return (
    <div className={styles.content}>
      <TokenInfo handleBecomeValidator={() => setIsBecomeValidatorView(true)} isApplyToAcceleration />
      <div className={styles.dataView}>
        <div className={styles.header}>
          <p className={styles.text}>Acceleration program Nodes</p>
          <Search wrapperClassName={styles.search} />
        </div>
        <SimpleTable headers={accelerationTableHeaders} content={accelerationTableContent} />
      </div>
    </div>
  );
};
