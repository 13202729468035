import { FC } from 'react';
import { clsx } from 'helpers/clsx';
import { PreviewBannerLg } from 'modules/participate/assets/images';

import styles from './styles.module.scss';

interface IPreviewBannerProps {
  className?: string;
}

export const PreviewBanner: FC<IPreviewBannerProps> = ({ className }) => {
  return (
    <div className={clsx(styles.root, className)}>
      <img src={PreviewBannerLg} alt="banner" className={styles.bannerImg} />
      <p className={styles.textBlock}>
        Choose your validators and help them win by delegating MEU to them!
        <br />
        You&apos;ll earn rewards when your validators are selected.
      </p>
    </div>
  );
};
