import { ReactNode } from 'react';
import { clsx } from 'helpers/clsx';
import { EllipseIcon } from 'modules/participate/assets';
import CustomButton from 'modules/participate/components/custom-button';

import styles from './styles.module.scss';

export const validationPoolsTableHeaders = ['VALIDATORS', 'TOTAL STAKE', 'COMM', 'APR', 'UPTIME', 'STATUS', ''];
export const validationNodesTableHeaders = ['Validation Nodes', 'TOTAL STAKE', 'Pool name', 'UPTIME', 'STATUS'];

export const MOCK_VALIDATORS_FILTERS = [
  {
    title: 'Pools',
    value: 'pools',
  },
  {
    title: 'Candidates',
    value: 'candidates',
  },
];
type ValidatorsPoolsInfoModel = {
  id: number;
  validators: ReactNode;
  totalStake: string;
  commission: string;
  apr: string;
  uptime: string;
  status: ReactNode | string;
  action: ReactNode | undefined;
};
type ValidatorsNodesInfoModel = {
  id: number;
  validationNodes: ReactNode;
  totalStake: string;
  poolName: string;
  uptime: string;
  status: ReactNode | string;
};
export const validationPoolsTableContent = (currentView: string): ValidatorsPoolsInfoModel[] => [
  {
    id: 1,
    validators: (
      <div className={styles.validatorsCell}>
        <EllipseIcon className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    totalStake: '7,718,877.... MEU',
    commission: '10%',
    apr: '15.61 / 15.93 %',
    uptime: '100.00 %',
    status: <p className={styles.highlightedText}>Active</p>,
    ...(currentView !== 'candidates' && {
      action: (
        <div className={clsx(styles.actionContainer, currentView === 'candidates' && styles.hidden)}>
          <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
            Delegate
          </CustomButton>
          <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
            Join
          </CustomButton>
        </div>
      ),
    }),
  },
  {
    id: 2,
    validators: (
      <div className={styles.validatorsCell}>
        <EllipseIcon className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    totalStake: '7,718,877.... MEU',
    commission: '10%',
    apr: '15.61 / 15.93 %',
    uptime: '100.00 %',
    status: <p className={styles.highlightedText}>Active</p>,
    ...(currentView !== 'candidates' && {
      action: (
        <div className={clsx(styles.actionContainer, currentView === 'candidates' && styles.hidden)}>
          <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
            Delegate
          </CustomButton>
          <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
            Join
          </CustomButton>
        </div>
      ),
    }),
  },
  {
    id: 3,
    validators: (
      <div className={styles.validatorsCell}>
        <EllipseIcon className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    totalStake: '7,718,877.... MEU',
    commission: '10%',
    apr: '15.61 / 15.93 %',
    uptime: '100.00 %',
    status: <p className={styles.highlightedText}>Active</p>,
    ...(currentView !== 'candidates' && {
      action: (
        <div className={clsx(styles.actionContainer, currentView === 'candidates' && styles.hidden)}>
          <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
            Delegate
          </CustomButton>
          <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
            Join
          </CustomButton>
        </div>
      ),
    }),
  },
  {
    id: 4,
    validators: (
      <div className={styles.validatorsCell}>
        <EllipseIcon className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    totalStake: '7,718,877.... MEU',
    commission: '10%',
    apr: '15.61 / 15.93 %',
    uptime: '100.00 %',
    status: <p className={styles.highlightedText}>Active</p>,
    ...(currentView !== 'candidates' && {
      action: (
        <div className={clsx(styles.actionContainer, currentView === 'candidates' && styles.hidden)}>
          <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
            Delegate
          </CustomButton>
          <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
            Join
          </CustomButton>
        </div>
      ),
    }),
  },
  {
    id: 5,
    validators: (
      <div className={styles.validatorsCell}>
        <EllipseIcon className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    totalStake: '7,718,877.... MEU',
    commission: '10%',
    apr: '15.61 / 15.93 %',
    uptime: '100.00 %',
    status: <p className={styles.highlightedText}>Active</p>,
    ...(currentView !== 'candidates' && {
      action: (
        <div className={clsx(styles.actionContainer, currentView === 'candidates' && styles.hidden)}>
          <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
            Delegate
          </CustomButton>
          <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
            Join
          </CustomButton>
        </div>
      ),
    }),
  },
  {
    id: 6,
    validators: (
      <div className={styles.validatorsCell}>
        <EllipseIcon className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    totalStake: '7,718,877.... MEU',
    commission: '10%',
    apr: '15.61 / 15.93 %',
    uptime: '100.00 %',
    status: <p className={styles.highlightedText}>Active</p>,
    ...(currentView !== 'candidates' && {
      action: (
        <div className={clsx(styles.actionContainer, currentView === 'candidates' && styles.hidden)}>
          <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
            Delegate
          </CustomButton>
          <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
            Join
          </CustomButton>
        </div>
      ),
    }),
  },
  {
    id: 7,
    validators: (
      <div className={styles.validatorsCell}>
        <EllipseIcon className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    totalStake: '7,718,877.... MEU',
    commission: '10%',
    apr: '15.61 / 15.93 %',
    uptime: '100.00 %',
    status: <p className={styles.highlightedText}>Active</p>,
    ...(currentView !== 'candidates' && {
      action: (
        <div className={clsx(styles.actionContainer, currentView === 'candidates' && styles.hidden)}>
          <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
            Delegate
          </CustomButton>
          <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
            Join
          </CustomButton>
        </div>
      ),
    }),
  },
];

export const validationNodesTableContent = (): ValidatorsNodesInfoModel[] => [
  {
    id: 1,
    validationNodes: (
      <div className={styles.validatorsCell}>
        <EllipseIcon className={styles.validatorIcon} />
        <p>AzSSSSS</p>
      </div>
    ),
    totalStake: '1000 MEU',
    poolName: 'Sunrise',
    uptime: '100.00 %',
    status: <p className={styles.highlightedText}>Active</p>,
  },
  {
    id: 2,
    validationNodes: (
      <div className={styles.validatorsCell}>
        <EllipseIcon className={styles.validatorIcon} />
        <p>AzSSSSS</p>
      </div>
    ),
    totalStake: '1000 MEU',
    poolName: 'Sunrise',
    uptime: '100.00 %',
    status: <p className={styles.highlightedText}>Active</p>,
  },
  {
    id: 3,
    validationNodes: (
      <div className={styles.validatorsCell}>
        <EllipseIcon className={styles.validatorIcon} />
        <p>AzSSSSS</p>
      </div>
    ),
    totalStake: '1000 MEU',
    poolName: 'Sunrise',
    uptime: '100.00 %',
    status: <p className={styles.highlightedText}>Active</p>,
  },
  {
    id: 4,
    validationNodes: (
      <div className={styles.validatorsCell}>
        <EllipseIcon className={styles.validatorIcon} />
        <p>AzSSSSS</p>
      </div>
    ),
    totalStake: '1000 MEU',
    poolName: 'Sunrise',
    uptime: '100.00 %',
    status: <p className={styles.highlightedText}>Active</p>,
  },
  {
    id: 5,
    validationNodes: (
      <div className={styles.validatorsCell}>
        <EllipseIcon className={styles.validatorIcon} />
        <p>AzSSSSS</p>
      </div>
    ),
    totalStake: '1000 MEU',
    poolName: 'Sunrise',
    uptime: '100.00 %',
    status: <p className={styles.highlightedText}>Active</p>,
  },
];
