import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(50px);
  z-index: 1000;
`;
export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
  @media screen and (max-width: 1280px) {
    padding: 0 60px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 32px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
`;
export const Logo = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  @media screen and (max-width: 1024px) {
    & img {
      height: 24px;
    }
  }
`;
export const Navigation = styled.button`
  white-space: nowrap;
  height: 48px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => (props.isActive ? '#7e8390' : '#fff')};

  &:hover {
    color: #7e8390;
    transition: 0.3s;
  }
`;
export const MenuBtn = styled.button`
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.09);
  border-radius: 4px;
  padding: 6px;
`;
export const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  @media screen and (max-width: 1024px) {
    min-width: unset;
  }
`;
export const ConnectButton = styled.div`
  min-width: 185px;
`;
export const ProfileButton = styled.div`
  width: fit-content;
`;
export const Line = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  background: #ffffff;
  &:before {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    background: #ffffff;
    top: -6px;
    left: 0;
  }
  &:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    background: #ffffff;
    top: 6px;
    left: 0;
  }
`;
