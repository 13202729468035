import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 450px;
  position: relative;
  padding: 30px 0 100px 0;
  @media screen and (max-width: 1440px) {
    /* height: 410px; */
    position: relative;
    padding: 20px 0 100px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 0px 0 100px 0;
  }
`;

export const Button = styled.button`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  width: 40px;
  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50px);
  border-radius: 10px;
  z-index: 1;
  transition: 0.2s;
  &:hover {
    transition: 0.2s;
    background: rgba(255, 255, 255, 0.3);
    @media screen and (max-width: 1024px) {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`;
export const ButtonLeft = styled(Button)`
  left: 20px;
  right: auto;
`;
export const SliderWrap = styled.div`
  width: 100%;
  height: 100%;
  .slick-slider {
    height: 100%;
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
        display: flex;
        .slick-center {
          & div {
            .slide-video {
              display: block !important;
              transition: 0.3s;

              & video {
                animation: opacity 0.7s ease-in-out;
              }
              @keyframes opacity {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
              @media screen and (max-width: 767px) {
                width: 100%;
                align-items: center;
              }
            }
            .slide-img {
              display: none !important;
              transition: 0.3s;
            }
          }
        }
        .slick-active {
          & div {
            .roadmap-circle {
              background-color: #4ea04c;
              transition: 0.3s;
            }
          }
        }
        .slick-slide div {
          height: 100%;
          .slide-video {
            display: none;
            transition: 0.3s;
          }
          .slide-img {
            display: block;
            transition: 0.3s;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    border-radius: 16px;
  }
`;
export const Slide = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 290px !important;
  & > div {
    position: relative;
    .preloader-base {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translate(-50%, 0);
      & > img {
        width: 44px;
      }
      & > div {
        height: auto !important;
        font-size: 8px;
      }
    }
    & video {
      width: 100%;
      height: 240px;
      @media screen and (max-width: 1440px) {
        height: 260px;
      }
    }
    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }
  & img {
    width: 160px;
  }
`;
