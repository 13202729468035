import styled from 'styled-components';

import ImageLines from '../../../../../../../assets/images/token-lines.png';
import ImageLinesTablet from '../../../../../../../assets/images/token-lines-tablet.png';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px 0 40px 0;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 54px 0 0 0;
    .primary-button {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    & > .lazy {
      width: 98vw;
    }
  }
`;
export const CrystalWrapper = styled.div`
  position: relative;
  width: 370px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #1b1b1c;
  border-radius: 16px;
  padding: 16px;
  @media screen and (max-width: 1200px) {
    width: 280px;
    height: 202px;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
  @media screen and (max-width: 1024px) {
    width: calc(50% - 16px);
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    & + & {
      margin-top: 16px;
    }
    height: fit-content;
  }
`;
export const TokenWrapper = styled.div`
  width: 350px;
  height: 130px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  ${(prop) => prop.shadow}
  backdrop-filter: blur(12px);
  border-radius: 16px;
  padding: 24px;
  margin-left: 32px;
  @media screen and (max-width: 1200px) {
    width: 280px;
    height: 202px;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
  @media screen and (max-width: 1024px) {
    margin-left: 0;
    width: calc(50% - 16px);
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    & + & {
      margin-top: 16px;
    }
    height: fit-content;
  }
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: url(${ImageLines}) no-repeat;
  background-size: auto 85%;
  background-position: right center;
  padding-left: 32px;
  @media screen and (max-width: 1280px) {
    padding-left: 0;
  }
  @media screen and (max-width: 1200px) {
    background: url(${ImageLinesTablet}) no-repeat;
    background-size: auto 880px;
    background-position: right 105px;
    padding-right: 20px;
  }
  @media screen and (max-width: 1024px) {
    background: transparent;
  }
`;
export const Row = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  padding-right: 86px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: 1200px) {
    height: 202px;
    padding-right: 0;
  }
  @media screen and (max-width: 1024px) {
    justify-content: space-between;
  }
`;

export const Crystal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  width: 95px;
  aspect-ratio: 1;
  & > .lazy {
    height: 123px !important;
    width: 123px !important;
    display: block;
    object-fit: cover;
    aspect-ratio: 1;
    img {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 1200px) {
    width: 72px;
    min-width: 72px;
    height: 72px;
    margin: 0;
    flex-wrap: nowrap;
  }
`;

export const Token = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  width: 56px;
  min-width: 56px;
  height: 56px;
  aspect-ratio: 1;
  & > img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
  @media screen and (max-width: 1200px) {
    margin: 0;
    margin-right: 24px;
    min-width: 54px;
    width: 54px;
    height: 54px;
  }
  @media screen and (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 16px;
  }
`;

export const Title = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 4px;
`;

export const SubTitle = styled.div`
  white-space: pre-line;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #ffffff;
`;
export const Column = styled.div`
  display: block;
  ${(prop) => prop.className === 'crystal' && 'align-self: flex-start;'}
`;
export const Shards = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > .lazy {
    height: 20px !important;
    width: 20px !important;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;
export const CrystalsPanel = styled.button`
  position: absolute;
  aspect-ratio: 335/88;
  top: 54px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border-radius: 16px;
`;

export const TokensPanel = styled(CrystalsPanel)`
  top: calc(54px + 37.5vw);
  aspect-ratio: 335/80;
`;
export const Inner = styled.div`
  position: relative;
  height: 0;
  border: none;
  padding-top: 18%;
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
