import { useEffect, useState } from 'react';
import Slider from 'react-slick';

import ArrowL from '../../../../../../assets/icons/slide-left.svg';
import ArrowR from '../../../../../../assets/icons/slide-right.svg';
import Preloader from '../../../../../../components/loader';

import { assets } from './data';
import { Button, ButtonLeft, Slide, SliderWrap, Wrapper } from './styles';

import '../../../../../../assets/css/slick.scss';

const ArrowNext = ({ className, style, onClick }) => (
  <Button style={{ ...style }} onClick={onClick} className={className}>
    <img src={ArrowR} alt="" />
  </Button>
);
const ArrowPrev = ({ className, style, onClick }) => (
  <ButtonLeft style={{ ...style }} onClick={onClick} className={className}>
    <img src={ArrowL} alt="" />
  </ButtonLeft>
);

const Video = ({ item, isRender, isChange }) => {
  const [isShow, setShow] = useState(false);
  const [isLoad, setLoad] = useState(true);
  useEffect(() => {
    try {
      if (isRender) {
        const element = document.getElementById(`${item?.id}`);
        const style = window.getComputedStyle(element, null);
        if (style.display === 'block') {
          setShow(true);
        } else {
          setShow(false);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [isRender, isChange, item?.id]);
  return (
    <div className="slide-video" id={item?.id}>
      {isShow && (
        <video
          type="video/mp4"
          muted
          autoPlay
          loop
          playsInline
          disableRemotePlayback
          onLoadedData={() => setLoad(false)}
        >
          <source src={item?.video} type="video/mp4" />
        </video>
      )}
      {isLoad && <Preloader />}
    </div>
  );
};

const AssetsSlider = () => {
  const [isRender, setRender] = useState(false);
  const [isChange, setChange] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setRender(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  const settings = {
    slidesToShow: 5,
    dots: false,
    arrows: true,
    infinite: true,
    lazyLoad: true,
    swipe: false,
    speed: 300,
    centerMode: true,
    focusOnSelect: true,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
    afterChange: () => {
      setChange(isChange + 1);
    },
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          speed: 400,
          slidesToShow: 1,
          swipe: true,
        },
      },
    ],
  };
  return (
    <Wrapper>
      <SliderWrap>
        <Slider {...settings}>
          {assets?.map((item, index) => (
            <Slide className="slide-ref" key={`${index + 1}`}>
              {Video({ item, isRender, isChange })}
              <img className="slide-img" src={item.image} alt="" />
            </Slide>
          ))}
        </Slider>
      </SliderWrap>
    </Wrapper>
  );
};

export default AssetsSlider;
