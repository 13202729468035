export const heroes = [
  {
    image: 'https://assets.metaplayerone.app/assets/images/heroes/angel.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/heroes/angel.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/heroes/angel.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/heroes/angel.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/heroes/angel.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/heroes/angel.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/heroes/angel.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/heroes/angel.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/heroes/angel.png',
  },
  {
    image: 'https://assets.metaplayerone.app/assets/images/heroes/angel.png',
  },
];
