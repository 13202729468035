import React from 'react';
import Modal from 'react-modal';

import ArrowL from '../../../assets/icons/slide-left.svg';
import ArrowR from '../../../assets/icons/slide-right.svg';
import { legendary } from '../../../modules/catalog/data';
import IconClose from '../../icons/IconClose';

import { ButtonNext, ButtonPrev, Close, Conatainer, Description, Item, Title, Wrapper } from './styles';

const TokenDetailModal = (props) => {
  const { isOpenModal, closeModal, item, next, prev, tokens } = props;
  const getRarity = () => {
    switch (true) {
      case legendary?.includes(item?.metadata?.attributes?.number):
        return 'Legendary';
      default:
        return 'Epic';
    }
  };
  // eslint-disable-next-line react/no-unstable-nested-components, no-undef
  class Video extends React.Component {
    shouldComponentUpdate(nextProps) {
      // eslint-disable-next-line react/destructuring-assignment, react/no-this-in-sfc
      if (nextProps.value !== this.props.value) {
        return true;
      }
      return false;
    }

    render() {
      return (
        <video type="video/mp4" muted autoPlay loop playsInline disableRemotePlayback>
          <source src={item?.metadata?.animation_url} type="video/mp4" />
        </video>
      );
    }
  }
  return (
    <Modal isOpen={isOpenModal} onRequestClose={closeModal} shouldCloseOnOverlayClick ariaHideApp={false}>
      <Wrapper>
        <Close onClick={closeModal}>
          <IconClose />
        </Close>
        <Conatainer>
          <Title>{item?.metadata?.name}</Title>
          <Description>
            #{item?.metadata?.attributes?.number || item?.token_id}&nbsp;
            {getRarity()}
          </Description>
          <Item>
            <Video />
          </Item>

          <ButtonPrev onClick={prev} style={tokens?.indexOf(item) === 0 ? { opacity: 0.5 } : { opacity: 1 }}>
            <img src={ArrowL} alt="" />
          </ButtonPrev>
          <ButtonNext
            onClick={next}
            // eslint-disable-next-line no-unsafe-optional-chaining
            style={tokens?.indexOf(item) + 1 !== tokens?.length ? { opacity: 1 } : { opacity: 0.5 }}
          >
            <img src={ArrowR} alt="" />
          </ButtonNext>
        </Conatainer>
      </Wrapper>
    </Modal>
  );
};

export default TokenDetailModal;
