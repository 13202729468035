import { Dispatch, FC, SetStateAction, useState } from 'react';
import { clsx } from 'helpers/clsx';
import CustomButton from 'modules/participate/components/custom-button';

import { StepContent } from '../step-content';
import { StepTracker } from '../step-tracker';

import { MOCK_STEPS } from './mock';

import styles from './styles.module.scss';

interface isBecomeValidatorProps {
  className?: string;
  setIsBecomeValidatorView?: Dispatch<SetStateAction<boolean>>;
  isBecomeAccelerator?: boolean;
}

export const BecomeValidator: FC<isBecomeValidatorProps> = ({
  className,
  setIsBecomeValidatorView,
  isBecomeAccelerator,
}) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const getCurrentIndex = () => {
    if (!isBecomeAccelerator) return activeStepIndex;
    if (activeStepIndex < 4) return activeStepIndex;
    return activeStepIndex + 1;
  };
  return (
    <div className={clsx(className, styles.root)}>
      <p className={styles.title}>Become a Validator</p>
      <div className={styles.contentContainer}>
        <StepTracker
          steps={isBecomeAccelerator ? [...MOCK_STEPS.slice(0, 4), ...MOCK_STEPS.slice(5, 6)] : MOCK_STEPS}
          activeStepIndex={getCurrentIndex()}
        />
        <div className={styles.stepContentView}>
          <StepContent activeStepIndex={getCurrentIndex()} />
          <div className={styles.actionsContainer}>
            {getCurrentIndex() !== 0 && (
              <CustomButton
                variant="contained"
                onClick={() => setActiveStepIndex((prev) => prev - 1)}
                className={styles.action}
              >
                Back
              </CustomButton>
            )}
            <CustomButton
              variant="filled"
              onClick={() =>
                getCurrentIndex() === 5 ? setIsBecomeValidatorView(false) : setActiveStepIndex((prev) => prev + 1)
              }
              className={styles.action}
            >
              {getCurrentIndex() === 5 ? 'Done' : 'Continue'}
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};
