import { useState } from 'react';
import { clsx } from 'helpers/clsx';

import { Acceleration } from './sections/acceleration';
import { MyStaking } from './sections/my-staking';
import { Proposal } from './sections/proposal';
import { GlowSphere1Icon, GlowSphere2Icon } from './assets';
import { TabItem, TabSelectorContainer, Validators } from './components';

import styles from './styles.module.scss';

const tabs = [
  {
    value: 'validators',
    title: 'Validators',
  },
  {
    value: 'myStaking',
    title: 'My Staking',
  },
  {
    value: 'proposal',
    title: 'Proposal',
  },
  {
    value: 'acceleration',
    title: 'Acceleration',
  },
];

const Participate = () => {
  const [activeTab, setActiveTab] = useState<TabItem>(tabs[0]);
  return (
    <div className={styles.root}>
      <GlowSphere1Icon className={styles['glow-sphere-1']} />
      <GlowSphere2Icon className={styles['glow-sphere-2']} />
      <TabSelectorContainer tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} />
      <Validators className={clsx(activeTab.value === 'validators' && styles.visible, styles.tabContent)} />
      <MyStaking className={clsx(activeTab.value === 'myStaking' && styles.visible, styles.tabContent)} />
      <Proposal className={clsx(activeTab.value === 'proposal' && styles.visible, styles.tabContent)} />
      <Acceleration className={clsx(activeTab.value === 'acceleration' && styles.visible, styles.tabContent)} />
    </div>
  );
};
export default Participate;
