export const assets = [
  {
    id: 'as1',
    image: 'https://assets.metaplayerone.app/assets/artifacts/1.webp',
    video: 'https://assets.metaplayerone.app/assets/artifacts/1min.mp4',
  },
  {
    id: 'as2',
    image: 'https://assets.metaplayerone.app/assets/artifacts/2.webp',
    video: 'https://assets.metaplayerone.app/assets/artifacts/2min.mp4',
  },
  {
    id: 'as3',
    image: 'https://assets.metaplayerone.app/assets/artifacts/3.webp',
    video: 'https://assets.metaplayerone.app/assets/artifacts/3min.mp4',
  },
  {
    id: 'as4',
    image: 'https://assets.metaplayerone.app/assets/artifacts/4.webp',
    video: 'https://assets.metaplayerone.app/assets/artifacts/4min.mp4',
  },
  {
    id: 'as5',
    image: 'https://assets.metaplayerone.app/assets/artifacts/5.webp',
    video: 'https://assets.metaplayerone.app/assets/artifacts/5min.mp4',
  },
  // {
  //   id: 'as6',
  //   image:
  //     'https://metaplayerone.mypinata.cloud/ipfs/QmZMABbjUMW5SMA7AnmBeqdFzFL47RFkeuMEtvurFeJtmr/6.webp',
  //   video: Video6,
  // },
  {
    id: 'as7',
    image: 'https://assets.metaplayerone.app/assets/artifacts/7.webp',
    video: 'https://assets.metaplayerone.app/assets/artifacts/7min.mp4',
  },
  {
    id: 'as8',
    image: 'https://assets.metaplayerone.app/assets/artifacts/8.webp',
    video: 'https://assets.metaplayerone.app/assets/artifacts/8min.mp4',
  },
  {
    id: 'as9',
    image: 'https://assets.metaplayerone.app/assets/artifacts/9.webp',
    video: 'https://assets.metaplayerone.app/assets/artifacts/9min.mp4',
  },
  {
    id: 'as10',
    image: 'https://assets.metaplayerone.app/assets/artifacts/10.webp',
    video: 'https://assets.metaplayerone.app/assets/artifacts/10min.mp4',
  },
  {
    id: 'as11',
    image: 'https://assets.metaplayerone.app/assets/artifacts/11.webp',
    video: 'https://assets.metaplayerone.app/assets/artifacts/11min.mp4',
  },
];
