import { Content, Inner, Item, Wrapper } from './styles';

const CardAssets = ({ item, onClick }) => {
  return (
    <Wrapper>
      <Inner>
        <Content>
          <Item onClick={onClick}>
            <video type="video/mp4" muted autoPlay loop playsInline disableRemotePlayback>
              <source src={item} type="video/mp4" />
            </video>
          </Item>
        </Content>
      </Inner>
    </Wrapper>
  );
};
export default CardAssets;
