import { FC } from 'react';

import { IconProps } from '..';

export const EllipseIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="url(#paint0_linear_7_672)" />
      <defs>
        <linearGradient id="paint0_linear_7_672" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0047FF" />
          <stop offset="1" stopColor="#00FFD1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
