import Modal from 'react-modal';

import { tokenomics } from '../../../modules/main/sections/meta-skynet/info/crystals-power-new/data';
import TokenCard from '../../../modules/main/sections/meta-skynet/info/crystals-power-new/tokenomics/token';
import IconClose from '../../icons/IconClose';

import { Close, Conatainer, Title, Wrapper } from './styles';

const AboutTokensModal = (props) => {
  const { isOpenModal, closeModal } = props;

  return (
    <Modal isOpen={isOpenModal} onRequestClose={closeModal} shouldCloseOnOverlayClick ariaHideApp={false}>
      <Wrapper>
        <Conatainer>
          <Close onClick={closeModal}>
            <IconClose />
          </Close>
          <Title>About Tokens</Title>
          {tokenomics?.map((item) => (
            <TokenCard item={item} key={item?.token_name} />
          ))}
        </Conatainer>
      </Wrapper>
    </Modal>
  );
};
export default AboutTokensModal;
