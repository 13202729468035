import { useState } from 'react';

const useScrollY = () => {
  const [scrollY, setScrollY] = useState(0);

  window.onscroll = () => {
    setScrollY(window.scrollY);
  };
  return scrollY;
};

export default useScrollY;
