import Modal from 'react-modal';

import ArrowL from '../../../assets/icons/slide-left.svg';
import ArrowR from '../../../assets/icons/slide-right.svg';
import { legendary } from '../../../modules/catalog/data';
import IconClose from '../../icons/IconClose';
import Preloader from '../../loader';
import { FilledButton } from '../../ui';

import { ButtonNext, ButtonPrev, Close, Conatainer, Description, Item, Title, Wrapper } from './styles';

const ShardDetailModal = (props) => {
  const { isOpenModal, closeModal, item, next, prev, amount, activeIndex, loading, list, openMint } = props;

  const getRarity = () => {
    switch (true) {
      case legendary?.includes(item?.attributes?.number):
        return 'Legendary';
      default:
        return 'Epic';
    }
  };
  // eslint-disable-next-line react/no-unstable-nested-components, no-undef
  class Video extends React.Component {
    shouldComponentUpdate(nextProps) {
      // eslint-disable-next-line react/destructuring-assignment, react/no-this-in-sfc
      if (nextProps.value !== this.props.value) {
        return true;
      }
      return false;
    }

    render() {
      return (
        <video type="video/mp4" muted autoPlay loop playsInline disableRemotePlayback>
          <source src={item?.animation_url} type="video/mp4" />
        </video>
      );
    }
  }
  return (
    <Modal isOpen={isOpenModal} onRequestClose={closeModal} shouldCloseOnOverlayClick ariaHideApp={false}>
      <Wrapper>
        <Close onClick={closeModal}>
          <IconClose />
        </Close>
        <Conatainer>
          <Title>{item?.name}</Title>
          <Description>
            {/* #{item?.attributes?.number} &nbsp; */}
            {getRarity()}
          </Description>
          <Item>{loading ? <Preloader /> : <Video />}</Item>

          <ButtonPrev onClick={prev} isDisabled={list.indexOf(activeIndex) === 0}>
            <img src={ArrowL} alt="" />
          </ButtonPrev>
          <ButtonNext onClick={next} isDisabled={list.indexOf(activeIndex) === amount - 1}>
            <img src={ArrowR} alt="" />
          </ButtonNext>
          <FilledButton title="random mint" onClick={openMint} />
        </Conatainer>
      </Wrapper>
    </Modal>
  );
};

export default ShardDetailModal;
