import { useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';
import axios from 'axios';

import EmailArrowIcon from '../../assets/icons/email-arrow.svg';
import ImgLogo from '../../assets/icons/logo.svg';
import { Container } from '../../components/containers';
import { TabletOff, TabletOn } from '../../helpers/responsive';
import useHeight from '../../helpers/useHeightComponent';
import usePosition from '../../helpers/usePosition';
import useScrollY from '../../helpers/useScrollY';

import { allLinks, socials } from './data';
import {
  Border,
  EmailFormContainer,
  Flex,
  FormArrowBox,
  FormInputContainer,
  FormSubTitle,
  FormTitle,
  Grid,
  Icon,
  LegalText,
  LinkGroupItem,
  LinkGroupName,
  MobileInfoContainer,
  Socials,
  Wrapper,
} from './styles';

const Footer = () => {
  const options = usePosition();
  const scrollY = useScrollY();
  const height = useHeight();
  const { pathname } = useLocation();
  const [email, setEmail] = useState('');
  const [isSuccessText, setIsSuccessText] = useState(false);
  const [isLoading, setIsLoading] = useState('');
  const watch = (item) =>
    item.position - 80 <= scrollY && item.position + item.heightWrap - 80 >= scrollY && pathname === '/';

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const portalId = '26463076';
      const formGuid = '4c52abb9-90d1-45f4-a54c-6ff4658d4515';
      const response = await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
          portalId,
          formGuid,
          fields: [
            {
              name: 'email',
              value: email,
            },
          ],
        },
      );
      if (response.status === 200) {
        setIsSuccessText(true);
        setTimeout(() => {
          setIsSuccessText(false);
        }, 5000);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Wrapper>
      <Border />
      <Container className="container">
        <Flex>
          <img src={ImgLogo} alt="" />
          <TabletOff>
            <Grid>
              {allLinks(options, height).map(({ name: groupName, links }) => (
                <div key={groupName}>
                  <LinkGroupName>{groupName}</LinkGroupName>
                  {links.map(({ name, link, url, position, heightWrap }) => (
                    <LinkGroupItem key={name}>
                      {url || pathname !== '/' ? (
                        <Link
                          to={url || (pathname !== '/' ? '/' : '')}
                          target={
                            link === 'validators' || link === 'my-assets' || pathname !== '/' ? '_self' : '_blank'
                          }
                        >
                          {name}
                        </Link>
                      ) : (
                        <Scroll to={!url ? link : ''} smooth={!url} isActive={watch({ position, heightWrap })}>
                          {name}
                        </Scroll>
                      )}
                    </LinkGroupItem>
                  ))}
                </div>
              ))}
            </Grid>
            <EmailFormContainer>
              <FormTitle>
                {isSuccessText ? 'Well done, stay tuned! ' : 'Stay always updated with our newsletter'}
              </FormTitle>
              <FormSubTitle>
                You will receive only weekly newsletter with most interesting things happened on our platform.
              </FormSubTitle>
              <FormInputContainer>
                <input placeholder="Your email address" onChange={({ target: { value } }) => setEmail(value)} />
                <FormArrowBox onClick={!isLoading && onSubmit} className={isLoading && 'disabled'}>
                  <img src={EmailArrowIcon} alt="" />
                </FormArrowBox>
              </FormInputContainer>
            </EmailFormContainer>
          </TabletOff>
          <TabletOn>
            <Grid>
              {allLinks(options, height)
                .slice(1, 3)
                .map(({ name: groupName, links }) => (
                  <div key={groupName}>
                    <LinkGroupName>{groupName}</LinkGroupName>
                    {links.map(({ name, link, url, position, heightWrap }) => (
                      <LinkGroupItem key={name}>
                        {url || pathname !== '/' ? (
                          <Link
                            to={url || (pathname !== '/' ? '/' : '')}
                            target={
                              link === 'validators' || link === 'my-assets' || pathname !== '/' ? '_self' : '_blank'
                            }
                          >
                            {name}
                          </Link>
                        ) : (
                          <Scroll to={!url ? link : ''} smooth={!url} isActive={watch({ position, heightWrap })}>
                            {name}
                          </Scroll>
                        )}
                      </LinkGroupItem>
                    ))}
                  </div>
                ))}
            </Grid>
            <Socials>
              {socials('socialIcon').map((item, index) => (
                <Icon key={`${index + 1}`} href={item?.url} target="_blank">
                  {item.icon}
                </Icon>
              ))}
            </Socials>
          </TabletOn>
        </Flex>
        <TabletOn>
          <MobileInfoContainer>
            {/* <FilledButton title="Report a bug" /> */}
            <Flex className="legalContainer">
              <LegalText>MetaPlayerOne Ltd. {new Date().getFullYear()}</LegalText>
              <LegalText>All rights reserved</LegalText>
            </Flex>
          </MobileInfoContainer>
        </TabletOn>
      </Container>
    </Wrapper>
  );
};
export default Footer;
