import styled from 'styled-components';

const Wrapper = styled.button`
  ${(props) => props.rootClassName};
  --border-width: 2px;
  --border-radius: 22px;
  --color-1: #af01ec;
  --color-2: #0066ff;
  font-weight: bold;
  letter-spacing: 1px;
  color: #fff;
  border-radius: 56px;
  border: 2px solid var(--States-gradient-pink2purple, #e657dc);
  z-index: 1;

  display: flex;
  width: 223px;
  height: 45px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
`;

const PrimarySoikaButton = ({ title, onClick, disabled = false, rootClassName = '' }) => {
    return (
        <Wrapper
            onClick={onClick}
            disabled={disabled}
            locked={disabled}
            className="primary-button"
            rootClassName={rootClassName}
        >
            {title}
        </Wrapper>
    );
};
export default PrimarySoikaButton;
