import { IconDiscord } from '../../assets/icons/discord';
import { IconInstagram } from '../../assets/icons/instagram';
import { IconTikTok } from '../../assets/icons/tiktok';
import { IconTwitter } from '../../assets/icons/twitter';
import { IconYoutube } from '../../assets/icons/youtube';
import { discord, social } from '../../const/links';
import { navigations } from '../header/data';

export const socials = (className = '') => [
  {
    name: 'Discord',
    url: discord.default,
    icon: <IconDiscord className={className} />,
  },
  {
    name: 'Twitter',
    url: social.twitter,
    icon: <IconTwitter className={className} />,
  },

  {
    name: 'Youtube',
    url: social.youtube,
    icon: <IconYoutube className={className} />,
  },
  {
    name: 'Instagram',
    url: social.instagram,
    icon: <IconInstagram className={className} />,
  },

  {
    name: 'TikTok',
    url: social.tiktok,
    icon: <IconTikTok className={className} />,
  },
];

export const allLinks = (options, height) => [
  {
    name: 'Community',
    links: socials().map(({ url, name }) => ({ name, url })),
  },
  {
    name: 'Menu',
    links: navigations(options, height).map(({ label, link, isNewPage, position, heightWrap }) => ({
      name: label,
      link,
      ...(isNewPage && { url: link }),
      position,
      heightWrap,
    })),
  },
  {
    name: 'Legal',
    links: [
      {
        name: 'Terms and Conditions',
        url: 'https://assets.metaplayerone.app/assets/terms-mp1.pdf',
      },
      {
        name: 'Documentation',
        url: 'https://docs.metaplayerone.app/metaplayerone/white-paper/introduction',
      },
    ],
  },
];
