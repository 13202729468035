import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const Column = ({ children }) => <Wrapper>{children}</Wrapper>;

export default Column;
