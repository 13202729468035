import styled from 'styled-components';

const Wrapper = styled.button`
  border-radius: 56px;
  padding: 1px;
  background: ${(props) => (props.locked ? '#474A52' : 'linear-gradient(107.75deg, #af01ec 3.49%, #0066ff 97.12%)')};
  cursor: ${(props) => (props.locked ? 'default' : 'pointer')};
  transition: 0.4s;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 0 22px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  border-radius: 56px;
  color: ${(props) => (props.locked ? '#7E8390' : '#fff')};
  background: ${(props) => (props.locked ? '#474A52' : 'linear-gradient(107.75deg, #af01ec 3.49%, #0066ff 97.12%)')};
  transition: 0.4s;
  &:hover {
    background: ${(props) => (props.locked ? '#474a52' : '#010102')};
    transition: 0.4s;
    @media screen and (max-width: 1024px) {
      background: ${(props) =>
        props.locked ? '#474A52' : 'linear-gradient(107.75deg, #af01ec 3.49%, #0066ff 97.12%)'};
    }
  }
`;
const FilledButton = ({ title, onClick, disabled = false, type, className = '' }) => {
  return (
    <Wrapper className="filled-button" type={type || 'button'} onClick={onClick} disabled={disabled} locked={disabled}>
      <Content locked={disabled} className={className}>
        {title}
      </Content>
    </Wrapper>
  );
};
export default FilledButton;
