import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const usePosition = () => {
  const { pathname } = useLocation();
  const [soika, setSoika] = useState(0);
  const [dgpu, setDgpu] = useState(0);
  const [metaUnits, setMetaUnits] = useState(0);
  const [mintCrystal, setMintCrystal] = useState(0);
  useEffect(() => {
    if (pathname === '/') {
      setSoika(document.getElementById('soika')?.offsetTop);
      setDgpu(document.getElementById('dgpu')?.offsetTop);
      setMetaUnits(document.getElementById('metaUnits')?.offsetTop);
      setMintCrystal(document.getElementById('mintCrystal')?.offsetTop);
    }
  }, [pathname]);
  return { soika, dgpu, metaUnits, mintCrystal };
};
export default usePosition;
