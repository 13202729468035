import styled from 'styled-components';

const Wrapper = styled.div`
  border-radius: 56px;
  padding: 1px;
  background: linear-gradient(107.75deg, #af01ec 3.49%, #0066ff 97.12%);
  cursor: pointer;
  transition: 0.4s;
`;
const Content = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 0 22px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  border-radius: 56px;
  color: #fff;
  background: linear-gradient(107.75deg, #af01ec 3.49%, #0066ff 97.12%);
  transition: 0.4s;
  &:hover {
    background: #010102;
    transition: 0.4s;
    @media screen and (max-width: 1024px) {
      background: linear-gradient(107.75deg, #af01ec 3.49%, #0066ff 97.12%);
    }
  }
`;
const FilledLink = ({ title, url, onClick, className }) => {
  return (
    <Wrapper className="filled_link">
      <Content href={url} onClick={onClick} target="_blank" className={className}>
        {title}
      </Content>
    </Wrapper>
  );
};
export default FilledLink;
