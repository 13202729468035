import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 60px;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
    background: #deeffa;
  }
  ::-webkit-scrollbar-thumb {
    background: #a2c6dd;
    border-radius: 5px;
    cursor: pointer;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 10px 32px 10px;
  }
`;
export const Conatainer = styled.div`
  width: 808px;
  min-height: 524px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  background: #1b1b1c;
  border-radius: 24px;
  padding: 56px;
  @media screen and (max-width: 767px) {
    padding: 20px;
    border-radius: 8px;
  }
`;
export const Close = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: absolute;
  background: transparent;
  border-radius: 50%;
  top: 12px;
  right: 12px;
  stroke: #000;
  & svg {
    stroke: #fff;
    fill: #fff;
  }
  @media screen and (max-width: 767px) {
    position: absolute;
    top: 24px;
  }
`;
export const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  padding-bottom: 24px;
`;
export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  min-width: 260px;
  height: 260px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  margin-right: 32px;
  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
  }
`;
export const Name = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
`;
export const Number = styled(Name)``;
export const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 8px 0 12px 0;
`;
export const Rate = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7e8390;
`;

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;
export const SmallCrystal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  min-width: 120px;
  height: 120px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
  }
`;
export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 32px;
  @media screen and (max-width: 767px) {
    & button {
      width: 100%;
    }
  }
`;
export const Step = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
export const Back = styled.button`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  margin-right: 12px;
`;
export const Block = styled.div`
  padding-left: 24px;
`;
export const Amount = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  padding-top: 2px;
`;
export const Notice = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
`;
export const WrapList = styled.div`
  width: calc(100% + 4px);
  height: 258px;
  position: relative;
`;
export const List = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #8d8d8d;
    border-radius: 5px;
    cursor: pointer;
  }
`;

export const SmallText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;

export const Item = styled.button`
  position: relative;
  width: 108px;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid ${(props) => (props.checked ? '#8e40e4' : '#323233')};
  margin: 0 8px 8px 0;
  cursor: pointer;
  padding: 0;
  & img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
`;
export const Gradient = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 53px;
  background: linear-gradient(0deg, #1b1b1c 0%, rgba(31, 31, 31, 0) 100%);
`;
export const Checked = styled.div`
  display: ${(props) => (props.checked ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 5px;
`;
export const CenterWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  & > div:nth-child(1) {
    margin: 0 0 16px 0;
  }
  & > button {
    margin-top: 16px;
  }
`;
