import { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { useLocation, useNavigate } from 'react-router';
import { Link as Scroll } from 'react-scroll';
import { useSnackbar } from 'notistack';

import ImgClose from '../../../assets/icons/circle-x.svg';
import ImgLogo from '../../../assets/icons/logo.svg';
import IconUser from '../../../assets/images/emptyUser.png';
import { Column } from '../../../components/containers';
// import LoadingModal from '../../../components/modals/loading';
import { FilledLink, PrimaryButton } from '../../../components/ui';
import { discord } from '../../../const/links';
import useAuth from '../../../helpers/auth';
import useHeight from '../../../helpers/useHeightComponent';
import usePosition from '../../../helpers/usePosition';
import useScrollY from '../../../helpers/useScrollY';
import { navigations } from '../data';

import { Avatar, Body, CloseBtn, Content, Logo, Navigation, Number, WrapBtn } from './styles';

const getAddress = (account = '') => {
  return `${account.slice(0, 15)}...${account.slice(-3)}`;
};

const Menu = ({ setOpenMenu, isOpenMenu }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOpenLoading, setOpenLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user, logout, authenticate } = useAuth();
  const push = useNavigate();
  const { pathname, search } = useLocation();
  const scrollY = useScrollY();
  const options = usePosition();
  const height = useHeight();
  const onClickAssets = () => {
    push(`/my-assets${search}`);
    setOpenMenu(false);
    window.scrollTo(0, 0);
  };
  const onToggleNav = (name, isNewPage, link) => {
    setOpenMenu(false);
    if (link === 'my-assets') {
      push(`/my-assets`);
      window.scrollTo(0, 0);
      return;
    }
    if (isNewPage) {
      push(`/${name}`);
      window.scrollTo(0, 0);
      return;
    }
    if (pathname !== '/') {
      window.scrollTo(0, 0);
    }
    push(`/${search}`);
  };
  const onBtnLogout = () => {
    logout();
    setOpenMenu(false);
  };
  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflowY = isOpenMenu ? 'hidden' : 'auto';
  }, [isOpenMenu]);
  const login = async () => {
    try {
      setOpenLoading(true);
      setOpenMenu(false);
      await authenticate();
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Error! Wallet is not connected!', {
        autoHideDuration: 3000,
        variant: 'error',
      });
    }
    setOpenLoading(false);
  };
  // eslint-disable-next-line consistent-return
  const watch = (item) => {
    if (item.position - 80 <= scrollY && item.position + item.heightWrap - 80 >= scrollY && pathname === '/') {
      return true;
    }
  };
  return (
    <>
      <Content isOpen={isOpenMenu}>
        <Body>
          <CloseBtn onClick={() => setOpenMenu(false)}>
            <img src={ImgClose} alt="" />
          </CloseBtn>
          <Column>
            {user && (
              <>
                <Avatar onClick={onClickAssets}>
                  <LazyLoad>
                    <img src={user?.get('avatar') ?? IconUser} alt="" />
                  </LazyLoad>
                </Avatar>
                <Number>{getAddress(user?.get('ethAddress'))}</Number>
              </>
            )}
            <Scroll className="nav_link" to="teaser" smooth offset={0}>
              <Logo onClick={onToggleNav}>
                <img src={ImgLogo} alt="" />
                <span>Meta</span>PlayerOne
              </Logo>
            </Scroll>

            {navigations(options, height).map((item, index) => (
              <Scroll className="nav_link" key={`${index + 1}`} to={item?.link} smooth offset={item?.offset_m}>
                <Navigation onClick={() => onToggleNav(item.name, item.isNewPage, item.link)} isActive={watch(item)}>
                  {item.label}
                </Navigation>
              </Scroll>
            ))}
          </Column>
          <WrapBtn>
            {user ? (
              <PrimaryButton title="Logout" onClick={onBtnLogout} />
            ) : (
              <PrimaryButton title="connect wallet" onClick={login} />
            )}
            <FilledLink title="Join discord" url={discord.default} />
          </WrapBtn>
        </Body>
      </Content>
      {/* <LoadingModal isOpenModal={isOpenLoading} text="Please wait..." /> */}
    </>
  );
};
export default Menu;
