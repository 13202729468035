import { FC } from 'react';
import { clsx } from 'helpers/clsx';
import { CheckIcon } from 'modules/participate/assets';

import styles from './styles.module.scss';

type StepItemModel = {
  title: string;
  value: string;
};

interface IStepTrackerProps {
  className?: string;
  steps: StepItemModel[];
  activeStepIndex: number;
}

export const StepTracker: FC<IStepTrackerProps> = ({ className, steps, activeStepIndex }) => {
  return (
    <div className={clsx(className, styles.root)}>
      {steps.map(({ title, value }, idx) => (
        <div className={styles.stepItemContainer} key={value}>
          <div className={clsx(styles.stepCount, activeStepIndex >= idx && styles.active)}>
            {activeStepIndex > idx ? <CheckIcon /> : <p className={styles.stepCountText}>{idx + 1}</p>}
          </div>
          <p className={clsx(styles.step, activeStepIndex >= idx && styles.active)}>{title}</p>
          {idx !== steps.length - 1 && <div className={clsx(styles.divider, activeStepIndex > idx && styles.active)} />}
        </div>
      ))}
    </div>
  );
};
