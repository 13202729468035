import styled from 'styled-components';

const Wrapper = styled.div`
  display: block;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  padding: 0 100px;
  @media screen and (max-width: 1280px) {
    padding: 0 60px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 32px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
`;

const Container = ({ children, className }) => <Wrapper className={className}>{children}</Wrapper>;

export default Container;
