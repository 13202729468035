export const navigations = (options, height) => [
  {
    link: 'validators',
    label: 'Validators',
    isNewPage: true,
  },
  {
    link: 'soika',
    label: 'Soika',
    position: options?.soika,
    heightWrap: height?.soika,
  },
  {
    link: 'dgpu',
    label: 'DGPU',
    position: options?.dgpu,
    heightWrap: height?.dgpu,
  },
  {
    link: 'metaUnits',
    label: 'MetaUnit',
    position: options?.metaUnits,
    heightWrap: height?.metaUnits,
  },
  {
    link: 'mintCrystal',
    label: 'Crystals of Power',
    position: options?.mintCrystal,
    heightWrap: height?.mintCrystal,
  },
  {
    link: 'my-assets',
    label: 'My Assets',
    isNewPage: true,
  },
];
