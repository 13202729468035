import { useLocation, useNavigate } from 'react-router';

import { Button, SubTitle, Title, Wrapper } from './styles';

const EmptyCard = () => {
  const push = useNavigate();
  const { search } = useLocation();
  const onClick = () => {
    push(`/${search}`);
    window.scrollTo(0, 0);
  };
  return (
    <Wrapper>
      <Title>Nothing yet...</Title>
      <SubTitle>
        You can mint your first asset on the{' '}
        <button onClick={onClick} type="button">
          Main page
        </button>
      </SubTitle>
      <Button onClick={onClick}>back to main</Button>
    </Wrapper>
  );
};
export default EmptyCard;
