export const MOCK_HARDWARE_REQUIREMENTS = [
  {
    title: 'CPU cores',
    value: '8',
  },
  {
    title: 'GPU cores',
    value: '8',
  },
  {
    title: 'RAM',
    value: '32 GB',
  },
  {
    title: 'SSD',
    value: '1 TB',
  },
];

export const MOCK_SETUP_INSTRUCTIONS = [
  {
    title: 'Validator POOL',
    value: 'validatorPool',
  },
  {
    title: 'Validator Node',
    value: 'validatorNode',
  },
];
