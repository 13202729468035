/* eslint-disable camelcase */
export const isDev = window.location.hostname.includes('dev') || window.location.hostname.includes('localhost');

export const serverUrl = isDev ? 'https://dev.moralis.mp1.site/server' : 'https://moralis.mp1.site/server';
export const appId = '1704';
export const chain = isDev ? 'goerli' : 'eth';
export const chainId = isDev ? '0x5' : '0x1';

export const shard_address = isDev
  ? '0xAF3Cf576659a85a10f3b3B6B81eeb1Db1FD1feCD'
  : '0x72771CfAC63092255ab9a7ec03129f0574844709';

export const AIRDROP_CRYSTAL_ADDRESS = isDev ? '0x2f2c9CAc8c6a6F063E0A60B4E727F4AdB2feC5Ba' : '';
export const ALLOCATION_CRYSTAL_ADDRESS = isDev ? '0x1809E990Ad32EFdBA9d21ebee13CfAec126649b6' : '';
export const GENERATIVE_ASSETS_CRYSTAL_ADDRESS = isDev ? '0x64585a8911f49eE089bd68c157FE1A9DEC673ec4' : '';
export const METAPLAYER_CRYSTAL_ADDRESS = isDev ? '0xc7680ab443ca01CF8f574cEe23A94243A0185BE8' : '';
export const PROMOTION_CRYSTAL_ADDRESS = isDev
  ? '0x838cAFC098F86364cC861111777EB69CF8b5c06A'
  : '0x434D6209881B11b9E3B0B988805Bf9Ec71f15556';

export const METAUNIT_IDO_WHITELIST = isDev
  ? '0xbf0Cbf548B35526AAb8F2d26Bc99A2902089CB3F'
  : '0xae28ca05a49b64dfe2bd3aa37d73c057e6f2b925';

export const crystals = [
  AIRDROP_CRYSTAL_ADDRESS,
  ALLOCATION_CRYSTAL_ADDRESS,
  GENERATIVE_ASSETS_CRYSTAL_ADDRESS,
  METAPLAYER_CRYSTAL_ADDRESS,
  PROMOTION_CRYSTAL_ADDRESS,
];

export const shard_price = 0.55;
export const shard_white_price = 0.5;
export const shard_gold_price = 0.4;

export const metaunit_price = 0.000025;
