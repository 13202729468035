import CustomButton from 'modules/participate/components/custom-button';

import styles from './styles.module.scss';

export const tabs = [
  {
    title: 'MY validators',
    value: 'myValidators',
  },
  {
    title: 'Activities',
    value: 'activities',
  },
];

export const headers = [
  'VALIDATOR',
  'MY STAKE',
  'TOTAL REWARDS',
  'CLAIMABLE',
  'COMMISSION RATE',
  'STATUS',
  'STARTED DATE',
  'ACTION',
];

export const content = [
  {
    id: 1,
    validators: (
      <div className={styles.validatorsCell}>
        <div className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    myStake: '17.788',
    totalRewards: '7.718 MEU',
    claimable: '2.637 MEU',
    commissionRate: '19 %',
    status: <span className={styles.highlightedText}>Active</span>,
    startedDate: '12.06.23',
    action: (
      <div className={styles.actionContainer}>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Claim
        </CustomButton>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Unstake
        </CustomButton>
      </div>
    ),
  },
  {
    id: 2,
    validators: (
      <div className={styles.validatorsCell}>
        <div className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    myStake: '17.788',
    totalRewards: '7.718 MEU',
    claimable: '2.637 MEU',
    commissionRate: '19 %',
    status: <span className={styles.highlightedText}>Active</span>,
    startedDate: '12.06.23',
    action: (
      <div className={styles.actionContainer}>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Claim
        </CustomButton>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Unstake
        </CustomButton>
      </div>
    ),
  },
  {
    id: 3,
    validators: (
      <div className={styles.validatorsCell}>
        <div className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    myStake: '17.788',
    totalRewards: '7.718 MEU',
    claimable: '2.637 MEU',
    commissionRate: '19 %',
    status: <span className={styles.highlightedText}>Active</span>,
    startedDate: '12.06.23',
    action: (
      <div className={styles.actionContainer}>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Claim
        </CustomButton>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Unstake
        </CustomButton>
      </div>
    ),
  },
  {
    id: 4,
    validators: (
      <div className={styles.validatorsCell}>
        <div className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    myStake: '17.788',
    totalRewards: '7.718 MEU',
    claimable: '2.637 MEU',
    commissionRate: '19 %',
    status: <span className={styles.highlightedText}>Active</span>,
    startedDate: '12.06.23',
    action: (
      <div className={styles.actionContainer}>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Claim
        </CustomButton>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Unstake
        </CustomButton>
      </div>
    ),
  },
  {
    id: 5,
    validators: (
      <div className={styles.validatorsCell}>
        <div className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    myStake: '17.788',
    totalRewards: '7.718 MEU',
    claimable: '2.637 MEU',
    commissionRate: '19 %',
    status: <span className={styles.highlightedText}>Active</span>,
    startedDate: '12.06.23',
    action: (
      <div className={styles.actionContainer}>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Claim
        </CustomButton>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Unstake
        </CustomButton>
      </div>
    ),
  },
  {
    id: 6,
    validators: (
      <div className={styles.validatorsCell}>
        <div className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    myStake: '17.788',
    totalRewards: '7.718 MEU',
    claimable: '2.637 MEU',
    commissionRate: '19 %',
    status: <span className={styles.highlightedText}>Active</span>,
    startedDate: '12.06.23',
    action: (
      <div className={styles.actionContainer}>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Claim
        </CustomButton>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Unstake
        </CustomButton>
      </div>
    ),
  },
  {
    id: 7,
    validators: (
      <div className={styles.validatorsCell}>
        <div className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    myStake: '17.788',
    totalRewards: '7.718 MEU',
    claimable: '2.637 MEU',
    commissionRate: '19 %',
    status: <span className={styles.highlightedText}>Active</span>,
    startedDate: '12.06.23',
    action: (
      <div className={styles.actionContainer}>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Claim
        </CustomButton>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Unstake
        </CustomButton>
      </div>
    ),
  },
  {
    id: 8,
    validators: (
      <div className={styles.validatorsCell}>
        <div className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    myStake: '17.788',
    totalRewards: '7.718 MEU',
    claimable: '2.637 MEU',
    commissionRate: '19 %',
    status: <span className={styles.highlightedText}>Active</span>,
    startedDate: '12.06.23',
    action: (
      <div className={styles.actionContainer}>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Claim
        </CustomButton>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Unstake
        </CustomButton>
      </div>
    ),
  },
  {
    id: 9,
    validators: (
      <div className={styles.validatorsCell}>
        <div className={styles.validatorIcon} />
        <p>Google</p>
      </div>
    ),
    myStake: '17.788',
    totalRewards: '7.718 MEU',
    claimable: '2.637 MEU',
    commissionRate: '19 %',
    status: <span className={styles.highlightedText}>Active</span>,
    startedDate: '12.06.23',
    action: (
      <div className={styles.actionContainer}>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Claim
        </CustomButton>
        <CustomButton variant="filled" onClick={() => {}} className={styles.actionButton}>
          Unstake
        </CustomButton>
      </div>
    ),
  },
];
