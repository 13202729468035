import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${(props) => props.className}
`;

const Flex = ({ children, className }) => <Wrapper className={className}>{children}</Wrapper>;

export default Flex;
