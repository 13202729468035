import { useRef, useState } from 'react';

import IconUser from '../../../assets/images/emptyUser.png';
import { PrimaryButton } from '../../../components/ui';
import useAuth from '../../../helpers/auth';
import useOutsideClick from '../../../helpers/ClickAwayListener';

import { Button, Number, OptonList, Wrapper } from './styles';

const getAddress = (account = '') => {
  return `${account.slice(0, 15)}...${account.slice(-3)}`;
};

const Profile = () => {
  const { user, logout } = useAuth();
  const [isProfile, setProfile] = useState();
  const refProfile = useRef();

  useOutsideClick(refProfile, () => {
    setProfile(false);
  });

  const onBtnLogout = () => {
    logout();
    setProfile(false);
  };
  return (
    <Wrapper ref={refProfile}>
      {user && (
        <Button onClick={() => setProfile(!isProfile)}>
          <img src={user?.get('avatar') ?? IconUser} alt="" />
        </Button>
      )}
      <OptonList isProfile={isProfile}>
        <Number>{getAddress(user?.get('ethAddress'))}</Number>
        <PrimaryButton onClick={onBtnLogout} title="Logout" />
      </OptonList>
    </Wrapper>
  );
};
export default Profile;
